import { createTheme, responsiveFontSizes } from '@mui/material';

export function AppTheme() {
  let theme = createTheme(
    {
      palette: {
        text: {
          primary: "#1D2332",
          secondary: "#1D2332",
          disabled: "#8c9eff",
          // hint: "#8c9eff",
          // icon: "#8c9eff",
          divider: "#8c9eff",
          // lightDivider: "#8c9eff",
          // dark: "#8c9eff",
          // contrast: "#8c9eff",
          // white: "#8c9eff",
          // black: "#8c9eff",
        },
        background: {
          default: "#1D2332",
          neutral: '#f5f5f5',
        },
        primary: {
          main: '#1D2332',
          light: '#757ce8',
          dark: '#002884',
          contrastText: '#fff',
        },
        secondary: {
          main: '#8c9eff',
          light: '#ff7961',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
      typography: {
        fontSize: 11,
        fontFamily: 'Roboto',
        h1: {
          fontSize: '2rem',
          fontWeight: 500,
        },
        h2: {
          fontSize: '1.5rem',
          fontWeight: 500,
        },
        h3: {
          fontSize: '1.17rem',
          fontWeight: 500,
        },
        h4: {
          fontSize: '1rem',
          fontWeight: 500,
        },
        h5: {
          fontSize: '.83rem',
          fontWeight: 500,
        },
        h6: {
          fontSize: '.67rem',
          fontWeight: 500,
        },
        subtitle1: {
          fontSize: '1rem',
          fontWeight: 400,
        },
        subtitle2: {
          fontSize: '.83rem',
          fontWeight: 400,
        },
        body1: {
          fontSize: '1rem',
          fontWeight: 400,
        },
        body2: {
          fontSize: '.83rem',
          fontWeight: 400,
        },
        button: {
          fontSize: '1rem',
          fontWeight: 500,
        },
        caption: {
          fontSize: '.67rem',
          fontWeight: 400,
        },
        overline: {
          fontSize: '.67rem',
          fontWeight: 500,
        },
      },
      // breakpoints: {
      //   values: {
      //     xs: 0,
      //     sm: 600,
      //     md: 900,
      //     lg: 1200,
      //     xl: 1536,
      //   }
      // },
      tableRow: {
        "&:hover": {
          backgroundColor: "blue !important"
        }
      },
      // MuiTableRow: {
      //   styleOverrides: {
      //     // Even though there is a hover rule we have to override it here. Don't ask.
      //     root: {
      //       '&.MuiTableRow-hover:hover': {
      //         backgroundColor: 'blue',
      //       },
      //     },
      //   },
      // }
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              fontWeight: 600,
            },
            root: {
              padding: '8px 16px',
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              padding: '4px 8px',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              borderRadius: 8,
            },
          },
        },
      },
    }
  );
  return responsiveFontSizes(theme)
}
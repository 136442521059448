import { 
  Box, Checkbox, Divider, FormControl, FormControlLabel, 
  Grid, Paper, Stack, Typography, useTheme, useMediaQuery 
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { poolFacilidades } from '../../../constants/places';

export const Caracteristicas2 = ({ setPlaceEdit, placeEdit, setNewPlace, newPlace }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [caracteristica, setCaracteristica] = useState(placeEdit);

  useEffect(() => {
    setPlaceEdit(caracteristica);
  }, [caracteristica]);

  const NumberField = ({ label, value, field, maxLength = 2, width = '80px' }) => (
    <TextFieldMask
      label={label}
      variant="outlined"
      size="small"
      mask="number"
      value={value || ""}
      onChange={(e) => setCaracteristica({ ...caracteristica, [field]: e.target.value })}
      onBlur={(e) => setCaracteristica({ ...caracteristica, [field]: e.target.value })}
      sx={{
        width,
        '& .MuiOutlinedInput-root': {
          borderRadius: 2,
        }
      }}
      inputProps={{ 
        maxLength, 
        style: { textAlign: 'center' } 
      }}
    />
  );

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{
        width: '100%',
        p: 0
      }}
    >
      <Stack spacing={3}>
        {/* Specifications Section */}
        <Paper 
          elevation={2}
          sx={{ p: 2, borderRadius: 2 }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" color="primary" fontWeight="medium">
              Especificações Gerais
            </Typography>

            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2}
              sx={{ 
                flexWrap: 'wrap',
                justifyContent: 'space-between'
              }}
            >
              <Stack direction="row" spacing={2}>
                <NumberField 
                  label="Dorm. De" 
                  value={caracteristica.quartosqtdmin} 
                  field="quartosqtdmin"
                />
                <NumberField 
                  label="Dorm. Até" 
                  value={caracteristica.quartosqtdmax} 
                  field="quartosqtdmax"
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextFieldMask
                  label="Área Menor"
                  variant="outlined"
                  size="small"
                  mask="decimal"
                  value={caracteristica.minarea || ""}
                  onChange={(e) => setCaracteristica({ ...caracteristica, minarea: e.target.value })}
                  sx={{ width: '100px' }}
                  inputProps={{ maxLength: 6 }}
                />
                <TextFieldMask
                  label="Área Maior"
                  variant="outlined"
                  size="small"
                  mask="decimal"
                  value={caracteristica.maxarea || ""}
                  onChange={(e) => setCaracteristica({ ...caracteristica, maxarea: e.target.value })}
                  sx={{ width: '100px' }}
                  inputProps={{ maxLength: 6 }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <NumberField 
                  label="Pavimentos" 
                  value={caracteristica.andares} 
                  field="andares"
                  width="90px"
                />
                <NumberField 
                  label="Torres" 
                  value={caracteristica.blocos} 
                  field="blocos"
                  width="80px"
                />
              </Stack>
            </Stack>
          </Stack>
        </Paper>

        {/* Facilities Section */}
        <Paper 
          elevation={2}
          sx={{ p: 2, borderRadius: 2 }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" color="primary" fontWeight="medium">
              Facilidades e Comodidades
            </Typography>

            <Box 
              sx={{ 
                maxHeight: '400px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                },
              }}
            >
              <Grid 
                container 
                spacing={1}
                sx={{ p: 1 }}
              >
                {poolFacilidades.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.campo}>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          checked={caracteristica?.[item.campo] || false}
                          onChange={(e) => setCaracteristica({ 
                            ...caracteristica, 
                            [item.campo]: e.target.checked 
                          })}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {item.nome}
                        </Typography>
                      }
                      sx={{
                        width: '100%',
                        margin: 0,
                        '& .MuiCheckbox-root': {
                          padding: '4px'
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
};
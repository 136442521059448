import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
  Alert,
  InputAdornment,
  Tab,
  Tabs,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade
} from '@mui/material';
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  LocationOn as LocationOnIcon,
  Assignment as AssignmentIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { atualizarCarteira } from '../../../controllers/crm/carteiraController';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

export default function CarteiraDetalhe({ account, cliente, setSnackbar, onClose, onUpdate }) {


  console.log('account', account)
  console.log('cliente', cliente)
  const clienteId = cliente.idcliente;
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  // Convert date string to dayjs object if it exists
  const initialDate = cliente.dtnascimento
    ? dayjs(cliente.dtnascimento)
    : null;

  const [carteira, setCarteira] = useState({
    idcliente: clienteId,
    idconta: cliente.idconta,
    idusuario: account.id,
    nome: cliente.nome,
    telefone: cliente.telefone,
    email: cliente.email,
    dtnascimento: initialDate,
    observacao: cliente.observacao || '',
    recproposta: cliente.recproposta !== undefined ? cliente.recproposta : true,
    version: cliente.version
  });

  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear error for this field when user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: undefined
      });
    }

    setCarteira({
      ...carteira,
      [name]: value
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setCarteira({
      ...carteira,
      [name]: checked
    });
  };

  const handleDateChange = (date) => {
    setCarteira({
      ...carteira,
      dtnascimento: date
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields
    if (!carteira.nome.trim()) {
      newErrors.nome = 'Nome é obrigatório';
    }

    if (!carteira.telefone.trim()) {
      newErrors.telefone = 'Telefone é obrigatório';
    }

    if (carteira.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(carteira.email)) {
      newErrors.email = 'Email inválido';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value, true);
    setCarteira({
      ...carteira,
      telefone: formattedPhone
    });

    // Clear error if exists
    if (errors.telefone) {
      setErrors({
        ...errors,
        telefone: undefined
      });
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      // Show the tab with errors
      const fieldsToTabs = {
        nome: 0,
        telefone: 0,
        email: 0,
        dtnascimento: 0,
        observacao: 1
      };

      // Find the first field with error and show its tab
      const errorFields = Object.keys(errors);
      if (errorFields.length > 0) {
        const tabToShow = fieldsToTabs[errorFields[0]];
        setActiveTab(tabToShow);
      }
      return;
    }

    setSaveLoading(true);
    try {
      // Prepare data for API
      const saveData = {
        ...carteira,
        dtnascimento: carteira.dtnascimento ? carteira.dtnascimento.toISOString() : null,
        telefone: formatPhoneNumber(carteira.telefone, false)
      };

      const response = await atualizarCarteira(saveData);
      if (response.status === 200 || response.status === 201) {
        cliente.nome = carteira.nome
        cliente.telefone = carteira.telefone
        cliente.email = carteira.email
        cliente.dtnascimento = carteira.dtnascimento
        cliente.observacao = carteira.observacao
        cliente.recproposta = carteira.recproposta
        cliente.updatedat = response.data.data.updatedat
        cliente.version = response.data.data.version
        setSnackbar({
          open: true,
          message: clienteId ? 'Cliente atualizado com sucesso!' : 'Cliente criado com sucesso!',
          severity: 'success'
        });
        if (onClose) {
          onClose();
        }
      }
      else if (response.status === 210) {
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: 'error'
        });
      }
      else if (response.status === 215) {
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: 'error'
        });
        if (onUpdate) {
          onUpdate();
        }
        if (onClose) {
          onClose();
        }
      }
      else {
        setSnackbar({
          open: true,
          message: 'Erro ao salvar cliente',
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Erro ao salvar cliente:', error);
      setSnackbar({
        open: true,
        message: 'Erro ao salvar cliente',
        severity: 'error'
      });
    } finally {
      setSaveLoading(false);
    }
  };

  // Show confirmation dialog before closing if form has been modified
  const handleCloseRequest = () => {
    // setConfirmDialogOpen(true);
    // setConfirmDialogOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    if (onClose) {
      onClose();
    }
  };

  // Content height calculation based on screen size
  const getContentHeight = () => {
    if (isMobile) return 'auto'; // Auto height for mobile
    if (isTablet) return '36vh'; // Moderate fixed height for tablets
    if (isLarge) return '40vh';  // Larger height for big screens
    return '38vh'; // Default for medium screens
  };

  const renderPersonalTab = () => (
    <Fade in={activeTab === 0}>
      <Stack
        spacing={{ xs: 2, sm: 2 }}
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        sx={{
          height: getContentHeight(),
          overflowY: 'auto',
          pb: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack
          pt={1}
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 2 }}
        >
          <TextField
            fullWidth
            label="Nome completo"
            name="nome"
            value={carteira.nome}
            onChange={handleChange}
            error={!!errors.nome}
            helperText={errors.nome}
            required
            size={isMobile ? "small" : "medium"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="primary" />
                </InputAdornment>
              )
            }}
          />

          <TextField
            fullWidth
            label="Telefone"
            name="telefone"
            value={carteira.telefone}
            onChange={handlePhoneChange}
            error={!!errors.telefone}
            helperText={errors.telefone}
            required
            size={isMobile ? "small" : "medium"}
            placeholder="(99) 99999-9999"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon color="primary" />
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 2 }}
        >
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={carteira.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            size={isMobile ? "small" : "medium"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon color="primary" />
                </InputAdornment>
              )
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
              label="Data de nascimento"
              value={carteira.dtnascimento}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!errors.dtnascimento,
                  helperText: errors.dtnascimento,
                  size: isMobile ? "small" : "medium",
                }
              }}
            />
          </LocalizationProvider>
        </Stack>

        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={carteira.recproposta}
                onChange={handleSwitchChange}
                name="recproposta"
                color="primary"
                size={isMobile ? "small" : "medium"}
              />
            }
            label="Receber propostas"
          />
        </Box>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            // backgroundColor: theme.palette.background.default
          }}
        >
          <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
            <Typography variant="h5" color="textSecondary">
              Corretor : {cliente.operador.nome}
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Telefone : {cliente.operador.telefone}
            </Typography>
          </Stack>
        </Paper>

        {/* Flexible spacer */}
        <Box sx={{ flexGrow: 1 }} />
      </Stack>
    </Fade>
  );

  const renderNotesTab = () => (
    <Fade in={activeTab === 1}>
      <Stack
        spacing={3}
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        sx={{
          pt: 1,
          height: getContentHeight(),
          overflowY: 'auto',
          pb: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TextField
          fullWidth
          label="Observações"
          name="observacao"
          value={carteira.observacao}
          onChange={handleChange}
          multiline
          rows={isMobile ? 6 : isTablet ? 8 : 10}
          size={isMobile ? "small" : "medium"}
          placeholder="Adicione notas importantes sobre o cliente..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ alignSelf: 'flex-start', marginTop: 1 }}>
                <AssignmentIcon color="primary" />
              </InputAdornment>
            )
          }}
          sx={{ flexGrow: 1 }}
        />
      </Stack>
    </Fade>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: isMobile ? '60vh' : '400px'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Paper
        sx={{
          p: { xs: 2, sm: 3 },
          borderRadius: { xs: isMobile ? 0 : 2, sm: 2 },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: isMobile ? '100%' : '85vh',
          overflow: 'hidden'
        }}
        elevation={isMobile ? 0 : 3}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: { xs: 1.5, sm: 2 },
          position: 'sticky',
          top: 0,
          bgcolor: 'background.paper',
          zIndex: 10,
          py: 1
        }}>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            fontWeight="bold"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '80%'
            }}
          >
            {clienteId ? `${isMobile ? 'Editar' : 'Editar Cliente:'} ${cliente.nome}` : 'Novo Cliente'}
          </Typography>

          <Box>
            <IconButton
              onClick={handleCloseRequest}
              aria-label="fechar"
              size={isMobile ? "small" : "medium"}
            >
              <CloseIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </Box>
        </Box>

        {Object.keys(errors).length > 0 && (
          <Alert
            severity="error"
            sx={{ mb: { xs: 1, sm: 2 } }}
            icon={<WarningIcon fontSize="small" />}
            variant="filled"
          >
            Por favor, corrija os campos destacados antes de salvar.
          </Alert>
        )}

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant={isMobile ? "fullWidth" : "standard"}
          sx={{
            mb: { xs: 1, sm: 2 },
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              minHeight: { xs: '42px', sm: '48px' }
            }
          }}
        >
          <Tab
            label="Dados Pessoais"
            icon={isMobile ? null : <PersonIcon fontSize="small" />}
            iconPosition="start"
          />
          <Tab
            label="Observações"
            icon={isMobile ? null : <AssignmentIcon fontSize="small" />}
            iconPosition="start"
          />
        </Tabs>

        <Box sx={{
          flexGrow: 1,
          overflow: 'hidden',
          mt: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
          {activeTab === 0 && renderPersonalTab()}
          {activeTab === 1 && renderNotesTab()}
        </Box>

        <Divider sx={{ mt: 'auto', mb: 2 }} />

        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          pt: { xs: 1, sm: 2 },
          gap: { xs: 1, sm: 2 }
        }}>
          <Button
            variant="outlined"
            onClick={handleCloseRequest}
            disabled={saveLoading}
            startIcon={!isMobile && <CloseIcon />}
            size={isMobile ? "small" : "medium"}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={saveLoading}
            startIcon={!isMobile && (saveLoading ?
              <CircularProgress size={20} color="inherit" /> :
              <SaveIcon />)
            }
            size={isMobile ? "small" : "medium"}
          >
            {saveLoading && isMobile ?
              <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} /> :
              null
            }
            {clienteId ? 'Atualizar' : 'Salvar'}
          </Button>
        </Box>
      </Paper>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            borderRadius: isMobile ? 0 : 2,
            width: isMobile ? '100%' : 'auto',
            m: isMobile ? 0 : 2
          }
        }}
      >
        <DialogTitle sx={{ fontSize: isMobile ? '1.1rem' : '1.25rem' }}>
          Descartar alterações?
        </DialogTitle>
        <DialogContent>
          <Typography variant={isMobile ? "body2" : "body1"}>
            Todas as alterações não salvas serão perdidas. Deseja continuar?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
          <Button
            onClick={() => setConfirmDialogOpen(false)}
            size={isMobile ? "small" : "medium"}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmClose}
            color="error"
            variant="contained"
            size={isMobile ? "small" : "medium"}
          >
            Descartar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';  // Update this import

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import LeadsDashboard from '../../components/Crm/Dashboard/LeadsDashboard';
import Lembretes from '../../components/Crm/Lembretes/Lembretes';
import EscalasSelection from '../../components/Crm/Escalas/EscalasSelection';
import LeadsDistribuicao from '../../components/Crm/Leads/LeadsDistribuicao';

import { getMyLeads, listLeadsDistribuidos } from '../../controllers/crm/leadController';
// import { leadStatus } from '../../constants/crm';
// import { getLeads } from '../../controllers/crm/leadController';
import { listenToCredito } from '../../controllers/userController';

import MenuIcon from '@mui/icons-material/Menu';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import ShoppingCartCheckoutTwoToneIcon from '@mui/icons-material/ShoppingCartCheckoutTwoTone';
import SocialDistanceTwoToneIcon from '@mui/icons-material/SocialDistanceTwoTone';
import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import BookmarksTwoToneIcon from '@mui/icons-material/BookmarksTwoTone';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import FolderSharedTwoToneIcon from '@mui/icons-material/FolderSharedTwoTone';

import { CampanhaSelect } from '../../components/Crm/Campanhas/CampanhaSelect';
import ParticiparEscala from '../../components/Crm/Escalas/ParticiparEscala';
import MyLeads from '../../components/Crm/Leads/MyLeads';
import { MyTasks } from '../../components/Crm/Leads/MyTasks';
import { rules } from '../../constants/rules';
import { plans } from '../../constants/plans';

import dayjs from 'dayjs';
import { Alert, Fab, Snackbar, useMediaQuery } from '@mui/material';
import CarteiraSelecao from '../../components/Crm/Clientes/CarteiraSelecao';

// import { use } from 'react';



const drawerWidth = 240;

function Home({ account, handleToHome, handleLogOut, setHasNewLead, coordinates, listen, disableAlertLead, update, setUpdate }) {

  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [isClosing, setIsClosing] = React.useState(false);
  const [ativarTmp, setAtivarTmp] = React.useState(false)
  const [temporizador, setTemporizador] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const [dayInicio, setDayInicio] = React.useState(dayjs().startOf('week').toISOString())
  const [dayFim, setDayFim] = React.useState(dayjs().endOf('week').toISOString())

  const [showControleOnLine, setShowControleOnLine] = React.useState(false);
  const [showLeadsCampanha, setShowLeadsCampanha] = React.useState(false);
  const [showLeadsDistribuicao, setShowLeadsDistribuicao] = React.useState(false);
  const [showDashboard, setShowDashboard] = React.useState(false);
  const [showCarteira, setShowCarteira] = React.useState(false);

  const [showMyLeads, setShowMyLeads] = React.useState(false);
  const [showLembretes, setShowLembretes] = React.useState(true);
  const [showMyTasks, setShowMyTasks] = React.useState(false);
  const [showOnLine, setShowOnLine] = React.useState(false);

  // Snackbar - Mensagens
  const [snackbar, setSnackbar] = React.useState(null)
  const handleCloseSnackbar = () => setSnackbar(null);

  // Cache de dados
  const [escalas, setEscalas] = React.useState([])
  const [escala, setEscala] = React.useState(null)

  const [dados, setDados] = React.useState(null)
  const [leadsList, setLeadsList] = React.useState([])
  const [leadsAll, setLeadsAll] = React.useState([])
  const [filteredLeads, setFilteredLeads] = React.useState([])
  const [reminders, setReminders] = React.useState([])
  const [tasks, setTasks] = React.useState([])



  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    // if (!isClosing) {
    setMobileOpen(!mobileOpen);
    // }
  };


  // Load Functions
  // Load MyLeads
  async function loadMyLeads() {
    setLoading(true)
    try {
      const api = await getMyLeads(account)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('MyLeads', api)
      if (api.status === 200) {
        // setUpdate(false)
        if (api.data.length > 0) {
          // console.log('MyLeads', api.data)
          setLeadsList(api.data)
          setFilteredLeads(api.data)
          // setDados(estatistica(api.data))
        }
        else {
          setLeadsList([])
          setFilteredLeads([])
          setDados(null)
        }
      }
      else {
        setLeadsList([])
        setFilteredLeads([])
        setDados(null)
      }
    } catch (e) {
      // console.log('Error', e)
      setSnackbar({ message: 'Erro ao carregar Leads', severity: 'error' })
    }
    finally {
      setLoading(false)
    }
  }

  // Load All Leads
  async function loadAllLeads() {
    setLoading(true)
    try {
      const api = await listLeadsDistribuidos(account)
      if (api.status === 200) {
        if (api.data.length > 0) {
          setLeadsAll(api.data)
        }
        else {
          setLeadsAll([])
        }
      }
      else {
        setLeadsAll([])
      }
    } catch (e) {

    }
    finally {
      setLoading(false)
    }
  }



  const menu = ['OnLine Controle', 'Leads Campanha', 'Leads Distribuição', 'Dashboard']
  const menu2 = ['Leads', 'Lembretes', 'Tarefas', 'OnLine', 'Carteira', 'Imóveis', 'LogOut']
  const [tituloPage, setTituloPage] = React.useState(menu2[1])

  const selectOption = (option) => {
    if (option !== 'OnLine Controle' && showControleOnLine) { setShowControleOnLine(false); }
    if (option !== 'Leads Campanha' && showLeadsCampanha) { setShowLeadsCampanha(false); }
    if (option !== 'Leads Distribuição' && showLeadsDistribuicao) { setShowLeadsDistribuicao(false); }
    if (option !== 'Dashboard' && showDashboard) { setShowDashboard(false); }
    if (option !== 'Carteira' && showCarteira) { setShowCarteira(false); }
    if (option !== 'Leads' && showMyLeads) { setShowMyLeads(false); }
    if (option !== 'Lembretes' && showLembretes) { setShowLembretes(false); }
    if (option !== 'Tarefas' && showMyTasks) { setShowMyTasks(false); }
    if (option !== 'OnLine' && showOnLine) { setShowOnLine(false); }
    switch (option) {
      case 'OnLine Controle':
        // showControleOnLine ? setShowControleOnLine(false) : 
        setShowControleOnLine(true)
        setTituloPage(menu[0])
        break;
      case 'Leads Campanha':
        // showLeadsCampanha ? setShowLeadsCampanha(false) : 
        setShowLeadsCampanha(true)
        setTituloPage(menu[1])
        break;
      case 'Leads Distribuição':
        // showLeadsDistribuicao ? setShowLeadsDistribuicao(false) : 
        setShowLeadsDistribuicao(true)
        setTituloPage(menu[2])
        break;
      case 'Dashboard':
        // showDashboard ? setShowDashboard(false) : 
        setShowDashboard(true)
        setTituloPage(menu[3])
        break;

      case 'Leads':
        // showMyLeads ? setShowMyLeads(false) :
        setShowMyLeads(true)
        setTituloPage(menu2[0])
        break;
      case 'Lembretes':
        // showLembretes ? setShowLembretes(false) : 
        setShowLembretes(true)
        setTituloPage(menu2[1])
        break;
      case 'Tarefas':
        // showMyTasks ? setShowMyTasks(false) : 
        setShowMyTasks(true)
        setTituloPage(menu2[2])
        break;
      case 'OnLine':
        // showOnLine ? setShowOnLine(false) : 
        setShowOnLine(true)
        setTituloPage(menu2[3])
        break;
      case 'Carteira':
        // showCarteira ? setShowCarteira(false) :
        setShowCarteira(true)
        setTituloPage(menu2[4])
        break;
      case 'Imóveis':
        handleToHome()
        break;
      case 'LogOut':
        handleLogOut()
        break;
      default:
        break;
    }
    handleDrawerClose()
  }

  const drawer = (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.background.paper,
        height: '100%',
        color: theme.palette.primary.contrastText,
        transition: 'all 0.3s ease',
        '& .MuiListItemButton-root': {
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`,
            transform: 'translateX(4px)',
            '& .MuiListItemIcon-root, & .MuiListItemText-root': {
              color: theme.palette.primary.contrastText
            }
          }
        },
        '& .MuiDivider-root': {
          borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      }}
    >
      {/* Logotipo */}
      <Box
        my={1.3}
        mx={3}
        height={'45px'}
        width={'139px'}
        sx={{
          display: { xs: 'flex', md: 'flex' },
          backgroundImage: 'url(./logo1n_bc.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      {/* Drawer Content */}
      {(account.rules.includes(rules.ruleAdm.title) &&
        account.plano.toLowerCase() !== plans.planUser.title.toLowerCase()) && (
          <>
            <Divider sx={{ borderColor: theme.palette.divider }} />
            <List>
              {menu.map((text) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    onClick={() => selectOption(text)}
                    sx={{
                      py: 1.5,
                      '& .MuiListItemIcon-root': {
                        color: theme.palette.primary.contrastText,
                        minWidth: 40
                      },
                      '& .MuiListItemText-root': {
                        color: theme.palette.primary.contrastText,
                        '& .MuiTypography-root': {
                          fontWeight: 500
                        }
                      }
                    }}
                  >
                    <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}>
                      {text === 'Dashboard' && <AssessmentTwoToneIcon />}
                      {text === 'OnLine Controle' && <CalendarMonthTwoToneIcon />}
                      {text === 'Leads Campanha' && <ShoppingCartCheckoutTwoToneIcon />}
                      {text === 'Leads Distribuição' && <SocialDistanceTwoToneIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{
                        color: theme.palette.primary.contrastText,
                        '& .MuiTypography-root': {
                          fontWeight: 500
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
      <Divider sx={{ borderColor: theme.palette.divider }} />
      {/* Menu2 List */}
      <List>
        {menu2.map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => selectOption(text)}
              sx={{
                py: 1.5,
                '& .MuiListItemIcon-root': {
                  color: theme.palette.primary.contrastText,
                  minWidth: 40
                },
                '& .MuiListItemText-root': {
                  color: theme.palette.primary.contrastText,
                  '& .MuiTypography-root': {
                    fontWeight: 500
                  }
                }
              }}
            >
              <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}>
                {text === 'Carteira' && <FolderSharedTwoToneIcon />}
                {text === 'Leads' && <EmojiEmotionsTwoToneIcon />}
                {text === 'Lembretes' && <BookmarksTwoToneIcon />}
                {text === 'Tarefas' && <CalendarTodayTwoToneIcon />}
                {text === 'OnLine' && <AddIcCallTwoToneIcon />}
                {text === 'Imóveis' && <MapsHomeWorkIcon />}
                {text === 'LogOut' && <ExitToAppTwoToneIcon />}
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  color: theme.palette.primary.contrastText,
                  '& .MuiTypography-root': {
                    fontWeight: 500
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleCreditoUpdate = (result) => {
    if (result.success) {
      account.credito = result.credito
    } else {
      setSnackbar({ open: true, message: result.message, severity: 'error' });
    }
  };

  React.useEffect(() => {
    // loadMyLeads()
    // loadAllLeads()
    const subscription = listenToCredito(account, handleCreditoUpdate)
    return () => subscription();
  }, [])

  React.useEffect(() => {
    loadAllLeads()
    loadMyLeads()
  }, [update])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" noWrap component="div">
              Gestão de Leads - {tituloPage}
            </Typography>
            {/* Icone do menu para alerta de lead } */}
            {listen &&
              <IconButton
                size="medium"
                edge="end"
                aria-label="Lead"
                color='error'
                onClick={disableAlertLead}
                className={'btn-pisca'}
              >
                <CampaignOutlinedIcon fontSize='large' /> Lead
              </IconButton>
            }
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { sm: 'none' }
            }}
          >
            <MenuIcon fontSize='large' />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="menu"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={document.body}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
            disablePortal: true,
            disableEnforceFocus: false,
            disableRestoreFocus: false
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={
          {
            flexGrow: 1,
            width: { xs: '100vw', sm: `calc(100vw - ${drawerWidth}px)` },
            height: `calc(100vh - 55px)`,
            justifyItems: 'center',
            bgcolor: 'secondary.main',
            overflow: 'auto',
          }
        }
      >
        <Toolbar />
        <Fab

          color="primary"
          aria-label="add"
          onClick={handleDrawerToggle}
          sx={{
            position: 'absolute',
            left: '32px',
            bottom: '16px',
            zIndex: 1100,
            display: { xs: 'flex', md: 'none' }
          }}
        >
          <MenuIcon />
        </Fab>
        {showControleOnLine &&
          <EscalasSelection
            account={account}
            setEscalas={setEscalas}
            escalas={escalas}
            setEscala={setEscala}
            escala={escala}
          />
        }
        {showLeadsCampanha &&
          <CampanhaSelect
            user={account}
            setSnackbar={setSnackbar}
            setLoading={setLoading}
            loading={loading}
          />}
        {showLeadsDistribuicao &&
          <LeadsDistribuicao
            account={account}
            setUpdate={setUpdate}
            setSnackbar={setSnackbar}
            listen={listen}
          />
        }
        {showDashboard &&
          <LeadsDashboard
            account={account}
            setLeadsAll={setLeadsAll}
            leadsAll={leadsAll}
            update={update}
            setUpdate={setUpdate}
            setAtivarTmp={setAtivarTmp}
            ativarTmp={ativarTmp}
            temporizador={temporizador}
            setTemporizador={setTemporizador}
            loadAllLeads={loadAllLeads}
          />
        }
        {showCarteira &&
          <CarteiraSelecao
            account={account}
            setSnackbar={setSnackbar}
          />
        }
        {showMyLeads &&
          <MyLeads
            account={account}
            leadsList={leadsList}
            setLeadsList={setLeadsList}
            filteredLeads={filteredLeads}
            setFilteredLeads={setFilteredLeads}
            setSnackbar={setSnackbar}
            showMyLeads={showMyLeads}
            setShowMyLeads={setShowMyLeads}
            setUpdate={setUpdate}
            update={update}
            // setShowMenu={setShowMenu}
            loadMyLeads={loadMyLeads}
          />
        }
        {showLembretes &&
          <Lembretes
            account={account}
            setSnackbar={setSnackbar}
            setShowReminders={setShowLembretes}
            reminders={reminders}
            setReminders={setReminders}
            setUpdate={setUpdate}
            update={update}
          />
        }
        {showMyTasks &&
          <MyTasks
            account={account}
            setSnackbar={setSnackbar}
            // setShowTasks={setShowTasks}
            tasks={tasks}
            setTasks={setTasks}
            setUpdate={setUpdate}
            update={update}
            // setShowMenu={setShowMenu}
            dayInicio={dayInicio}
            dayFim={dayFim}
            setDayInicio={setDayInicio}
            setDayFim={setDayFim}
          />
        }
        {showOnLine &&
          <ParticiparEscala
            account={account}
            setSnackbar={setSnackbar}
            snackbar={snackbar}
            setUpdate={setUpdate}
            update={update}

          />
        }
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          autoHideDuration={4000}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            variant="filled"
            elevation={6}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}


export default Home;

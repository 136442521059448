import { AppBar, Box, Fab, Grid, IconButton, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLeadFasesEtapasG } from '../../constants/fases';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { delAlerta } from '../../controllers/messageriaController';
import Home from './Home';
import { playSound } from '../../components/Sounds/playSound';

export function Crm() {

  let navigate = useNavigate()
  const { state } = useLocation();
  const { account } = state

  const [hasNewLead, setHasNewLead] = useState(false)
  const [coordinates, setCoordinates] = useState(state.coordinates)
  const [update, setUpdate] = useState(Date.now())

  const [listen, setListen] = useState(null)
  useEffect(() => {
    const listenLeads = onSnapshot(doc(db, "alerts", account.id), async (snapshot) => {
      const alert = snapshot.data()
      setListen(alert)
      if (alert){ 
        playSound(); 
      }
    })
    return () => listenLeads();
  }, [])

  function disableAlertLead() {
    try {
      delAlerta(account.id)
      setHasNewLead(false)
    } catch (e) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro', e)
    }
  }
  const handleToHome = () => {
    navigate('/home', { replace: true, state: { account: account, coordinates: coordinates } })
  }
  async function handleLogOut() {
    navigate('/singin', { replace: true })
  }

  useEffect(() => {
    let token = localStorage.getItem('token')
    getLeadFasesEtapasG(account) // Carrega as Fases e Etapas
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(account, token);
  }, []);

  return (
    <Grid
      container
      direction={'row'}
      sx={{ backgroundColor: 'yellow' }}
    >
      {/* Menu Superior */}
      <Grid
        container
        height={'6vh'}
      >
        <AppBar position="fixed" >
          <Toolbar >
            <Grid container
              alignItems={'center'}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Box
                height={'45px'}
                width={'139px'}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  backgroundImage: 'url(./logo1n_bc.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              />
              {/* Icone do menu para alerta de lead } */}
              {/* {listen &&
                <Grid item
                  justifyContent={'center'}
                >
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="Lead"
                    color='error'
                    onClick={disableAlertLead}
                    className={'btn-pisca'}
                  >
                    <CampaignOutlinedIcon fontSize='large' /> Lead
                  </IconButton>
                </Grid>
              } */}
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      {/* Corpo da Página */}
      {/* <Container> */}
      <Grid container
        // height={'94vh'}
        direction={'row'}
      // alignItems={'center'}
      >
        <Home
          account={account}
          handleToHome={handleToHome}
          handleLogOut={handleLogOut}
          setHasNewLead={setHasNewLead}
          coordinates={coordinates}
          listen={listen}
          disableAlertLead={disableAlertLead}
          update={update}
          setUpdate={setUpdate}
        />
      </Grid>
      {/* </Container> */}
    </Grid>
  );
}
import React from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { blueGrey, green, grey, orange, red, yellow } from '@mui/material/colors';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


const COLORS = [red[500], orange[500], grey[500], green[500], blueGrey[500]];

export default function LeadsCharts({ dados, leadsAll }) {
  if (!dados || !leadsAll.length) return null;

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Leads Charts', dados, leadsAll); }

  const getStatusData = () => {
    return dados?.map(d => ({
      name: d.fase,
      value: d.total
    })) || [];
  };

  const getSituacaoData = () => {
    return dados?.encerramento?.map(d => ({
      name: d.motivo,
      value: d.qtd
    })) || [];
  };

  const qtdOperador = () => {
    // localiza nome null em dados.operador
    let _operador = dados?.operador || []
    if (_operador.some(op => op.nome === null)) return _operador.length - 1
    return _operador.length
  }

  const getOperatorData = () => {
    return dados?.operador?.map(op => ({
      name: op.nome || 'Aguardando...',
      leads: op.qtd
    })) || [];
  };

  const getCanalData = () => {
    return dados?.canal?.map(ca => ({
      name: ca.canal || 'Outros',
      value: ca.qtd
    })) || [];
  };

  // console.log('qtdOperador', qtdOperador())

  return (
    <Container maxWidth="xl">
      <Box py={3}>
        <Grid container spacing={3}>
          {/* Summary Cards */}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ bgcolor: grey[100], height: '100%' }}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Total de Leads
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4">{dados?.total || 0}</Typography>
                    <TrendingUpIcon sx={{ color: green[500] }} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ bgcolor: grey[100], height: '100%' }}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Total de Operadores
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4">{qtdOperador() || 0}</Typography>
                    <PeopleAltIcon sx={{ color: orange[500] }} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* Pie Charts */}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ bgcolor: grey[100], height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribuição por Fase
                  </Typography>
                  <ResponsiveContainer width="100%" height={350}>
                    <PieChart>
                      <Pie
                        data={getStatusData()}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                      >
                        {getStatusData().map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ bgcolor: grey[100], height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribuição por Situação
                  </Typography>
                  <ResponsiveContainer width="100%" height={350}>
                    <PieChart>
                      <Pie
                        data={getSituacaoData()}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                      >
                        {getSituacaoData().map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ bgcolor: grey[100], height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribuição por Canal
                  </Typography>
                  <ResponsiveContainer width="100%" height={350}>
                    <PieChart>
                      <Pie
                        data={getCanalData()}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                      >
                        {getCanalData().map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
            {/* Bar Chart */}
            <Grid item
              xs={12}
              sm={dados.operador.length > 5 ? 12 : 6}
            >
              <Card sx={{ bgcolor: grey[100], height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Leads por Operador
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={getOperatorData()}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="leads" fill={orange[500]} />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

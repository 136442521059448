import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardActions, Typography, Grid, Container, CssBaseline, Alert, CircularProgress, Input } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { leadStatus, processFases, taskType } from '../../../constants/crm';
import { getLeads } from '../../../controllers/crm/leadController';
import { useTheme } from '@emotion/react';
import LeadDetails from './LeadDetails';
import { gFases, gFasesetapas } from '../../../constants/fases';
import { push, set, update } from 'firebase/database';
import { MyTasks } from './MyTasks';
import { MyReminders } from './MyReminders';
import { green, teal } from '@mui/material/colors';
import { getColorFaseByOrdem } from '../../../utils/crm/getColors';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
import {
  Box, Stack, TextField, IconButton,
  Paper, Fade, useMediaQuery, Select, MenuItem, Pagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { motion } from 'framer-motion';
dayjs.locale('pt-br');

export default function MyLeads({
  account, setShowMyLeads,
  update, setUpdate, setSnackbar,
  leadsList, setLeadsList, loadMyLeads,
  filteredLeads, setFilteredLeads, snackbar
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const idconta = account.conta
  const idusuario = account.id
  const nome = account.nome

  const [showLeadDetails, setShowLeadDetails] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectLead, setSelectLead] = useState(null)
  const [showOption, setShowOption] = useState(false)
  const [option, setOption] = useState(null)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function procurarLeads(event) {
    const value = event.target.value;
    if (!value) {
      setFilteredLeads(leadsList);
      setPage(0);
      return;
    }

    const filtered = leadsList.filter(lead =>
      lead.nome?.toLowerCase().includes(value.toLowerCase()) ||
      lead.email?.toLowerCase().includes(value.toLowerCase()) ||
      lead.telefone?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredLeads(filtered);
    setPage(0);
  }

  function estatistica() {
    let cont = []
    let dados = []
    let total = 0
    const leads = leadsList.length
    for (let i = 0; i < gFases.length; i++) {
      let _fase = gFases[i]
      cont[i] = 0
      for (let j = 0; j < leads; j++) {
        if (leadsList[j].idfase === _fase.idfase) {
          cont[i]++
        }
      }
      _fase.total = cont[i]
      total += cont[i]
      dados.push(_fase)
    }
    dados.total = total
    return dados
  }

  const dados = estatistica()

  function handleOpenLead(lead) {
    setSelectLead(lead)
    setShowLeadDetails(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    // if (showMyLeads) {
    loadMyLeads()
    // }
  }, [])


  return (
    <Box
      sx={{
        width: '95%',
        height: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 2,
      }}
    >
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      {!showLeadDetails ? (
        <>
          <Stack
            spacing={2}
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              boxShadow: 1,
              position: 'sticky',
              top: 0,
              zIndex: 1000
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                color="primary"
              >
                Meus Leads
              </Typography>
              <Typography variant="h5" color="text.secondary">
                Total: {leadsList.length}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <TextField
                fullWidth
                size="small"
                placeholder="Buscar lead..."
                onChange={(e) => procurarLeads(e)}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
                sx={{ bgcolor: 'background.paper' }}
              />
              <IconButton
                onClick={loadMyLeads}
                disabled={loading}
                color="primary"
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <ReplayTwoToneIcon />
              </IconButton>
            </Stack>
          </Stack>

          <Box sx={{
            flex: 1,
            bgcolor: 'grey.100',
            overflow: 'auto',
            p: 2
          }}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {filteredLeads
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((lead, index) => (
                    <Fade in key={lead.idlead} style={{ transitionDelay: `${index * 30}ms` }}>
                      <Paper
                        component={motion.div}
                        whileHover={{ scale: 1.01 }}
                        onClick={() => handleOpenLead(lead)}
                        elevation={2}
                        sx={{
                          p: 2,
                          cursor: 'pointer',
                          bgcolor: getColorFaseByOrdem(lead.fase.ordem),
                          transition: 'all 0.2s',
                          '&:hover': {
                            bgcolor: green[300],
                            boxShadow: 4
                          }
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Stack>
                              <Typography variant="subtitle1" fontWeight="bold">
                                {lead.nome}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {lead.email}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Stack>
                              <Typography variant="body2" color="text.secondary">
                                Fase: {lead.fase.nome}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Etapa: {lead.etapa.nome}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography variant="body2">
                              {lead.telefone}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="text.secondary">
                              Criado em: {new Date(lead.createdat).toLocaleString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Fade>
                  ))}
              </Stack>

              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  bgcolor: 'background.paper'
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {`${page * rowsPerPage + 1}-${Math.min((page + 1) * rowsPerPage, filteredLeads.length)} de ${filteredLeads.length}`}
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Select
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    size="small"
                    sx={{
                      display: isMobile ? 'none' : 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <MenuItem value={5}>5 por página</MenuItem>
                    <MenuItem value={10}>10 por página</MenuItem>
                    <MenuItem value={25}>25 por página</MenuItem>
                    <MenuItem value={50}>50 por página</MenuItem>
                  </Select>
                  <Pagination
                    count={Math.ceil(filteredLeads.length / rowsPerPage)}
                    page={page + 1}
                    onChange={(e, p) => handleChangePage(e, p - 1)}
                    color="primary"
                    size={isMobile ? "small" : "medium"}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Box>
        </>
      ) : (
        <LeadDetails
          account={account}
          lead={selectLead}
          setShowMyLeads={setShowMyLeads}
          // setShowMenu={setShowMenu}
          setShowLeadDetails={setShowLeadDetails}
          showLeadDetails={showLeadDetails}
          setOption={setOption}
          setShowOption={setShowOption}
          setSnackbar={setSnackbar}
          setUpdate={setUpdate}
          update={update}
          setLoading={setLoading}
          loading={loading}
        />
      )}

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={4000}
          anchorOrigin={{
            horizontal: 'center',
            vertical: isMobile ? 'top' : 'bottom'
          }}
        >
          <Alert
            severity={snackbar.severity}
            onClose={() => setSnackbar(null)}
            variant="filled"
            elevation={6}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}




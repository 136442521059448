import { getFirestore, collection, query, getDocs, doc, addDoc, getDoc, setDoc, updateDoc, runTransaction, where, serverTimestamp, documentId, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { accountModel } from '../models/accountModel';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { userConverter, userModel } from '../models/userModel';
import { result } from '../utils/result';
import { getAllInviteByIdUser } from './inviteController';
import { saveOperador } from './equipeController';
import { getContaController } from './contaController';
import { apiGate } from '../services/api_gate';
import { rules } from '../constants/rules';
import { plans } from '../constants/plans';
import { getPerfilByLicence } from '../utils/getPerfilByLicence';

// import { setTokenMsg } from './messageriaController';


const firestore = getFirestore()


export function listenToCredito(user, callback) {
  try {
    var unsub = onSnapshot(doc(db, "usuarios", user.conta), (doc) => {
      if (!doc.exists) {
        callback({ success: false, message: 'Usuário não encontrado' });
      } else {
        const userData = doc.data();
        user.credito = userData.credito;
        callback({ success: true, credito: userData.credito });
     }},
      (error) => {
        callback({ success: false, message: error.message });
      }
    );
    return unsub;
  } catch (error) {
    callback({ success: false, message: error.message });
  }
}

// Função para parar de escutar as mudanças de credito em tempo real
export function stopListeningToCredito(unsubscribe) {
  if (typeof unsubscribe === 'function') {
    unsubscribe();
  }
}



// export async function setTokenMsg(_conta, _id) {
//   if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em setTokenMsg', _conta, _id) }
//   try {
//     const token = await generateToken()
//     await setDoc(doc(db, 'contas/' + _conta + '/tokenmsg/', _id), { token: token, date: new Date() })
//     result.sucess = true
//     result.msg = 'Token gerado com sucesso...'
//     result.data = token
//   }
//   catch (e) {
//     result.sucess = false
//     result.msg = 'Falha na geração do token...'
//     result.data = null
//     if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em setTokenMsg', e) }
//   }
//   finally {
//     if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saiu de setTokenMsg', result) }
//     return result
//   }
// }


export async function gravarUser(user) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em setUser', user) }
  if (user?.id === null) {
    result.sucess = false
    result.data = user
    result.msg = 'Usuario sem id...'
    return result
  }
  try {
    const userDocRef = doc(db, 'usuarios', user.id)

    await setDoc(userDocRef, user, { merge: true })
    result.sucess = true
    result.data = user
    result.msg = 'Registro gravado com sucesso.'
  }
  catch (e) {
    result.sucess = false
    result.data = e
    result.msg = 'Falha na gravação do registro.'
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em setUser', e) }
    return result
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saiu de setUser', result) }
    return result
  }

}

export async function setUserControl(usuario) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em setUserControl', usuario) }
  if (usuario?.id === null) return null;
  try {
    const userDocRef = doc(db, 'usuarios', usuario.id)
    await setDoc(userDocRef, usuario)
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em setUserControl', usuario, e) }
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saiu de setUserControl', usuario) }
    return usuario.id
  }
}


export const getUserControl = async (id) => {
  const user = new userModel();
  const docSnap = await getDoc(doc(firestore, 'usuarios', id))
  const data = docSnap.exists() ? docSnap.data() : null
  if (data === null || data === undefined) return null
  user.setData(data, id)
  return user
}


export const getAllUserContas = async (_contas) => {
  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou getAllUserContas :', _contas)
    let data = []
    const q = query(collection(db, "contas"),
      where(documentId(), "in", _contas),
    );
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      let cta = doc.data()
      cta.id = doc.id
      data.push(cta)
    });
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = data
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação ...'
    result.data = null
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getAllUserContas:', result, e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getAllUserContas:', result)
    return result
  }

}

export const getAllUserControl = async () => {
  const user = new userModel();
  let list = []
  const querySnapshot = await getDocs(collection(db, "usuarios"));
  querySnapshot.forEach((doc) => {
    user.setData(doc.data(), doc.id)
    list.push(user);
  });
  return list
}


export async function getUsuarioByEmail(_email) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getOperadorByEmail:', _email)
  let operadores = [];
  try {
    const q = query(collection(db, "usuarios"),
      where("email", "==", _email.toLowerCase()),
    );
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      operadores.push(doc.data())
    });
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('getOperadorByEmail:', operadores)
    return operadores
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em getOperadorByEmail', e)
  }
}

async function getOperadorByUser(_user) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em getOperadorByUser', _user) }
  let list = []
  try {
    const q = query(collection(db, 'contas/' + _user.conta[0] + '/operadores'),
      where('id', '==', _user.id),
      where('ativo', '==', true)
    )
    const queryData = await getDocs(q)
    queryData.forEach((doc) => {
      list.push(doc.data())
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saiu do getOperdorByUser', list, q) }
    return list
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em getOperdorByUser', e) }
  }
}
// Verificar duplicação getUsuarioByEmail
export async function getUserByEmail(_email) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em getUserByEmail', _email) }
  let list = []
  try {
    const q = query(collection(db, 'usuarios').withConverter(userConverter),
      where('email', '==', _email)
    )
    const queryData = await getDocs(q)
    queryData.forEach((doc) => {
      list.push(doc.data())
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saiu do getUserByEmail', list) }
    return list
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em getUserByEmail', e) }
    return []
  }
}

// Validar Login Integrado
async function getLicencaByEmail(email) {
  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getLicencaByEmail', email) }
    const api = await apiGate.get('/clients/licenca/' + email)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api)
    if (api.status === 200) {
      result.code = 0
      result.sucess = true
      result.msg = 'Usuário com assinatura ativa.'
      result.data = api.data
      // return result
    }
    else {
      result.code = 0
      result.sucess = false
      result.msg = 'Usuário sem assinatura ativa.'
      result.data = null
      // return result
    }
  }
  catch (e) {
    result.code = 5
    result.sucess = false
    result.msg = e.message
    result.msg2 = "Serviço de autenticação de assinaturas indisponível no momento, tente novamente mais tarde..."
    result.data = null
    // return result
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saiu do getLicencaByEmail', result) }
    return result
  }
}

let userRules = rules.ruleAdm.title
let userPermissions = rules.ruleAdm.permissions

// Valida a situação do plano do usuário
async function validateUserPlan(_user) {
  let dtcreated = _user.createdat.toDate()
  const rst = await getLicencaByEmail(_user.email)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Licença', rst)
  if (rst.sucess) {
    const licenca = rst.data
    dtcreated = licenca.create_at
    if (licenca.status === rules.customerStatus.ATV) {
      const userPerfil = getPerfilByLicence(licenca)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Perfil:', userPerfil)
      if (userPerfil) {
        userRules = userPerfil.userRules
        userPermissions = userPerfil.userPermissions
      }
      else {
        userRules = [rules.ruleViewer.title]
        userPermissions = rules.ruleViewer.permissions
      }

      return {
        code: 0,
        sucess: true,
        msg2: 'Usuário com assinatura ativa...',
        data: { rules: userRules, permissions: userPermissions, licenca: licenca }
      }
    } else {
      return {
        code: 5,
        sucess: false,
        msg2: 'Usuário com assinatura vencida...',
        data: null
      }
    }
  }
  else {
    return rst
    // if (rst.msg === 'Network Error') {
    //   if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('validateUserPlan - Network Error !!!')
    //   return rst
    // }
    // else if (rst.code === 5) {
    //   return rst
    // }
    // else {
    //   return rst
    //   // Alteração da política de degustação
    //   // if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Email sem Assinatura Licenciada', _user, _user.email) }
    //   // return validateDtCreationUser(dtcreated)
    // }
  }
}

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function validateDtCreationUser(_date) {
  let dt1 = new Date(_date)
  let dt2 = new Date(Date.now())
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Datas:', dt1, dt2, _date)
  const diff = dateDiffInDays(dt1, dt2)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('dias:', diff)

  if (diff <= 3) {
    result.code = 0
    result.sucess = true
    result.msg = "Período de degustação...Você será redirecionado em breve..."
    result.data = { userRules, userPermissions, licenca: { subscription: { hascrm: false }, licenca: 'BROKER' } }
  } else if (diff > 3 && diff <= 8) {
    result.code = 1
    result.sucess = true
    result.msg = "Período de degustação...Você será redirecionado em breve..."
    result.data = { userRules, userPermissions, licenca: { subscription: { hascrm: false }, licenca: 'BROKER' } }
  } else if (diff > 8 && diff <= 16) {
    result.code = 2
    result.sucess = true
    result.msg = "Período de degustação encerrando, se você gostou do aplicativo sugerimos que considere realizar sua assinatura. Você será redirecionado em breve..."
    result.data = { userRules, userPermissions, licenca: { subscription: { hascrm: false }, licenca: 'BROKER' } }
  } else if (diff > 16 && diff <= 30) {
    result.code = 3
    result.sucess = true
    result.msg = "Período de degustação encerrado. Se você gostou do aplicativo incentivamos que realize sua assinatura. Você será redirecionado em breve..."
    result.data = { userRules, userPermissions, licenca: { subscription: { hascrm: false }, licenca: 'BROKER' } }
  } else if (diff > 30) {
    result.code = 4
    result.sucess = false
    result.msg = "Período de degustação encerrado. Se você gostou do aplicativo considere realizar sua assinatura."
    result.data = null
  }
  return result
}

function getValidouEmail(user) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou no getValidouEmail', user, user.emailVerified) }
  if (!user.emailVerified) {
    result.code = 6
    result.sucess = false
    result.msg = 'Valide seu email para acessar o sistema.'
    result.data = null
  }
  return result
}


// async function saveTokenMsg(_conta, _id) {
//   // Grava token de mensagem
//   const tokenMsg = await setTokenMsg(_conta, _id)
//   if (tokenMsg) {
//     if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Token gerado com sucesso...') }
//   }
//   else {
//     // todo Tratamento de usuário sem token de mensagem
//     if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em setTokenMsg', tokenMsg) }
//   }
//   return tokenMsg
// }



// **********************************
// Principal função de autenticação *
// **********************************
export async function getUserAccount(_user, _conta) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou no getUserAccount', _user, _user.email) }
  const user = await getUserByEmail(_user.email)
  if (user?.length > 0) { // Localizou Usuario
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuário selecionado:', user) }

    // Valida o Plano de Assinatura do Usuário
    const plan = await validateUserPlan(user[0])
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Plano:', plan, user) }
    if (plan.sucess) {
      user[0].plano = plan.data?.licenca ? plan.data.licenca.licenca : plans.planFree.title.toLocaleUpperCase()
      user[0].hasinvite = false
      user[0].hascrm = plan.data?.licenca.sys_subscription.hascrm ? plan.data.licenca.sys_subscription.hascrm : false
      user[0].crm = plan.data?.licenca.crm ? plan.data.licenca.crm : false

      //Verifica se o usuario validou o email
      const emailValido = getValidouEmail(_user)
      if (!emailValido.sucess) { return emailValido }

      //Verifica se o usuario recebeu convite
      let resInvite = await getAllInviteByIdUser(user[0].id)
      if (resInvite.sucess) {
        if (resInvite.data.length > 0) user[0].hasinvite = true
      }

      // Verifica a quantidade de contas vinculadas ao usuario
      let account = {}

      if (user[0].conta.length === 1) {  // Usuario com uma (1) conta vinculada

        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuario com 01 conta', user[0]) }

        // Busca dados da Conta
        const conta = await getContaController(user[0].conta)

        // Grava token de mensagem
        // const tokenMsg = await saveTokenMsg(user[0].conta[0], user[0].id)
        // if (tokenMsg) { user[0].tokenmsg = tokenMsg }


        // Usuario administrador de conta
        if (user[0].id === user[0].conta[0]) {

          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('USER -> ADM', (user[0].plano === plans.planUser.title.toLocaleUpperCase() && user[0].rules[0] === rules.ruleAdm.title))
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(plans.planUser.title.toLocaleUpperCase(), user[0].plano)
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(rules.ruleAdm.title, user[0].rules[0])
          if (user[0].plano === plans.planUser.title.toLocaleUpperCase() && user[0].rules[0] === rules.ruleAdm.title) {
            account = setAccount(
              user[0].id,
              user[0],
              plans.planUser.rule.permissions,
              [plans.planAdm.rule.title],
              user[0].conta[0],
              _user.accessToken,
              _user.emailVerified,
              user[0].hasinvite,
              conta.equipe,
              user[0].conta,
              user[0].credito,
              user[0].hascrm,
              user[0].telefone,
              user[0].tokenmsg,
              user[0].crm
            )
          }
          else {
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuario Administrador', user[0]) }
            account = setAccount(
              user[0].id,
              user[0],
              plans.planAdm.rule.permissions,
              [plans.planAdm.rule.title],
              user[0].conta[0],
              _user.accessToken,
              _user.emailVerified,
              user[0].hasinvite,
              conta.equipe,
              user[0].conta,
              user[0].credito,
              user[0].hascrm,
              user[0].telefone,
              user[0].tokenmsg,
              user[0].crm
            )
          }
          result.sucess = true
          result.data = account
          if (plan.code === 0) result.msg = 'Operação realizada com sucesso...'
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saida do getUserAccount:', result.data, 'Plan', plan) }

        }
        // Usuario operador de conta de terceiros
        else {
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuario Operador de Equipe - Conta de Terceiro', user[0]) }
          // Buscar em Operadores da Conta - contas/operadores
          const operador = await getOperadorByUser(user[0])
          if (operador.length > 0) {
            account = setAccount(
              operador[0].id,
              user[0],
              operador[0].permissions,
              operador[0].rules,
              user[0].conta[0],
              _user.accessToken,
              _user.emailVerified,
              user[0].hasinvite,
              conta.equipe,
              user[0].conta,
              user[0].credito,
              user[0].hascrm,
              user[0].telefone,
              user[0].tokenmsg,
              user[0].crm
            )

            result.sucess = true
            result.data = account
            result.msg = 'Operação realizada com sucesso...'
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saida do getUserAccount:', result.data) }
          }
          else {
            result.sucess = false
            result.data = account
            result.msg = 'Conta de trabalho inválida! Equipe de origem DESATIVADA. Caso necessário entre em contato com suporte@gate51.com.br. Obrigado!'
          }
        }
      }
      // -----------------------------------------------
      // *** Usuário com mais de uma conta vinculada ***
      // -----------------------------------------------

      if (user[0].conta.length > 1) {
        // Retorna para a interface para  definir a conta
        // e realizar novo login 
        if (_conta) {
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrando em Conta selecionada:', _conta, user)
          user[0].contas = user[0].conta
          user[0].conta = [_conta]

          // Busca dados da Conta
          const conta = await getContaController(user[0].conta)

          // Grava token de mensagem
          // const tokenMsg = await saveTokenMsg(user[0].conta[0], user[0].id)
          // if (tokenMsg) { user[0].tokenmsg = tokenMsg }

          // Usuario Administrador da Conta
          if (user[0].id === user[0].conta[0]) {
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuario Administrador') }
            account = setAccount(
              user[0].id,
              user[0],
              plans.planAdm.rule.permissions,
              [plans.planAdm.rule.title],
              user[0].conta[0],
              _user.accessToken,
              _user.emailVerified,
              user[0].hasinvite,
              conta.equipe,
              user[0].contas,
              user[0].credito,
              user[0].hascrm,
              user[0].telefone,
              user[0].tokenmsg,
              user[0].crm
            )

            result.sucess = true
            result.data = account
            result.msg = 'Operação realizada com sucesso...'
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saida do getUserAccount - Conta Selecionada:', result.data) }
          }
          // Usuario operador de conta de terceiros
          else {
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuario Operador de Equipe - Conta de Terceiro', user[0]) }
            // Buscar nos Operadores da Conta - contas/operadores
            const operador = await getOperadorByUser(user[0])
            if (operador.length > 0) {
              account = setAccount(
                operador[0].id,
                user[0],
                operador[0].permissions,
                operador[0].rules,
                user[0].conta[0],
                _user.accessToken,
                _user.emailVerified,
                user[0].hasinvite,
                conta.equipe,
                user[0].contas,
                user[0].credito,
                user[0].hascrm,
                user[0].telefone,
                user[0].tokenmsg,
                user[0].crm
              )

              result.sucess = true
              result.data = account
              result.msg = 'Operação realizada com sucesso...'
              if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saida do getUserAccount - Conta Selecionada:', result.data) }
            }
            else {
              result.sucess = false
              result.data = null
              result.msg = 'Operador não cadastrado na equipe...'
            }
          }
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saindo Conta selecionada:', _conta, user)
        }
        else {
          // Trata Operador com mais de uma conta vinculada
          // Retorna para interface para seleção de conta
          result.sucess = false
          result.data = user[0].conta
          result.msg = 'cta' // Identificador de mais de uma conta vinculada
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuário com MAIS DE 1 CONTA vinculada.', user[0].conta) }
        }

      }

      //Usuario sem conta vinculada
      if (user[0].conta.length === 0 || user[0].conta === undefined) {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuário SEM CONTA vinculada') }
        // Promove o usuário para Administrador
        await setUserToAdmn(user[0])
        // Cadastra Conta de Trabalho para o usuário
        await setContaTrabalho(user[0])
        account = setAccount(
          user[0].id,
          user[0],
          plans.planAdm.rule.permissions,
          [plans.planAdm.rule.title],
          user[0].id,
          _user.accessToken,
          _user.emailVerified,
          user[0].hasinvite,
          user[0].email,
          user[0].conta,
          user[0].credito,
          user[0].hascrm,
          user[0].telefone,
          user[0].tokenmsg,
          user[0].crm
        )

        result.sucess = true
        result.data = account
        result.msg = 'Operação realizada com sucesso...'
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saida do getUserAccount:', result.data) }

      }
      return result
    } else {
      return result
    }
  }
}

function setAccount(id, usuario, permissions, rules, conta, token, emailVerified, hasinvite, equipe, contas, credito, hascrm, telefone, tokenmsg, crm) {
  const account = new accountModel(
    id,
    usuario.email,
    usuario.nome,
    permissions,
    rules,
    conta,
    usuario.plano,
    usuario.photoURL || '/assets/avatars/avatar1.jpg',
    token,
    emailVerified,
    hasinvite,
    equipe,
    contas,
    credito,
    hascrm,
    telefone,
    tokenmsg,
    crm
  )
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('setAccount:', account) }
  return account
}

async function setUserToAdmn(usuario) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setUserToAdmn', usuario)
  try {
    await updateDoc(doc(db, 'usuarios', usuario.id), { conta: [usuario.id], rules: [plans.planAdm.title], permissions: plans.planAdm.rule.permissions })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setUserToAdmn Ok!', usuario)
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setUserToAdmn!', e)
  }
}

async function setContaTrabalho(usuario) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setContaTrabalho', usuario)
  const tConta = {
    email: usuario.email,
    convite: null,
    conviteval: null,
    ativo: true,
    createdat: serverTimestamp(),
    equipe: usuario.email
  }
  try {
    await setDoc(doc(db, 'contas', usuario.id), tConta)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Conta Traballho Ok!', tConta)
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setContaTrabalho!', e)
  }
}

export async function addNewUserControl(pws, usuario) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no AddNewUserControl', usuario)
  // Cadastra Usuario no Firebase
  await createUserWithEmailAndPassword(auth, usuario.email, pws)
    .then((userCredential) => {
      usuario.msg += ' - Credential Ok!'
      usuario.token = userCredential.user.accessToken
      usuario.emailVerified = userCredential.user.emailVerified
      usuario.credito = 0
    })
    .catch((error) => {
      const errorCode = error.code;
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em createUserWithEmailAndPassword', error)
      usuario.id = null
      usuario.msg = errorCode
      throw error('Credential Fail')
    });

  // Cadastra Usuario no GIMOV
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Iniciou processo de cadastramento no GIMOV', usuario)
  try {
    const userColRef = collection(db, 'usuarios').withConverter(userConverter)
    await runTransaction(db, async (transaction) => {

      // Cria usuario
      const docRef = await addDoc(userColRef, usuario)
      usuario.id = docRef.id.toString();

      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Usuario cadastrado ID:', usuario.id)

      // Usuario Novo com conta de terceiros EQUIPE

      if (usuario.conta) {

        // Atualiza id do usuario Operador
        await updateDoc(doc(db, 'usuarios', usuario.id), { id: usuario.id, conta: [usuario.conta] })

        // Cadastra Operador em Conta de Terceiro - Equipe
        await saveOperador(usuario, usuario.conta)

        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Conta Operador Consulta Ok')

      }
      else {// Usuario Administrador de Conta

        // Atualiza ID e a Conta do usuario Administrador
        await updateDoc(doc(db, 'usuarios', usuario.id), { id: usuario.id, conta: [usuario.id] })
        usuario.conta = usuario.id

        //Cria uma conta de trabalho para o usuario Administrador 
        setContaTrabalho(usuario)
      }
      usuario.msg += 'Cadastro Ok!'
    })
  } catch (e) {
    usuario.id = null
    usuario.msg += ' - Transaction failure!'
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro addNewUserControl', e)
  }
  finally {
    localStorage.setItem('token', JSON.stringify(usuario.token))
    return usuario
  }
}






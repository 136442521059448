import { LoadingButton } from '@mui/lab';
import {
  Button, Grid, FormControl,
  MenuItem, Paper, Select, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TextField,
  useTheme, IconButton, Tooltip, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Box
} from '@mui/material';
import { useState } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { removeOperatorController, saveEquipeNome, setOperadorController } from '../../../controllers/equipeController';

export function CadEquipe({ user, listUsuarios, setListUsuarios, getAllOperadores }) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [editMode, setEditMode] = useState(false);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [isLoadingNome, setIsLoadingNome] = useState(false);
  const [nomeEquipe, setNomeEquipe] = useState();
  const [altEquipe, setAltEquipe] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [pendingChanges, setPendingChanges] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [editingId, setEditingId] = useState(null);



  // Column definition with actions
  const columns = [
    { id: 'nome', label: 'Nome', minWidth: 200 },
    { id: 'email', label: 'Email', minWidth: 250 },
    { id: 'profile', label: 'Perfil', minWidth: 150 },
    { id: 'actions', label: 'Ações', minWidth: 120, align: 'center' },
  ].map(column => ({
    ...column,
    sx: {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  }));

  // Updated profile change handler
  const handleProfileChange = (userId, newProfile) => {
    setPendingChanges(prev => ({
      ...prev,
      [userId]: newProfile
    }));
  };

  // Update the handleSaveChanges function
  const handleSaveChanges = async (userId) => {
    setIsSaving(true);
    try {
      const userToUpdate = listUsuarios.find(u => u.id === userId);
      if (!userToUpdate || !pendingChanges[userId]) return;

      const updatedUser = {
        ...userToUpdate,
        rules: [pendingChanges[userId]]
      };

      await setOperadorController(updatedUser, user.conta);
      await getAllOperadores();

      // Clear pending changes for this user and exit edit mode
      setPendingChanges(prev => {
        const newChanges = { ...prev };
        delete newChanges[userId];
        return newChanges;
      });
      setEditingId(null);
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
    } finally {
      setIsSaving(false);
    }
  };

  async function gravarNomeEquipe() {
    setIsLoadingNome(true);
    const result = await saveEquipeNome(user.conta, nomeEquipe);
    if (result.sucess) {
      user.equipe = nomeEquipe;
      setAltEquipe(false);
    }
    setIsLoadingNome(false);
  }

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (userToDelete) {
      await removeOperatorController(user.conta, userToDelete.idop, userToDelete.id);
      await getAllOperadores();
      setDeleteDialogOpen(false);
      setUserToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleEditClick = (userId) => {
    setEditingId(editingId === userId ? null : userId);
  };

  const isMobile = window.innerWidth < 768;

  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      {/* Team Name Section */}
      <Grid container spacing={2} sx={{ mb: 3 }} alignItems="center">
        <Grid item xs={9}>
          <TextField
            fullWidth
            label="Nome da equipe"
            variant="outlined"
            defaultValue={user?.equipe}
            onChange={(e) => { setNomeEquipe(e.target.value); setAltEquipe(true) }}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            fullWidth
            variant="contained"
            loading={isLoadingNome}
            onClick={gravarNomeEquipe}
            startIcon={<SaveTwoToneIcon />}
            disabled={!altEquipe}
            size='small'
            sx={{ height: '40px', width: 'auto' }}
          >
            Salvar
          </LoadingButton>
        </Grid>
      </Grid>

      {/* Team Members Table */}
      <TableContainer sx={{ mb: 2, height: 300 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    ...column.sx,
                    display: column.id === 'email' && isMobile ? 'none' : 'table-cell'
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listUsuarios
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>{user.nome}</TableCell>
                  <TableCell
                    sx={{
                      ...user.sx,
                      display: isMobile ? 'none' : 'table-cell'
                    }}

                  >{user.email}</TableCell>
                  <TableCell sx={{ display: editingId === user.id ? 'none' : 'table-cell' }}>
                    {user.rules[0]}
                  </TableCell>
                  <TableCell sx={{ display: editingId === user.id ? 'table-cell' : 'none' }}>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={pendingChanges[user.id] || user.rules[0] || ''}
                        onChange={(e) => handleProfileChange(user.id, e.target.value)}
                        sx={{ height: '32px' }}
                      >
                        <MenuItem value="cadastro">Cadastro</MenuItem>
                        <MenuItem value="consulta">Consulta</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    {editingId === user.id ? (
                      <>
                        <Tooltip title="Salvar">
                          <IconButton
                            size="small"
                            onClick={() => handleSaveChanges(user.id)}
                            color="success"
                            sx={{ mr: 1 }}
                          >
                            <SaveTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancelar">
                          <IconButton
                            size="small"
                            onClick={() => handleEditClick(user.id)}
                            color="default"
                            sx={{ mr: 1 }}
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip title="Editar">
                        <IconButton
                          size="small"
                          onClick={() => handleEditClick(user.id)}
                          color="default"
                          sx={{ mr: 1 }}
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Excluir">
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteClick(user)}
                        color="error"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {Object.keys(pendingChanges).length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <LoadingButton
            variant="contained"
            loading={isSaving}
            onClick={handleSaveChanges}
            startIcon={<SaveTwoToneIcon />}
            sx={{ textTransform: 'none' }}
          >
            Salvar Alterações
          </LoadingButton>
        </Box>
      )} */}

      <TablePagination
        component="div"
        count={listUsuarios.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{
          color: theme.palette.error.main,
          fontWeight: 600
        }}>
          Confirmar Exclusão
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir o usuário{' '}
            <strong>{userToDelete?.nome}</strong> da equipe?
            Esta ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            sx={{
              textTransform: 'none',
              borderColor: theme.palette.grey[300],
              color: theme.palette.text.primary
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            sx={{ textTransform: 'none' }}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
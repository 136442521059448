import {
  Box, Stack, Typography, IconButton, Button,
  Paper, Divider, Grid, CircularProgress, Chip,
  useTheme, useMediaQuery, Dialog, DialogTitle,
  DialogContent, DialogActions, MenuItem, TextField,
  FormControl, InputLabel, Select, Avatar, Card, CardContent,
  Badge, Tooltip, Container
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SourceIcon from '@mui/icons-material/Source';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { green, blue, grey, red, orange, purple } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { leadStatus, leadDetailOptions, leadEncerramentoMotivo } from '../../../constants/crm';
import { useState } from 'react';
import LeadTaskDetails from './LeadTaskDetails';
import ClinteDetalhe from '../Clientes/ClienteDetalhe';
import { LeadTaskSelect } from './LeadTaskSelect';
import { WhastForm } from '../Whatsapp/WhatsForm';
import dayjs from 'dayjs';
import { encerrarLead } from '../../../controllers/crm/leadController';
import { handleSendWebWhatsapp } from '../../../utils/crm/openWhatsApp';

dayjs.locale('pt-br');

export default function LeadDetails({
  account, lead, setShowMyLeads, setShowLeadDetails,
  setShowOption, setOption, setSnackbar, setUpdate, update
}) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [filtro, setFiltro] = useState(null)
  const [loading, setLoading] = useState(false)

  const [showTasklList, setShowTaskList] = useState(true)
  const [taskList, setTaskList] = useState([])
  const [taskListFiltered, setTaskListFiltered] = useState([])

  const [task, setTask] = useState({})
  const [showTask, setShowTask] = useState(false)
  const [showClient, setShowClient] = useState(false)
  const [showWhatsapp, setShowWhatsapp] = useState(false)
  const [showInfLead, setShowInfLead] = useState(true)
  const [showEncerrarLead, setShowEncerrarLead] = useState(false)
  const [motivoEncerramento, setMotivoEncerramento] = useState('');
  const [observacaoEncerramento, setObservacaoEncerramento] = useState('');
  const [loadingEncerrar, setLoadingEncerrar] = useState(false);

  // Função para obter cor baseada na etapa do lead
  const getEtapaColor = () => {
    if (!lead?.etapa?.nome) return theme.palette.primary.main;
    
    const etapaNome = lead.etapa.nome.toLowerCase();
    if (etapaNome.includes('novo')) return blue[600];
    if (etapaNome.includes('contato')) return orange[600];
    if (etapaNome.includes('negociaç')) return green[600];
    if (etapaNome.includes('proposta')) return purple[600];
    return theme.palette.primary.light;
  };

  // Componente para mostrar informação com ícone
  const InfoItem = ({ label, value, icon, action }) => (
    <Box sx={{ 
      p: 1.5, 
      borderRadius: 1, 
      bgcolor: grey[50],
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5
    }}>
      <Typography 
        variant="caption" 
        color="text.secondary"
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          fontWeight: 'medium'
        }}
      >
        {icon}
        {label}
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        alignItems: action ? 'center' : 'flex-start',
        justifyContent: 'space-between'
      }}>
        <Typography 
          variant="body2" 
          fontWeight="medium" 
          sx={{ 
            wordBreak: 'break-word',
            flex: 1
          }}
        >
          {value}
        </Typography>
        {action}
      </Box>
    </Box>
  );

  function handleClose() {
    setShowLeadDetails(false)
    setShowMyLeads(true)
  }

  function handleOption(option) {
    setOption(option)
    setShowTaskList(false)
    setShowClient(false)
    setShowWhatsapp(false)
    setShowTaskList(false)

    if (option === leadDetailOptions.Task) {
      setShowTask(true)
    }
    if (option === leadDetailOptions.Whatsapp) {
      setShowWhatsapp(true)
    }
    if (option === leadDetailOptions.Identification) {
      setShowClient(true)
      setShowInfLead(false)
    }
    if (option === leadDetailOptions.Gimo) {
      // setShowGimo(true)
    }
    if (option === leadDetailOptions.TaskList) {
      setShowTaskList(true)
      setShowInfLead(true)
    }
  }

  const handleOpenEncerrarLead = () => {
    setShowEncerrarLead(true);
  };

  const handleCloseEncerrarLead = () => {
    setShowEncerrarLead(false);
    setMotivoEncerramento('');
    setObservacaoEncerramento('');
  };

  const handleEncerrarLead = async () => {
    if (!motivoEncerramento) {
      setSnackbar({ open: true, message: 'Selecione um motivo de encerramento', severity: 'error' });
      return;
    }

    setLoadingEncerrar(true);
    try {
      const response = await encerrarLead({
        idlead: lead.idlead,
        motivo: motivoEncerramento,
        observacao: observacaoEncerramento,
        idusuario: account.id
      });

      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Lead encerrado com sucesso', severity: 'success' });
        setShowEncerrarLead(false);
        setShowLeadDetails(false);
        setShowMyLeads(true);
        setUpdate(!update);
      } else {
        setSnackbar({ open: true, message: 'Erro ao encerrar o lead', severity: 'error' });
        setLoadingEncerrar(false);
      }
    } catch (error) {
      console.error('Erro ao encerrar lead:', error);
      setSnackbar({ open: true, message: 'Erro ao encerrar o lead', severity: 'error' });
      setLoadingEncerrar(false);
    }
  };

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        p: { xs: 1, sm: 2 }, 
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      
    >
      {loading && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      <Grid 
        container 
        spacing={3} 
        sx={{ 
          flexGrow: 1,
          alignItems: 'center', // Centraliza verticalmente
          minHeight: 'calc(100vh - 200px)' // Altura mínima para ocupar quase toda a tela
        }}
      >
        {/* Lead Info Card */}
        {showInfLead && (
          <Grid 
            item 
            xs={12} 
            md={6} 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center' 
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              style={{ 
                width: '100%',
                maxWidth: '450px', // Limita a largura máxima do card
                minWidth: '300px' // Limita a largura mínima do card
              }}
            >
              <Card 
                elevation={3} 
                sx={{ 
                  borderRadius: 2,
                  overflow: 'visible',
                  height: '100%'
                }}
              >
                {/* Card Header with Avatar */}
                <Box 
                  sx={{ 
                    position: 'relative',
                    p: 2.5, 
                    // pb: 7,
                    bgcolor: blue[50], // Cor de fundo do card
                    // color: '#fff',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {lead.nome}
                  </Typography>
                  
                  <Typography variant="body2" sx={{ opacity: 0.9, mb: 1 }}>
                    LEAD ID: {lead.idlead}
                  </Typography>
                  
                  <Chip 
                    label={lead.etapa.nome}
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      fontWeight: 'bold',
                      bgcolor: 'rgba(255,255,255,0.2)',
                      color: 'inherit',
                      '& .MuiChip-label': { px: 1.5 }
                    }}
                  />
                </Box>
                
                {/* Card Content */}
                <CardContent sx={{ pt: 4 }}>
                  <Grid container spacing={2}>
                    {/* Telefone com WhatsApp */}
                    <Grid item xs={12}>
                      <InfoItem
                        label="Telefone"
                        value={lead.telefone}
                        icon={<WhatsAppIcon fontSize="small" sx={{ color: green[600] }} />}
                        action={
                          <Tooltip title="Enviar WhatsApp">
                            <IconButton
                              onClick={() => handleSendWebWhatsapp(lead.telefone, account.nome, '')}
                              size="small"
                              sx={{
                                bgcolor: green[500],
                                '&:hover': { bgcolor: green[700] },
                                width: 32,
                                height: 32
                              }}
                            >
                              <WhatsAppIcon sx={{ color: 'white', fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </Grid>
                    
                    {/* Email */}
                    {lead.email && (
                      <Grid item xs={12}>
                        <InfoItem
                          label="E-mail"
                          value={lead.email}
                          icon={<EmailIcon fontSize="small" sx={{ color: blue[600] }} />}
                        />
                      </Grid>
                    )}
                    
                    {/* Origem */}
                    <Grid item xs={12}>
                      <InfoItem
                        label="Canal de Origem"
                        value={lead.origem}
                        icon={<SourceIcon fontSize="small" sx={{ color: orange[600] }} />}
                      />
                    </Grid>
                    
                    {/* Interesse */}
                    <Grid item xs={12}>
                      <Box sx={{ 
                        p: 1.5, 
                        borderRadius: 1, 
                        bgcolor: blue[50],
                        border: `1px solid ${blue[100]}`
                      }}>
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            fontWeight: 'medium',
                            mb: 1
                          }}
                        >
                          <InfoIcon fontSize="small" sx={{ color: blue[600] }} />
                          Interesse
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                          }}
                        >
                          {lead.interesse || "Sem informações de interesse"}
                        </Typography>
                      </Box>
                    </Grid>
                    
                    {/* Datas */}
                    <Grid item xs={12} sm={6}>
                      <InfoItem
                        label="Criado em"
                        value={dayjs(lead.createdat).format('DD/MM/YYYY HH:mm')}
                        icon={<ScheduleIcon fontSize="small" sx={{ color: grey[600] }} />}
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <InfoItem
                        label="Atualizado em"
                        value={dayjs(lead.updatedat).format('DD/MM/YYYY HH:mm')}
                        icon={<AccessTimeIcon fontSize="small" sx={{ color: grey[600] }} />}
                      />
                    </Grid>
                  </Grid>
                  
                  {/* Botões de Ações */}
                  <Stack 
                    direction={{ xs: 'column', sm: 'row' }} 
                    spacing={2} 
                    justifyContent="space-between"
                    sx={{ mt: 3 }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleOption(leadDetailOptions.Identification)}
                      disabled={showClient}
                      startIcon={<PersonIcon />}
                      size={isMobile ? "medium" : "small"}
                      fullWidth={isMobile}
                    >
                      Cliente
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleOpenEncerrarLead}
                      startIcon={<CloseIcon />}
                      size={isMobile ? "medium" : "small"}
                      fullWidth={isMobile}
                    >
                      Encerrar Lead
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      size={isMobile ? "medium" : "small"}
                      fullWidth={isMobile}
                    >
                      Fechar
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        )}

        {/* Right Side Content */}
        <Grid 
          item 
          xs={12} 
          md={showInfLead ? 6 : 12}
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            height: '100%'
          }}
        >
          <Box sx={{
            // height: '100%',
            width: '100%',
            display: 'flex', 
            flexDirection: 'column',
          }}>
            {showClient && (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                style={{ height: '100%' }}
              >
                <ClinteDetalhe
                  account={account}
                  lead={lead}
                  showClient={showClient}
                  setShowClient={setShowClient}
                  setShowTaskList={setShowTaskList}
                  setSnackbar={setSnackbar}
                  setShowInfLead={setShowInfLead}
                  setShowMyLeads={setShowMyLeads}
                  setShowLeadDetails={setShowLeadDetails}
                  setUpdate={setUpdate}
                />
              </motion.div>
            )}

            {showTasklList && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                style={{ height: '100%' }}
              >
                <Card elevation={3} sx={{ borderRadius: 2, height: '100%' }}>
                  <CardContent sx={{ height: '100%', p: { xs: 2, md: 3 } }}>
                    <LeadTaskSelect
                      account={account}
                      lead={lead}
                      setSnackbar={setSnackbar}
                      setLoading={setLoading}
                      setUpdate={setUpdate}
                      update={update}
                      setShowMyLeads={setShowMyLeads}
                      setShowLeadDetails={setShowLeadDetails}
                    />
                  </CardContent>
                </Card>
              </motion.div>
            )}

            {showTask && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                style={{ height: '100%' }}
              >
                <Card elevation={3} sx={{ borderRadius: 2, height: '100%' }}>
                  <CardContent sx={{ height: '100%', p: { xs: 2, md: 3 } }}>
                    <LeadTaskDetails
                      account={account}
                      lead={lead}
                      task={task}
                      setTask={setTask}
                      taskList={taskList}
                      taskListFiltered={taskListFiltered}
                      filtro={filtro}
                      showTask={showTask}
                      setShowTask={setShowTask}
                      setSnackbar={setSnackbar}
                      setUpdate={setUpdate}
                      update={update}
                    />
                  </CardContent>
                </Card>
              </motion.div>
            )}

            {/* {showWhatsapp && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                style={{ height: '100%' }}
              >
                <Card elevation={3} sx={{ borderRadius: 2, height: '100%' }}>
                  <CardContent sx={{ height: '100%', p: { xs: 2, md: 3 } }}>
                    <WhastForm
                      account={account}
                      lead={lead}
                      setSnackbar={setSnackbar}
                      setLoading={setLoading}
                      setShowWhatsapp={setShowWhatsapp}
                      showWhatsapp={showWhatsapp}
                      setShowTaskList={setShowTaskList}
                    />
                  </CardContent>
                </Card>
              </motion.div>
            )} */}
          </Box>
        </Grid>
      </Grid>

      {/* Dialog para Encerrar o Lead */}
      <Dialog
        open={showEncerrarLead}
        onClose={handleCloseEncerrarLead}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 5,
          sx: { borderRadius: 2 }
        }}
        TransitionProps={{
          component: motion.div,
          variants: {
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 }
          },
          initial: "hidden",
          animate: "visible"
        }}
      >
        <DialogTitle sx={{
          bgcolor: red[600],
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1.5
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <CloseIcon />
            <Typography
              sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}
              fontWeight="bold">
              Encerrar Lead - {lead?.nome}
            </Typography>
          </Box>
          <IconButton
            onClick={handleCloseEncerrarLead}
            size="small"
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ px: 3, py: 3 }}>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth variant="outlined" size="small" >
              <InputLabel id="motivo-encerramento-label">Motivo do Encerramento</InputLabel>
              <Select
                labelId="motivo-encerramento-label"
                id="motivo-encerramento"
                value={motivoEncerramento}
                label="Motivo do Encerramento"
                onChange={(e) => setMotivoEncerramento(e.target.value)}
                sx={{ minHeight: '40px' }}
              >
                <MenuItem value={leadEncerramentoMotivo.Vendido}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon fontSize="small" sx={{ color: green[600] }} />
                    Venda Realizada
                  </Box>
                </MenuItem>
                <MenuItem value={leadEncerramentoMotivo.Abandono}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CloseIcon fontSize="small" sx={{ color: red[600] }} />
                    Cliente Abandonou Negociação
                  </Box>
                </MenuItem>
                <MenuItem value={leadEncerramentoMotivo.Desistencia}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <InfoIcon fontSize="small" sx={{ color: orange[600] }} />
                    Cliente Desistiu
                  </Box>
                </MenuItem>
                <MenuItem value={leadEncerramentoMotivo.Outro}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MoreHorizIcon fontSize="small" sx={{ color: blue[600] }} />
                    Outro Motivo
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Observação"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              placeholder="Adicione informações relevantes sobre o encerramento deste lead"
              value={observacaoEncerramento}
              onChange={(e) => setObservacaoEncerramento(e.target.value)}
              InputProps={{
                sx: {
                  borderRadius: 1,
                  bgcolor: grey[50]
                }
              }}
            />

            <Paper
              variant="outlined"
              sx={{
                p: 2,
                borderColor: orange[200],
                borderRadius: 1,
                bgcolor: orange[50]
              }}
            >
              <Box sx={{ display: 'flex', gap: 1.5 }}>
                <WarningIcon sx={{ color: orange[700], fontSize: 20, mt: 0.2 }} />
                <Typography variant="body2" color={orange[900]}>
                  <strong>Atenção:</strong> Ao encerrar este lead, ele será movido para a lista de leads encerrados
                  e não ficará mais disponível na sua lista de leads ativos. Esta ação não pode ser desfeita.
                </Typography>
              </Box>
            </Paper>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ px: 3, py: 2.5, bgcolor: grey[50], borderTop: `1px solid ${grey[200]}` }}>
          <Button
            onClick={handleCloseEncerrarLead}
            variant="outlined"
            disabled={loadingEncerrar}
            size='small'
            sx={{ px: 3 }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleEncerrarLead}
            variant="contained"
            color="error"
            disabled={!motivoEncerramento || loadingEncerrar}
            startIcon={loadingEncerrar ? <CircularProgress size={20} color="inherit" /> : null}
            size='small'
            sx={{ px: 3 }}
          >
            {loadingEncerrar ? "Encerrando..." : "Confirmar Encerramento"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

import {
  Box, Stack, Typography, TextField, IconButton, Paper,
  useTheme, useMediaQuery
} from '@mui/material';
import { PhotoCamera, Delete, CloudUpload } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { loadURlImgEmpreendimentos } from '../../../api/storage'
import { isUrl } from '../../../utils/validURL'

export const Nuvem = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace, setFileImg, fileImg
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [nuvem, setNuvem] = useState({ ...placeEdit });

  let _place = placeEdit
  const [urlimagem, setUrlImagem] = useState(_place.hasOwnProperty('urlimagem') ? _place.urlimagem : newPlace.urlimagem)
  const [drive, setDrive] = useState(_place.hasOwnProperty('drive') ? _place.drive : newPlace.drive)
  const [tbvendas, setTbVendas] = useState(_place.hasOwnProperty('tbvendas') ? _place.tbvendas : newPlace.tbvendas)
  const [plantas, setPlantas] = useState(_place.hasOwnProperty('plantas') ? _place.plantas : newPlace.plantas)
  const [memorial, setMemorial] = useState(_place.hasOwnProperty('memorial') ? _place.memorial : newPlace.memorial)
  const [implantacao, setImplantacao] = useState(_place.hasOwnProperty('implantacao') ? _place.implantacao : newPlace.implantacao)
  const [divulgacao, setDivulgacao] = useState(_place.hasOwnProperty('divulgacao') ? _place.divulgacao : newPlace.divulgacao)
  const [selectedImage, setSelectedImage] = useState(_place.hasOwnProperty('file') ? _place.file : null)

  function handleInput(event) {

    if (event.target.id === 'urlimagem') {
      console.log('Edit url')
      setUrlImagem(event.target.value)
    }
    if (event.target.id === 'drive') { setDrive(event.target.value) }
    if (event.target.id === 'tbvendas') { setTbVendas(event.target.value) }
    if (event.target.id === 'plantas') { setPlantas(event.target.value) }
    if (event.target.id === 'memorial') { setMemorial(event.target.value) }
    if (event.target.id === 'implantacao') { setImplantacao(event.target.value) }
    if (event.target.id === 'divulgacao') { setDivulgacao(event.target.value) }
  }

  function setPlace() {
    _place.urlimagem = urlimagem ? urlimagem : ''
    _place.drive = drive ? drive : ''
    _place.tbvendas = tbvendas ? tbvendas : ''
    _place.plantas = plantas ? plantas : ''
    _place.memorial = memorial ? memorial : ''
    _place.implantacao = implantacao ? implantacao : ''
    _place.divulgacao = divulgacao ? divulgacao : ''
  }

  const style = {
    my: '5px',
    mr: '10px'
  }


  async function delImgEmpreeendimento() {
    const urlImg = document.getElementById('urlimagem')
    urlImg.value = ''
    const img = document.getElementById('imgEmp')
    if (img) img.setAttribute('src', 'assets/images/gimov.png')
    setNuvem({ ...nuvem, urlimagem: '' })
  }

  useEffect(() => {
    setPlaceEdit(nuvem)
    // console.log('Nuvem', nuvem)
  }, [nuvem])

  async function loadImgEmpreendimento() {
    // console.log('loadImgEmpreendimento', nuvem.urlimagem)
    let _url = ''
    if (nuvem.urlimagem) {
      if (!isUrl(nuvem.urlimagem)) {
        console.log('Não É URL', nuvem.urlimagem)
        _url = URL.createObjectURL(nuvem.file)
      }
      else {
        // console.log('É URL', nuvem.urlimagem)
        if (nuvem.urlimagem.includes('firebasestorage.googleapis.com')) _url = await loadURlImgEmpreendimentos(newPlace.id)
        else _url = nuvem.urlimagem
      }
      setNuvem({ ...nuvem, urlimagem: _url })
      if (_url) {
        const img = document.getElementById('imgEmp')
        if (img) img.setAttribute('src', _url)
      }
    }
  }

  function handleLoadImage(event) {
    var file = event.target.files[0];
    const url = URL.createObjectURL(file)
    const img = document.getElementById('imgEmp')
    img.setAttribute('src', url)
    setNuvem({ ...nuvem, file: file, urlimagem: file.name })
  }

  useEffect(() => {
    loadImgEmpreendimento()
  }, [nuvem.urlimagem])

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{
        width: '100%',
        p: 0
      }}
    >
      <Stack spacing={3}>
        {/* Image Upload Section */}
        <Paper
          elevation={2}
          sx={{
            p: 2,
            borderRadius: 2,
            bgcolor: 'background.paper'
          }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
          >
            {/* Form Fields */}
            <Stack spacing={2} sx={{ flex: 1 }}>
              <Typography variant="h6" color="primary" fontWeight="medium">
                Imagem e Links Principais
              </Typography>

              <TextField
                id='urlimagem'
                fullWidth
                variant="outlined"
                size="small"
                label="URL da Foto do empreendimento"
                value={nuvem.urlimagem || ''}
                onChange={(e) => setNuvem({ ...nuvem, urlimagem: e.target.value })}
                helperText="Link da foto do empreendimento do site da construtora"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Drive"
                value={nuvem.drive || ''}
                onChange={(e) => setNuvem({ ...nuvem, drive: e.target.value })}
                helperText="Link do Drive da construtora"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Tabela de Vendas"
                value={nuvem.tbvendas || ''}
                onChange={(e) => setNuvem({ ...nuvem, tbvendas: e.target.value })}
                helperText="Pasta TABELA DE VENDAS do drive da construtora"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </Stack>

            {/* Image Preview */}
            <Paper
              elevation={1}
              sx={{
                width: { xs: '100%', md: '300px' },
                p: 1,
                borderRadius: 2,
                border: '2px dashed',
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AnimatePresence>
                {nuvem.urlimagem ? (
                  <motion.img
                    key="preview"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    id="imgEmp"
                    alt="Preview"
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                      borderRadius: '8px'
                    }}
                  />
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <CloudUpload sx={{ fontSize: 64, color: 'text.secondary' }} />
                  </motion.div>
                )}
              </AnimatePresence>

              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <IconButton
                  color="error"
                  onClick={delImgEmpreeendimento}
                  size="small"
                >
                  <Delete />
                </IconButton>
                <IconButton
                  color="primary"
                  component="label"
                  size="small"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleLoadImage}
                  />
                  <PhotoCamera />
                </IconButton>
              </Stack>
            </Paper>
          </Stack>
        </Paper>

        {/* Additional Links Section */}
        <Paper
          elevation={2}
          sx={{
            p: 2,
            borderRadius: 2,
            bgcolor: 'background.paper'
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" color="primary" fontWeight="medium">
              Links Adicionais
            </Typography>

            {[
              { id: 'plantas', label: 'Plantas', helper: 'Pasta PLANTAS do drive da construtora' },
              { id: 'memorial', label: 'Memorial Descritivo', helper: 'Pasta MEMORIAL DESCRITIVO do drive da construtora' },
              { id: 'implantacao', label: 'Implantação', helper: 'Pasta IMPLANTAÇÃO do drive da construtora' },
              { id: 'divulgacao', label: 'Divulgação', helper: 'Pasta DIVULGAÇÃO do drive da construtora' }
            ].map(field => (
              <TextField
                key={field.id}
                fullWidth
                variant="outlined"
                size="small"
                label={field.label}
                value={nuvem[field.id] || ''}
                onChange={(e) => setNuvem({ ...nuvem, [field.id]: e.target.value })}
                helperText={field.helper}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            ))}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
};
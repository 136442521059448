import { Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { estatistica } from '../../../controllers/crm/leadController';
import { useEffect, useState } from 'react';
// import { diffDate } from '../../../utils/crm/getDiffDate';
// import { getColorFaseByOrdem } from '../../../utils/crm/getColors';
import LeadsCharts from './LeadsCharts';
import dayjs from 'dayjs';
import { orange } from '@mui/material/colors';



export default function LeadsDashboard({ leadsAll, setLeadsAll, setUpdate,
  setAtivarTmp, loadAllLeads }) {

  // Constante de Estado

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (leadsAll) {
      setDados(estatistica(leadsAll))
    }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Leads All', leadsAll)
    setDisabled(false)
  }, [leadsAll])

  const [dados, setDados] = useState(null)

  return (
    <Container maxWidth="xl">
      {/* Main Panel */}
      <Grid container spacing={2} sx={{
        height: 'calc(100vh - 100px)',
      }}>
        {/* Status Panel */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              {/* Loading Indicator */}
              {disabled && (
                <CircularProgress sx={{ position: 'fixed', top: '50%', left: '50%' }} />
              )}
              {/* Dashboard Section */}
              <Grid item xs={12}>
                <Box bgcolor={orange[500]} alignItems={'center'} px={2} py={1} sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: 2 }}>
                  <Typography variant="h4">
                    Dashboard de Leads  - Atualizado às {dayjs().format('HH:mm:ss')}
                  </Typography>
                  <Button variant='contained' size='small' onClick={() => { setDisabled(true); loadAllLeads() }}>Atualizar</Button>
                </Box>
              </Grid>
              <LeadsCharts dados={dados} leadsAll={leadsAll} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
import {
  Box, Stack, Typography, IconButton, Button,
  Paper, Chip, Grid, CircularProgress,
  useTheme, useMediaQuery, Tabs, Tab,
  Fab
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { taskStatus, taskType } from '../../../constants/crm';
import { getLeadTasks } from '../../../controllers/crm/leadTaskController';
import LeadTaskDetails from './LeadTaskDetails';
import dayjs from 'dayjs';
import { green, red, yellow } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';

export function LeadTaskSelect({
  account,
  lead,
  setSnackbar,
  setLoading,
  setUpdate,
  update,
  setShowMenu,
  setShowMyLeads,
  setShowLeadDetails
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [taskList, setTaskList] = useState([]);
  const [taskListFiltered, setTaskListFiltered] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [typeStatus, setTypeStatus] = useState(taskStatus.Pendente);
  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [taskDetail, setTaskDetail] = useState({});

  function handleClose() {
    setShowLeadDetails(false)
  }

  function filterTasks() {
    if (!filtro) {
      setTaskListFiltered(taskList.filter(task => task.situacao === taskStatus.Description["co"]))
    }
    else {
      setTaskListFiltered(taskList.filter(
        (task) => {
          if (task.tipo.includes(taskType.Description[filtro])
            && task.situacao === taskStatus.Description[typeStatus])
            return task
          else return null
        }
      ))
    }
  }

  async function loadTasks() {
    setLoading(true)
    try {
      const query = {
        where: {
          idlead: lead.idlead,
          idoperador: account.id,
          isactive: true,
        },
        orderBy: [
          { dtacao: 'asc' }
        ]
      };
      const dados = await getLeadTasks(query)
      if (dados.status === 200) {
        setTaskList(dados.data)
        setFiltro(null)
      }
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' })
    }
    finally {
      setLoading(false)
    }
  }

  function handleShowTask(task) {
    setShowTaskDetail(true)
    setTaskDetail(task)
  }

  useEffect(() => {
    loadTasks()
  }, [])

  useEffect(() => {
    filterTasks()
  }, [filtro, typeStatus])

  const getStatusColor = (task) => {
    if (dayjs().isBefore(dayjs(task.dtacao).add(1, 'day'), 'day')) {
      return green[300];
    }
    switch (taskStatus[task.situacao]) {
      case taskStatus.Concluida:
        return green[300];
      case taskStatus.Suspensa:
        return yellow[200];
      default:
        return red[100];
    }
  };

  return (
    <Paper
      elevation={2}
      sx={{
        // width: '100%',
        minHeight: `calc(100vh - 280px)`,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.paper',
        borderRadius: 2
      }}
    >
      {/* Header */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="primary">
          Atividades
        </Typography>
      </Stack>
      {/* Updated Task List with custom scrollbar */}
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 2,
          maxHeight: 'calc(100vh - 350px)', // Adjust this value based on your header height
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.2)',
            }
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          }
        }}
      >
        <Stack
          spacing={1}
          sx={{
            minHeight: 'min-content',
            pb: 2 // Add padding bottom to ensure last item is visible
          }}
        >
          <AnimatePresence>
            {taskListFiltered.map((task, index) => (
              <motion.div
                key={task.id || index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
              >
                <Paper
                  key={task.id || index}
                  elevation={1}
                  onClick={() => handleShowTask(task)}
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 3
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1" fontWeight="medium">
                        {task.titulo}
                      </Typography>
                      <Chip
                        label={dayjs(task.dtacao).format('DD/MM')}
                        size="small"
                        sx={{
                          bgcolor: getStatusColor(task),
                          fontWeight: 'medium'
                        }}
                      />
                    </Stack>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }}
                    >
                      {task.descricao}
                    </Typography>
                  </Stack>
                </Paper>
              </motion.div>
            ))}
          </AnimatePresence>
        </Stack>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleShowTask({})}
          sx={{
            transform: 'translateX(-50%)', // Adjust for exact center
            position: 'fixed',
            right: isMobile ? '16px' : '32px',
            bottom: isMobile ? '16px' : '32px',
            zIndex: 1000
          }}
        >
          <AddIcon />
        </Fab>
      </Box>

      {/* Task Details Dialog */}
      {showTaskDetail && (
        <LeadTaskDetails
          account={account}
          lead={lead}
          task={taskDetail}
          taskList={taskList}
          taskListFiltered={taskListFiltered}
          filtro={filtro}
          filterTasks={filterTasks}
          setShowTask={setShowTaskDetail}
          showTask={showTaskDetail}
          setSnackbar={setSnackbar}
          setUpdate={setUpdate}
          update={update}
        />
      )}
    </Paper>
  );
}

export const formatPhoneNumber = (value, formatar) => {
  // Remove all non-digits
  let numbers = value.replace(/\D/g, '');
  if (!formatar) return numbers;

  // Format as (99) 99999-9999 or (99) 9999-9999
  if (numbers.length <= 10) {
    return numbers.replace(/(\d{2})(\d{0,4})(\d{0,4})/, (_, p1, p2, p3) => {
      let result = '';
      if (p1) result += `(${p1}`;
      if (p2) result += `) ${p2}`;
      if (p3) result += `-${p3}`;
      return result;
    });
  } else {
    return numbers.replace(/(\d{2})(\d{0,5})(\d{0,4})/, (_, p1, p2, p3) => {
      let result = '';
      if (p1) result += `(${p1}`;
      if (p2) result += `) ${p2}`;
      if (p3) result += `-${p3}`;
      return result;
    });
  }
};
import React, { useEffect, useState } from 'react';
import {
  Box, Stack, Typography, Button, IconButton,
  Grid, CircularProgress, Paper, Fab,
  useTheme, useMediaQuery, Alert, Snackbar
} from '@mui/material';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
// import { motion } from 'framer-motion';
import { getEscala } from '../../../controllers/crm/escalaController';
import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import { CorretorIncluir } from './CorretorIncluir';

import { getEscalaSituacao } from '../../../utils/crm/getEscalaSituacao';
import { getEscalaCor } from '../../../utils/crm/getCorEscala';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
import { ListarParticipantes } from './ListarParticipantes';
import { green } from '@mui/material/colors';
dayjs.locale('pt-br');


export default function ParticiparEscala({
  account,
  setShowMenu,
  setShowParticiparEscala,
  showParticiparEscala,
  setUpdate,
  update,
  setSnackbar,
  snackbar,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Constantes Recebidas
  // const [account, setAccount] = useState(props.account)

  const idconta = account.conta
  const idusuario = account.id
  const nome = account.nome
  // console.log('nome', nome)


  // Constantes de Estado
  // const [operadores, setOperadores] = useState([])
  const [escalas, setEscalas] = useState([])
  const [filteredEscalas, setFilteredEscalas] = useState([])
  const [escala, setEscala] = useState({})
  // const [open, setOpen] = useState(false)
  // const [snack, setSnack] = useState(false)
  // const [snackMessage, setSnackMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [newDay, setNewDay] = useState(dayjs(new Date()));
  // const [openDialogAdd, setOpenDialogAdd] = useState(false);
  // const [openCorretores, setOpenCorretores] = useState(false);
  // const [openDialogExcluir, setOpenDialogExcluir] = useState(false);
  // const [openDialogEditar, setOpenDialogEditar] = useState(false);
  // const [openCorretorEscala, setOpenCorretorEscala] = useState(false);
  const [openCorretorIncluir, setOpenCorretorIncluir] = useState(false);
  const [openParticipantes, setOpenParticipantes] = useState(false);

  const [hrInicial, setHrInicial] = useState(dayjs().hour(9).minute(0))
  const [hrFinal, setHrFinal] = useState(dayjs().hour(18).minute(0))


  const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()


  // Constantes do Snackbar
  // const [snackbar, setSnackbar] = React.useState(null);
  // const handleCloseSnackbar = () => setSnackbar(null);

  // Funções das Escalas

  // Listar Escalas
  async function listEscalas() {
    try {
      setLoading(true)
      const query = {
        take: 8,
        where: {
          idconta: idconta,
          isactive: true,
          diamesano: { gte: newDay.add(-1, 'day').toDate() }
        },
        orderBy: [
          { diamesano: 'asc' }
        ]
      };
      const response = await getEscala(query);
      if (response.status !== 200) {
        setSnackbar({ severity: 'error', message: 'Erro ao carregar controles!' });
        setLoading(false)
        return;
      }
      if (response.data) {
        if (response.data.length === 0) {
          setEscalas([]);
          setFilteredEscalas([]);
          setSnackbar({ severity: 'info', message: 'Nenhum controle cadastrado!' });
        }
        if (response.data.length > 0) {
          setEscalas(response.data);
          setFilteredEscalas(response.data);
        }
      }
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
  }

  // Abrir Participantes
  function handleParticipantes(_escala) {
    setEscala(_escala)
    setOpenParticipantes(true)
  }

  // Abrir Corretores
  function handleCorretores(_escala) {
    setEscala(_escala)
    // setOpenCorretorEscala(true)
    setOpenCorretorIncluir(true)

  }

  useEffect(() => {
    listEscalas()
  }, [newDay])

  // Extracted Card component for better organization
  const EscalaCard = ({ escala }) => (
    <Paper
      // component={motion.div}
      // initial={{ opacity: 0, y: 20 }}
      // animate={{ opacity: 1, y: 0 }}
      elevation={3}
      sx={{
        backgroundColor: getEscalaCor(escala.status, escala.diamesano),
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: 4
        },
        borderRadius: 2,
        overflow: 'hidden',
        width: { xs: '300px', sm: '280px' },
      }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center"
          sx={{
            borderBottom: '1px solid', borderColor: 'divider', p: 1,
            bgcolor: escala.diamesano === hoje ? green[500] : 'transparent',
            borderRadius: 2
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {getDiaSemana(escala.diamesano)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {new Date(escala.createdat).toLocaleDateString()}
          </Typography>
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant="body1">
            {`Data: ${new Date(escala.diamesano).toLocaleDateString()}`}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {`Situação: ${getEscalaSituacao(escala.status, escala.diamesano)}`}
          </Typography>
          <Typography variant="body1">
            {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)}`}
          </Typography>
          <Typography variant="body1">
            {`Horário: ${dayjs(escala.hrinicial).format('HH:mm')} - ${dayjs(escala.hrfinal).format('HH:mm')}`}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Button
            variant="contained"
            startIcon={<GroupIcon />}
            size="small"
            onClick={() => handleParticipantes(escala)}
            disabled={disabled}
          >
            Participantes
          </Button>
          <Button
            variant="contained"
            startIcon={<GroupAddIcon />}
            size="small"
            onClick={() => handleCorretores(escala)}
            disabled={disabled}
          >
            Participar
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );

  return (
    <Box
      sx={{
        width: '95%',
        height: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        bgcolor: 'background.default',
        borderRadius: 2,
      }}
    >
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      <Stack
        spacing={2}
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          boxShadow: 1,
          position: 'sticky',
          top: 0,
          zIndex: 1000
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            color="primary"
          >
            Leads Online - Participar
          </Typography>
          <IconButton
            onClick={listEscalas}
            disabled={loading}
            color="primary"
            sx={{
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ReplayTwoToneIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Box sx={{
        flex: 1,
        bgcolor: 'grey.100',
        overflow: 'auto',
        p: 2
      }}>
        <Grid
          container
          // spacing={0}
          sx={{
            width: '100%',
            margin: 0,
            justifyContent: { xs: 'center', sm: 'flex-start' }
          }}
        >
          {filteredEscalas.map((escala) => (
            <Grid
              item
              key={escala.idescala}
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                padding: 2
              }}
            >
              <EscalaCard escala={escala} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Existing dialogs */}
      {openCorretorIncluir && (
        <CorretorIncluir
          openCorretorIncluir={openCorretorIncluir}
          setOpenCorretorIncluir={setOpenCorretorIncluir}
          escala={escala}
          listEscalas={listEscalas}
          setSnackbar={setSnackbar}
          idconta={idconta}
          idusuario={idusuario}
          hrInicial={hrInicial}
          setHrInicial={setHrInicial}
          hrFinal={hrFinal}
          setHrFinal={setHrFinal}
          nome={nome}
        />
      )}

      {openParticipantes && (
        <ListarParticipantes
          openParticipantes={openParticipantes}
          setOpenParticipantes={setOpenParticipantes}
          escala={escala}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}


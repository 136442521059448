import {
  Box, Stack, Typography, Button, CircularProgress,
  Dialog, FormControl, FormControlLabel, Radio,
  RadioGroup, Paper, Divider, useTheme
} from '@mui/material';
import { motion } from 'framer-motion';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { green, red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { carregarCliente, carregarPorWhere, salvarCarteira } from '../../../controllers/crm/carteiraController';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';
import { transferirLead } from '../../../controllers/crm/leadController';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

export default function ClienteDetalhe({
  account,
  lead,
  showClient,
  setShowClient,
  setShowTaskList,
  setSnackbar,
  setShowInfLead,
  setShowMyLeads,
  setShowLeadDetails,
  setUpdate
}) {
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState({
    idcliente: lead.idcliente ? lead.idcliente : null,
    idconta: account.conta,
    idlead: lead.idlead,
    idusuario: account.id,
    nome: lead.nome ? lead.nome : '',
    telefone: lead.telefone ? lead.telefone : '',
    email: lead.email ? lead.email : ''    
  });
  const [nome, setNome] = useState(lead.nome ? lead.nome : '');
  const [telefone, setTelefone] = useState(lead.telefone ? lead.telefone : '');
  const [email, setEmail] = useState(lead.email ? lead.email : '');
  const [nascimento, setNascimento] = useState(cliente?.dtnascimento ? dayjs(cliente.dtnascimento).format('DD/MM/YYYY').toString() : '');
  const [observacao, setObservacao] = useState(cliente?.observacao ? cliente.observacao : '');
  const [recProposta, setRecProposta] = useState(cliente?.recproposta ? cliente.recproposta : true);
  const [msgErro, setMsgErro] = useState([]);
  const [cpfCnpjExistente, setCpfCnpjExistente] = useState(false);
  const [clienteExistente, setClienteExistente] = useState({});
  const [showLoadClientExistente, setShowLoadClientExistente] = useState(false);
  const [filterExistente, setFilterExistente] = useState('');
  const [showClienteExistente, setShowClienteExistente] = useState(false);
  const [clienteVinculado, setClienteVinculado] = useState(false);
  const [showTransferirLead, setShowTransferirLead] = useState(false);

  const theme = useTheme();

  // console.log(account, lead);

  function substituirCliente() {
    const _cliente = clienteExistente;
    setCliente(_cliente);
    setNome(_cliente.nome);
    setTelefone(_cliente.telefone);
    setEmail(_cliente.email);
    setNascimento(_cliente.dtnascimento ? dayjs(_cliente.dtnascimento).format('DD/MM/YYYY').toString() : '');
    setObservacao(_cliente.observacao);
    setRecProposta(_cliente.recproposta);
    setShowLoadClientExistente(false);
  }

  async function transferir() {
    const idconta = clienteExistente.idconta;
    const idusuario = account.id;
    const idlead = lead.idlead;
    const idoperador = clienteExistente.idoperador;
    const operador = clienteExistente.operador.nome;
    const transferir = {
      idlead,
      idconta,
      idoperador,
      operador,
      idusuario
    }
    const retorno = await transferirLead(transferir);
    if (retorno.data.success) {
      setShowClienteExistente(false);
      setShowClient(false);
      setShowTaskList(false);
      setShowLeadDetails(false);
      setShowMyLeads(true);
      setUpdate(Date.now());
      setSnackbar({ open: true, message: retorno.data.message, severity: 'success' });
    } else {
      setSnackbar({ open: true, message: retorno.data.message, severity: 'error' });
    }
  }

  async function loadExistClient() {
    setLoading(true);
    // console.log('loadExistClient', filterExistente);
    // console.log('cliente', cliente);
    const filtro = filterExistente.split(' ')[0];
    let query = {
      where: {
        idconta: account.conta
      },
      include: {
        operador: {
          select: {
            nome: true,
            telefone: true
          }
        }
      }
    };

    if (filtro === 'email') {
      query.where.email = email;
    }
    if (filtro === 'telefone') {
      query.where.telefone = telefone;
    }
    setShowLoadClientExistente(true);
    const dados = await carregarPorWhere(query);
    if (dados.status === 200) {
      if (dados.data.data.length === 0) {
        setSnackbar({ open: true, message: 'Cliente não encontrado', severity: 'error' });
        setLoading(false);
        return;
      }
      // console.log('Cliente existente', dados.data.data[0]);
      dados.data.data[0].dtnascimento = dados.data.data[0].dtnascimento ? dayjs(dados.data.data[0].dtnascimento).format('DD/MM/YYYY').toString() : null;
      setClienteExistente(dados.data.data[0]);
      setShowClienteExistente(true);
      setLoading(false);
      return;
    } else {
      // console.log('Erro ao carregar cliente existente', dados);
      setSnackbar({ open: true, message: 'Erro ao carregar cliente existente', severity: 'error' });
      setLoading(false);
      return;
    }
  }

  async function loadClient() {
    setLoading(true);
    try {
      const retorno = await carregarCliente({ idcliente: lead.idcliente, idconta: account.conta });
      // console.log('loadClient', retorno);
      if (retorno.status === 200) {
        const _cliente = retorno.data.data;
        _cliente.dtnascimento = _cliente.dtnascimento ? dayjs(_cliente.dtnascimento).format('DD/MM/YYYY').toString() : null;
        setCliente(_cliente);
        setNome(_cliente.nome);
        setTelefone(formatPhoneNumber(_cliente.telefone,true));
        setEmail(_cliente.email);
        setNascimento(_cliente.dtnascimento);
        setObservacao(_cliente.observacao);
        setRecProposta(_cliente.recproposta);
      } else {
        setSnackbar({ open: true, message: 'Erro ao carregar cliente', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Erro ao carregar cliente', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setShowClient(false);
    setShowTaskList(true);
    setShowInfLead(true);
  }

  function validar(_dados) {
    msgErro.length = 0;
    if (!_dados.nome) {
      msgErro.push('Nome inválido, favor preencher');
    }
    if (!_dados.telefone) {
      msgErro.push('Telefone inválido, favor preencher');
    }
    if (_dados.dtnascimento) {
      // console.log('Data de nascimento', _dados.dtnascimento);
      if (!dayjs(_dados.dtnascimento, 'DD/MM/YYYY', true).isValid()) {
        msgErro.push('Data de nascimento inválida, favor preencher');
      } else _dados.dtnascimento = dayjs(_dados.dtnascimento, 'DD/MM/YYYY').toISOString();
    }
    if (msgErro.length > 0) {
      return false;
    } else return true;
  }

  async function handleSave() {
    setLoading(true);
    cliente.idoperador = account.id;
    if (cliente.telefone) cliente.telefone = formatPhoneNumber(cliente.telefone,false);
    if (lead) {
      // console.log('Lead', lead);
      if (!cliente.idlead) cliente.idlead = lead.idlead;
      try {
        if (!validar(cliente)) {
          let msg = '';
          msgErro.forEach((item) => {
            msg += item + ';\n';
          });
          setSnackbar({ open: true, message: msg, severity: 'error' });
          return;
        }
        // console.log('Cliente', cliente);
        const retorno = await salvarCarteira(cliente);
        if (retorno.status === 200 && retorno.data.success) {
          // console.log('Cliente salvo com sucesso', retorno);
          retorno.data.data.dtnascimento = retorno.data.data.dtnascimento ? dayjs(retorno.data.data.dtnascimento).format('DD/MM/YYYY').toString() : null;
          setCliente(retorno.data.data);
          lead.idcliente = retorno.data.data.idcliente;
          setSnackbar({ open: true, message: retorno.data.message, severity: 'success' });
        } else {
          // console.log('Erro ao salvar cliente', retorno);
          if (retorno.data.message.includes('existente')) {
            setShowLoadClientExistente(true);
            setFilterExistente(retorno.data.message);
          }
          setSnackbar({ open: true, message: retorno.data.message, severity: 'error' });
        }

      } catch (error) {
        setSnackbar({ open: true, message: 'Erro ao salvar cliente', severity: 'error' });
      } finally {
        setLoading(false);
      }
    } else {
      console.log('Lead não informado');
      setSnackbar({ open: true, message: 'Lead não informado', severity: 'error' });
    }
  }

  useEffect(() => {
    // console.log('lead.idcliente', lead.idcliente);
    if (lead.idcliente) {
      loadClient();
    }
  }, []);

  return (
    <>
      {loading && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Paper
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          elevation={3}
          sx={{
            width: { xs: '100%', sm: '90%', md: '600px' },
            maxHeight: { xs: 'calc(100vh - 200px)', md: 'calc(100vh - 280px)' },
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: theme => theme.shadows[10]
          }}
        >
          <Box
            sx={{
              p: 2,
              bgcolor: cliente?.idcliente ? green[100] : red[50],
              borderBottom: 1,
              borderColor: 'divider'
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {cliente?.idcliente ? 'CLIENTE EM CARTEIRA' : 'CLIENTE NÃO ESTÁ NA CARTEIRA'}
            </Typography>
          </Box>

          <Stack
            spacing={2}
            sx={{
              p: { xs: 2, md: 3 },
              overflow: 'auto',
              flex: 1
            }}
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextFieldMask
                fullWidth
                label="Nome"
                value={nome || ''}
                onChange={(e) => {
                  setNome(e.target.value);
                  setCliente({ ...cliente, nome: e.target.value })
                }}
                disabled={cliente?.idcliente ? true : false}
                mask={'nome'}
              />
              <TextFieldMask
                label="Telefone"
                value={telefone || ''}
                onChange={(e) => {
                  setTelefone(e.target.value);
                  setCliente({ ...cliente, telefone: e.target.value })
                }}
                disabled={cliente?.idcliente ? true : false}
                mask={'phone'}
              />
            </Stack>

            <TextFieldMask
              fullWidth
              label="Email"
              value={email || ''}
              onChange={(e) => {
                setEmail(e.target.value);
                setCliente({ ...cliente, email: e.target.value })
              }}
              disabled={cliente?.idcliente ? true : false}
              mask={'email'}
            />

            <TextFieldMask
              fullWidth
              label="Data de Nascimento"
              placeholder='dd/mm/aaaa'
              value={nascimento || ''}
              onChange={(e) => {
                setNascimento(e.target.value);
                setCliente({
                  ...cliente,
                  dtnascimento: e.target.value ? e.target.value : null
                })
              }}
              mask={'date'}
            />

            <TextFieldMask
              fullWidth
              multiline
              rows={3}
              label="Observação"
              value={observacao || ''}
              onChange={(e) => {
                setObservacao(e.target.value);
                setCliente({ ...cliente, observacao: e.target.value })
              }}
              mask={'text'}
            />

            <FormControl>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Receber novas propostas:
              </Typography>
              <RadioGroup
                row
                value={recProposta}
                onChange={(e) => {
                  setRecProposta(e.target.value);
                  setCliente({ ...cliente, recproposta: Boolean(e.target.value) })
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size='small' />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size='small' />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>

            {cliente.updatedat && (
              <Typography
                variant="caption"
                color="text.secondary"
              >
                Última atualização: {dayjs(cliente.updatedat).format('DD/MM/YYYY HH:mm')}
              </Typography>
            )}
          </Stack>

          <Divider />
          <Stack
            direction="row"
            spacing={2}
            justifyContent={'flex-end'}
            sx={{
              p: 2,
              bgcolor: 'background.paper'
            }}
          >
            {!showLoadClientExistente ? (

              <>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={loading}
                  size='small'
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  disabled={loading}
                  size='small'
                >
                  Fechar
                </Button>
              </>
            )
              : (
                <>
                  <Button
                    variant="contained"
                    onClick={loadExistClient}
                    disabled={loading}
                    size='small'
                  >
                    Ver existente
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setShowLoadClientExistente(false)}
                    disabled={loading}
                    size='small'
                  >
                    Cancelar
                  </Button>
                </>
              )}
          </Stack>
        </Paper>
      </Box>

      <Dialog
        open={showClienteExistente}
        onClose={() => setShowClienteExistente(false)}
        PaperProps={{
          sx: {
            width: { xs: '100%', md: '500px' },
            maxHeight: '80vh'
          }
        }}
      >
        <Paper sx={{ p: 0 }}>
          <Box
            sx={{
              p: 2,
              bgcolor: green[100],
              borderBottom: 1,
              borderColor: 'divider'
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              CLIENTE EXISTENTE
            </Typography>
          </Box>

          <Stack spacing={2} sx={{ p: 2 }}>
            <Stack spacing={1}>
              {[
                { label: 'Nome', value: clienteExistente.nome },
                { label: 'Telefone', value: clienteExistente.telefone },
                { label: 'Email', value: clienteExistente.email },
                {
                  label: 'Data de Nascimento',
                  value: clienteExistente?.dtnascimento ?
                    dayjs(clienteExistente.dtnascimento).format('DD/MM/YYYY') :
                    '-'
                },
                { label: 'Observação', value: clienteExistente.observacao || '-' },
                {
                  label: 'Receber novas propostas',
                  value: clienteExistente.recproposta ? 'Sim' : 'Não'
                },
                {
                  label: 'Corretor',
                  value: clienteExistente.operador?.nome || '-'
                }
              ].map(({ label, value }) => (
                <Stack key={label} spacing={0.5}>
                  <Typography variant="caption" color="text.secondary">
                    {label}
                  </Typography>
                  <Typography variant="body2">
                    {value}
                  </Typography>
                </Stack>
              ))}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="flex-end">
              {!showTransferirLead ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      substituirCliente();
                      setShowClienteExistente(false);
                    }}
                    disabled={loading}
                    size='small'
                  >
                    Vincular
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowTransferirLead(true);
                    }}
                    disabled={loading}
                    size='small'
                  >
                    Transferir Lead
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowClienteExistente(false);
                      setShowLoadClientExistente(false);
                    }}
                    disabled={loading}
                    size='small'
                  >
                    Cancelar
                  </Button>
                </>
              ) : (
                <Stack spacing={2} direction="row" alignItems="center">
                  <Typography variant="h4" color="text.secondary">
                    {'Para o corretor ' + clienteExistente.operador?.nome + ' ?'}
                  </Typography>
                  <Button
                    variant="text"
                    size='medium'
                    onClick={() => {
                      transferir();
                    }}
                    disabled={loading}
                  >
                    Sim
                  </Button>
                  <Button
                    variant="text"
                    size='medium'
                    onClick={() => {
                      setShowTransferirLead(false);
                    }}
                    disabled={loading}
                  >
                    Não
                  </Button>
                </Stack>
              )
              }

            </Stack>
          </Stack>
        </Paper>
      </Dialog>
    </>
  );
}

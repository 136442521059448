function handleSendWebWhatsapp(phone, nome, msg) {
  let _nrPhone = phone.replace(/\D/g, '');
  if (_nrPhone.startsWith('0')) {
    _nrPhone = _nrPhone.substring(1);
  }
  _nrPhone = `55${_nrPhone}`;
  if (msg === '') {
    msg = `Olá! Meu nome é ${nome} e sou corretor de imóveis. Agradeço pelo seu contato. Como posso auxiliá-lo hoje?`
  }
  const uri = `https://wa.me/${_nrPhone}?text=${msg}`
  const encoded = encodeURI(uri)
  const win = window.open(encoded, '_blank');
  win.focus();
}

export { handleSendWebWhatsapp };
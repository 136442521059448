import { LoadingButton } from '@mui/lab';
import { 
  Box,
  Button, 
  Card,
  CardContent,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Grid, 
  TextField, 
  Typography,
  useTheme 
} from '@mui/material';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import { deleteMessage, getMessage, saveMessage } from '../../../controllers/equipeController';
import { useEffect, useState } from 'react';

export function CadMensagem({ user, setHasMessage, setMsgAdm, msgAdm }) {
  const theme = useTheme();

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em CadMensagem', user)

  const [msg, setMsg] = useState()
  const [idMsg, setIdMsg] = useState()
  const [openExcluir, setOpenExcluir] = useState(false)

  async function handleGravar() {
    if (msg) {
      if (msg.length > 0) {
        const rst = await saveMessage(idMsg, user, msg)
        if (rst.sucess) {
          setHasMessage(true)
          setMsgAdm(rst.data)
          setIdMsg(rst.data.idmsg)
        }
      }
    }
  }

  async function loadMensagens(_conta) {
    let rst = await getMessage(_conta)
    if (rst.sucess) {
      if (rst.data.length > 0) {
        setIdMsg(rst.data[0].id)
        setMsg(rst.data[0].msg)
      }
    }
  }

  async function excluirMensagem() {
    let rst = await deleteMessage(idMsg, user.conta)
    if (rst.sucess) {
      setIdMsg(null)
      setMsgAdm(null)
      setHasMessage(false)
      setMsg('')
    }
    setOpenExcluir(false)
  }

  useEffect(() => {
    loadMensagens(user.conta)
  }, [])

  return (
    <Box sx={{ p: 2 }}>
      <Card elevation={0} sx={{ backgroundColor: theme.palette.background.neutral }}>
        <CardContent>
          <Typography 
            variant="h5" 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.primary.main,
              mb: 3,
              fontWeight: 600
            }}
          >
            <AnnouncementTwoToneIcon sx={{ mr: 1 }} />
            Quadro de Avisos
          </Typography>

          <TextField
            fullWidth
            multiline
            rows={12}
            placeholder="Digite sua mensagem aqui..."
            variant="outlined"
            onBlur={(event) => { setMsg(event.target.value) }}
            defaultValue={msg}
            inputProps={{ 
              maxLength: 1000,
              style: { 
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.light,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
          />

          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              gap: 1,
              mt: 2 
            }}
          >
            <LoadingButton
              size="medium"
              color="error"
              onClick={() => setOpenExcluir(true)}
              loadingPosition="start"
              variant="outlined"
              startIcon={<DeleteTwoToneIcon />}
              disabled={!msg}
              sx={{ 
                minWidth: 120,
                textTransform: 'none'
              }}
            >
              Excluir
            </LoadingButton>
            <LoadingButton
              size="medium"
              color="primary"
              onClick={handleGravar}
              loadingPosition="start"
              variant="contained"
              startIcon={<SaveTwoToneIcon />}
              sx={{ 
                minWidth: 120,
                textTransform: 'none'
              }}
            >
              Salvar
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>

      {/* Confirmation Dialog */}
      <Dialog
        open={openExcluir}
        onClose={() => setOpenExcluir(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            minWidth: 350
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography 
            variant="h6"
            sx={{ 
              color: theme.palette.error.main,
              fontWeight: 600
            }}
          >
            Confirmar Exclusão
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir esta mensagem?
            Esta ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button
            onClick={() => setOpenExcluir(false)}
            variant="outlined"
            sx={{
              textTransform: 'none',
              borderColor: theme.palette.grey[300],
              color: theme.palette.text.primary
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={excluirMensagem}
            variant="contained"
            color="error"
            disabled={!idMsg}
            sx={{ textTransform: 'none' }}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, Typography, Checkbox, CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';

import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { listCorretores, ordenarOperadores, updateCorretor } from '../../../controllers/crm/escalaController';
import { escalaStatus, operadorStatus } from '../../../constants/crm';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import dayjs from 'dayjs';


export function HabilitarCorretores({ openCorretores, setOpenCorretores, escala, listEscalas, setSnackbar, idusuario }) {

  const [corretores, setCorretores] = useState([])
  const [filtered, setFiltered] = useState(corretores)
  const [loading, setLoading] = useState(false)
  const [ordenada, setOrdenada] = useState(true)

  const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()

  // Pequisar corretores
  function pesquisarNome(event) {
    const filtro = corretores.filter(corretor => corretor.nome.toLowerCase().includes(event.target.value.toLowerCase()))
    setFiltered(filtro)
  }

  // Adicionar Check para todos os corretores
  function addCheck(_corretores) {
    _corretores.forEach(function (c, index) {
      if (c.status === operadorStatus.Disponivel) c.checked = true
      else c.checked = false
    })
    return _corretores
  }

  // Carregar Corretores da escala da camada de controle
  async function carregarCorretores(idescala, idconta) {
    setLoading(true)
    setCorretores([])
    setFiltered([])
    try {
      const where = { idescala: idescala, idconta: idconta }
      const response = await listCorretores(where)

      if (response.status === 200) {
        if (response.data.length > 0) {
          const _corretores = addCheck(response.data)
          setCorretores(_corretores)
          setFiltered(_corretores)
        }
        else {
          setSnackbar({ open: true, message: 'Não há corretores cadastrados para esta escala', severity: 'warning' })
          setCorretores([])
          setFiltered([])
        }
        setLoading(false)
      }
      else {
        setSnackbar({ open: true, message: 'Erro ao carregar corretores', severity: 'error' })
        setLoading(false)
      }
    }
    catch (e) {
      setSnackbar({ open: true, message: 'Erro ao carregar corretores', severity: 'error' })
      setLoading(false)
    }
  }

  // Atualizar status do corretor
  async function handleCheck(corretor, e) {
    
    const status = e.target.checked ? operadorStatus.Disponivel : operadorStatus.Indisponivel
    try {
      const response = await updateCorretor({
        idusuario: idusuario,
        idescala: escala.idescala,
        historico: escala.historico,
        nome: corretor.nome,
        idoperescala: corretor.idoperescala,
        ordem: corretor.ordem,
        status: status,
        version: corretor.version,
        versionescala: escala.version
      })
      // console.log('reponse',response)
      if (response.status === 200) {
        corretor.version = response.data.version
        escala.historico = response.data.historico
        escala.version = response.data.versionescala
        await carregarCorretores(escala.idescala, escala.idconta)
      }
      else {
        setSnackbar({ open: true, message: 'Erro ao atualizar status do corretor', severity: 'error' })
      }
    }
    catch (erro) {
      setOpenCorretores(false)
      if (erro.response.data.code === 'P2025') {
        setSnackbar({ open: true, message: 'Falha de acesso concorrente, realize novamente a operação!', severity: 'error' })
        listEscalas()
      }
      else
        setSnackbar({ open: true, message: 'Erro ao atualizar status do corretor', severity: 'error' })
    }
  }

  // Ordenar os corretores
  async function ordenarCorretores(_escala) {
    setLoading(true)
    const where = {
      idescala: _escala.idescala,
      tpdistribuicao: _escala.tpdistribuicao,
      status: _escala.status,
      idconta: _escala.idconta,
      dia: dayjs(_escala.diamesano).toISOString(),
      inout: _escala.inout,
      idusuario: idusuario,
      historico: _escala.historico,
      versionescala: _escala.version
    }
    if (corretores.length > 0) {
      try {
        const response = await ordenarOperadores(where)
        // console.log(response)
        if (response.status === 200) {
          if (response.data.operadores.length > 0) {
            // Atualiza o status da escala
            escala.status = escalaStatus.Ordenada
            // Atualiza o histórico da escala
            escala.historico = response.data.historico
            // Atualiza a versão da escala
            escala.version = response.data.versionescala
            // Carrega os corretores
            await carregarCorretores(escala.idescala, escala.idconta)
            setOrdenada(true)
            setSnackbar({ open: true, message: 'Corretores ordenados com sucesso', severity: 'success' })
          }
          else {
            setSnackbar({ open: true, message: 'Não foram encontrados registros para ordenar!', severity: 'warning' })
          }
        }
        else if (response.status === 201) {
          if (corretores.length > 0) {
            setOpenCorretores(false)
            setSnackbar({ open: true, message: 'Acesso Concorrente', severity: 'warning' })
            listEscalas()
          }
        }
        else if (response.status === 202) {
          setSnackbar({ open: true, message: 'Operadores já ordenados', severity: 'warning' })
          listEscalas()
        }
        else {
          setSnackbar({ open: true, message: 'Erro ao ordenar corretores', severity: 'error' })
        }
      }
      catch (erro) {
        setSnackbar({ open: true, message: 'Erro ao ordenar corretores', severity: 'error' })
      }
      finally {
        setLoading(false)
      }
    }
    else {
      setSnackbar({ open: true, message: 'Não há corretores cadastrados para esta escala', severity: 'warning' })
      setLoading(false)
    }
  }




  useEffect(() => {
    if (escala.idescala) {
      if (escala.status !== escalaStatus.Prevista) {
        setOrdenada(true)
      }
      else setOrdenada(false)
      carregarCorretores(escala.idescala, escala.idconta)
    }
  }, [])


  return (
    <Dialog open={openCorretores} >
      <DialogTitle >
        <Typography variant="h4" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          {`ON-LINE ( ${getDiaSemana(escala.diamesano).toLocaleUpperCase()} - ${new Date(escala.diamesano).toLocaleDateString()} )`}
        </Typography>
        <Typography variant="h5" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)} - ${dayjs(escala.hrinicial).format('HH:mm')} / ${dayjs(escala.hrfinal).format('HH:mm')}`}
        </Typography>

      </DialogTitle>
      <DialogContent dividers>
        <Grid container height={'450px'} >
          {(loading) && ((
            <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
              <CircularProgress />
            </div>
          ))}
          <Grid container direction={'row'} >
            <Grid item xs={8} >
              <Input id='pesquisar' fullWidth onChange={(e) => pesquisarNome(e)} placeholder="Pesquisar" />
            </Grid>
            <Grid item xs={4} textAlign={'end'}>
              <Button variant="contained" color="primary" size="small" sx={{ width: '100px' }}
                onClick={() => ordenarCorretores(escala)}
                disabled={loading || ordenada || (escala.diamesano < hoje)}
              >
                Ordenar
              </Button>
            </Grid>
          </Grid>
          <Grid container
          // my={'10px'}
          >
            <Grid item
              xs={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: grey[300] }}
            >
              <Typography variant="body1" fontSize={'12px'} component="div">
                Ord
              </Typography>
            </Grid>
            <Grid item
              xs={9}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: grey[400] }}
            >
              <Typography variant="body1" fontSize={'12px'} component="div">
                Nome
              </Typography>
            </Grid>
            <Grid item
              xs={2}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: grey[300] }}
            >
              <Typography variant="body1" fontSize={'12px'} component="div">
                Leads
              </Typography>
            </Grid>
          </Grid>
          <Grid container overflow={'auto'}
            height={'250px'}
            // display={'flex'}
            alignContent={'flex-start'}
            direction={'row'}
          // sx={{ backgroundColor: grey[200] }}
          >
            {filtered.map((operador) => (
              <Grid container key={operador.idoperador} py={'2px'}>
                <Grid
                  item xs={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ backgroundColor: grey[400] }}
                >
                  <Typography variant="body1" fontSize={'12px'} component="div">
                    {operador.ordem}
                  </Typography>
                </Grid>
                <Grid
                  item xs={6}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  px={'10px'}
                  sx={{ backgroundColor: grey[200] }} >
                  <Typography variant="body1" fontSize={'14px'} component="div" >
                    {operador.nome.substring(0, 30) + ' ...'}
                  </Typography>
                </Grid>
                <Grid
                  item xs={3}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  px={'10px'}
                  sx={{ backgroundColor: grey[200] }} >
                  <Typography variant="body1" fontSize={'11px'} component="div" >
                    {dayjs(operador.hrinicial).format('HH:mm') + ' / ' + dayjs(operador.hrfinal).format('HH:mm')}
                  </Typography>
                </Grid>
                <Grid
                  item xs={2}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ backgroundColor: grey[200] }}
                >
                  <Checkbox id={operador.idoperador}
                    defaultChecked={operador.checked}
                    onChange={(e) => handleCheck(operador, e)}
                    disabled={loading || (escala.diamesano < hoje)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container display={'flex'} justifyContent={'flex-start'} mt={'10px'} height={'100px'}
            sx={{ overflow: 'auto', backgroundColor: grey[200] }}
          >
            <Typography variant="body1" fontSize={'11px'} component="div">
              {escala.historico}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" color="primary" size="small"
            onClick={() => setOpenCorretores(false)}
          >
            Fechar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>

  );
}
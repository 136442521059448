import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// import { Start } from '../public/Start/Start';
// import SingUp from '../public/Login/SingUp';
// import Login from '../public/Login/Login'
// import Forget from '../public/Login/Forget';
import { Home } from '../private/Home/Home';
import { Crm } from '../private/Crm/Crm';
// import Leads from '../public/leads/leads';
const Start = lazy(() => import('../public/Start/Start'));
const SingUp = lazy(() => import('../public/Login/SingUp'));
const Login = lazy(() => import('../public/Login/Login'));
const Forget = lazy(() => import('../public/Login/Forget'));
// const Home = lazy(() => import('../private/Home/Home'));
// const Crm = lazy(() => import('../private/Crm/Crm'));
const Leads = lazy(() => import('../public/leads/leads'));



// import { Maintenance } from '../public/Login/Maintenance';


function AppRoutes() {

  const ProtectedRoute = ({ redirectPath = '/', children }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const { state } = useLocation();
    if (!token || token === undefined || !state?.account || state?.account === undefined) {
      return <Navigate to={redirectPath} replace />;
    }
    return <>{children}</>;
     
  };

  return (
    <Suspense fallback={<div>Loading...</div>} >
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/start" element={<Start />} />
        <Route path="/singin" element={<Login />} />
        <Route path="/singup" element={<SingUp />} />
        <Route path="/forget" element={<Forget />} />
        <Route path="/leads/:id1/:id2/:id3/:id4/:id5" element={<Leads />} />

        <Route path="home/" element={
          <ProtectedRoute redirectPath="/singin" >
              <Home />
          </ProtectedRoute>
        } />
        <Route path="crm/" element={
          <ProtectedRoute redirectPath="/singin" >
            <Crm />
          </ProtectedRoute>
        } />
        <Route path="*" element={<Start />} />

        {/* Exemplo de validação de usuarios
      !!user && user.permissions.includes('analyze') 
      !!user && user.roles.includes('admin')  */}

        {/* <Route
        path="settings"
        element={
          <ProtectedRoute redirectPath="/singin" >
            <Settings />
          </ProtectedRoute>
        }
      /> */}
      </Routes>
    </Suspense>
  )
}

export default AppRoutes;
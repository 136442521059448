import { db } from '../firebase';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, query, where, orderBy } from 'firebase/firestore';
import { result } from '../utils/result';

export const rememberController = {
  // Create - Adicionar um novo lembrete
  async create(iduser, rememberData) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em create:', iduser, rememberData);
    try {
      const docRef = await addDoc(collection(db, `usuarios/${iduser}/remembers`), {
        ...rememberData,
        createdAt: new Date(),
        updatedAt: new Date()
      });
      result.sucess = true;
      result.msg = 'Lembrete criado com sucesso';
      result.data = { id: docRef.id, ...rememberData };
    } catch (error) {
      result.sucess = false;
      result.msg = 'Erro ao criar lembrete';
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em create:', error);
    } finally {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de create:', result);
      return result;
    }
  },

  // Read - Buscar todos os lembretes do usuário
  async getAll(iduser) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getAll:', iduser);
    try {
      const q = query(collection(db, `usuarios/${iduser}/remembers`), orderBy('createdAt', 'asc'));
      const querySnapshot = await getDocs(q);
      const list = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      result.sucess = true;
      result.msg = 'Lembretes recuperados com sucesso';
      result.data = list;
    } catch (error) {
      result.sucess = false;
      result.msg = 'Erro ao buscar lembretes';
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getAll:', error);
    } finally {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getAll:', result);
      return result;
    }
  },

  // Read - Buscar lembretes por usuário e filtro adicional
  async getByUser(iduser, filter) {
    try {
      const q = query(
        collection(db, `usuarios/${iduser}/remembers`),
        where(filter.field, filter.operator, filter.value)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Erro ao buscar lembretes do usuário:', error);
      throw error;
    }
  },

  // Update - Atualizar um lembrete
  async update(iduser, id, rememberData) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em update:', iduser, id, rememberData);
    try {
      const rememberRef = doc(db, `usuarios/${iduser}/remembers`, id);
      await updateDoc(rememberRef, {
        ...rememberData,
        updatedAt: new Date()
      });
      result.sucess = true;
      result.msg = 'Lembrete atualizado com sucesso';
      result.data = { id, ...rememberData };
    } catch (error) {
      result.sucess = false;
      result.msg = 'Erro ao atualizar lembrete';
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em update:', error);
    } finally {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de update:', result);
      return result;
    }
  },

  // Delete - Remover um lembrete
  async delete(iduser, id) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em delete:', iduser, id);
    try {
      const rememberRef = doc(db, `usuarios/${iduser}/remembers`, id);
      await deleteDoc(rememberRef);
      result.sucess = true;
      result.msg = 'Lembrete removido com sucesso';
    } catch (error) {
      result.sucess = false;
      result.msg = 'Erro ao deletar lembrete';
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em delete:', error);
    } finally {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de delete:', result);
      return result;
    }
  }
};

export default rememberController;
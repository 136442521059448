// Formulario de seleção de escalas para o CRM.
// O Formulário possui duas áreas de controle, uma para escalas e outra para operadores.
// A área de escalas possui uma lista de Cards com as escalas disponíveis e um botão para selecionar a escala.
// A área de operadores possui uma lista de Cards com os operadores disponíveis e um botão para selecionar o operador.
// O formulario usa react e @mui, bem como o controller getEscalas.


import React, { useEffect, useState, useCallback } from 'react';
import {
  Button, Card, CardContent, Typography, Grid,
  Box, IconButton, Alert, CircularProgress,
  Fab, useMediaQuery, useTheme, Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import { getEscala, updateEscala } from '../../../controllers/crm/escalaController';
import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import { escalaStatus } from '../../../constants/crm';
import { HabilitarCorretores } from './HabilitarCorretores';
import { ExcluirEscala } from './ExcluirEscala';
import { AdicionarEscala } from './AdicionarEscala';
import { EditarEscala } from './EditEscala';
import { getEscalaSituacao } from '../../../utils/crm/getEscalaSituacao';
import { getEscalaCor } from '../../../utils/crm/getCorEscala';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
import { green, red, yellow } from '@mui/material/colors';
dayjs.locale("pt-br.js");


export default function EscalasSelection({ account, setEscalas, escalas }) {

  // Constantes Recebidas

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const idconta = account.conta
  const idusuario = account.id

  // Constantes de Estado
  const [operadores, setOperadores] = useState([])
  const [filteredEscalas, setFilteredEscalas] = useState([])
  const [escala, setEscala] = useState({})
  const [snack, setSnack] = useState(false)
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [newDay, setNewDay] = useState(dayjs(new Date()));
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openCorretores, setOpenCorretores] = useState(false);
  const [openDialogExcluir, setOpenDialogExcluir] = useState(false);
  const [openDialogEditar, setOpenDialogEditar] = useState(false);

  const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()

  // Constantes do Snackbar
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  // Memoized handlers using useCallback
  const handleExecutar = useCallback(async (escala) => {
    try {
      setDisabled(true);
      setLoading(true);
      const historico = ` # ${new Date().toLocaleString()} - Controle colocado em Execução (M) ${escala.historico}`;

      const response = await updateEscala({
        idescala: escala.idescala,
        status: escalaStatus.Executando,
        idusuario,
        historico,
        version: escala.version
      });

      if (response.status === 200) {
        setEscala({
          ...escala,
          historico,
          version: response.data.version,
          status: escalaStatus.Executando
        });
        setSnackbar({ severity: 'success', message: 'Controle colocado em execução com sucesso!' });
        loadEscalas();
      } else if (response.status === 210) {
        setSnackbar({ severity: 'error', message: 'Falha de acesso concorrente, realize novamente a operação.' });
        loadEscalas();
      }
    } catch (error) {
      setSnackbar({ severity: 'error', message: 'Acesso Concorrente!' });
      loadEscalas();
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  }, [idusuario]);

  const handleEditar = async (escala) => {
    setEscala(escala)
    setOpenDialogEditar(true)
  }

  // Encerrar Escala/Suspenso
  const handleSuspender = async (escala) => {
    try {
      setDisabled(true)
      setLoading(true)
      const historico = ' # ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString() + ' - Controle Suspenso (M) ' + escala.historico
      const response = await updateEscala({ idescala: escala.idescala, status: escalaStatus.Suspensa, idusuario: idusuario, historico: historico, version: escala.version });

      if (response.status !== 200) {
        if (response.status === 210) {
          setSnackbar({ severity: 'error', message: 'Falha de acesso concorrente, realize novamente a operação.' });
          loadEscalas()
        }
        else {
          setSnackbar({ severity: 'error', message: 'Erro ao colocar o controle em suspensão!' });
          return;
        }
      }
      if (response.status === 200) {
        escala.historico = historico
        escala.version = response.data.version
        escala.status = escalaStatus.Suspensa
        setSnackbar({ severity: 'success', message: 'Controle suspenso com sucesso!' });
      }
    }
    catch (e) {

      setSnackbar({ severity: 'error', message: 'Acesso Concorrente!' });
      loadEscalas()
    }
    finally {
      setDisabled(false)
      setLoading(false)
    }
  }

  // Excluir escala
  const handleExcluir = async (escala) => {
    setEscala(escala)
    setOpenDialogExcluir(true)
  }

  // Definir Dia
  function handleDayEscala(day) {
    setNewDay(day)
  }

  // Listar Escalas
  async function loadEscalas() {
    try {
      setLoading(true)
      const query = {
        take: 8,
        where: {
          idconta: idconta,
          isactive: true,
          diamesano: { gte: newDay.add(-1, 'day').toDate() }
        },
        orderBy: [
          { diamesano: 'asc' }
        ]
      };
      const response = await getEscala(query);
      if (response.status === 200) {
        setEscalas(response.data ? response.data : []);
        setFilteredEscalas(response.data);
        setLoading(false)
        return;
      }
      else {
        setSnackbar({ severity: 'error', message: 'Erro ao listar as escalas!' });
        setLoading(false)
      }
    }
    catch (error) {
      console.log('error', error)
      setLoading(false)
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
  }

  // Abrir Corretores
  function handleCorretores(_escala) {
    setEscala(_escala)
    setOpenCorretores(true)
  }

  useEffect(() => {
    loadEscalas()
  }, [newDay])

  // Extracted Card component for better organization
  const EscalaCard = ({ escala }) => (
    <Card
      elevation={3}
      sx={{
        backgroundColor: getEscalaCor(escala.status, escala.diamesano),
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.02)',
        },
        borderRadius: 2,
        minWidth: { xs: '300px', sm: '280px' }
      }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center"
            sx={{ 
              borderBottom: '1px solid', borderColor: 'divider', p: 1,
              bgcolor: escala.diamesano === hoje ? green[500] : 'transparent', 
              borderRadius: 2
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {getDiaSemana(escala.diamesano)}
            </Typography>
            <IconButton
              onClick={() => handleEditar(escala)}
              disabled={disabled || (escala.status !== escalaStatus.Prevista)}
              color="primary"
            >
              <EditCalendarTwoToneIcon />
            </IconButton>
          </Box>

          <Stack spacing={1}>
            <Typography variant="body1">
              {`Data: ${new Date(escala.diamesano).toLocaleDateString()}`}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {`Situação: ${getEscalaSituacao(escala.status, escala.diamesano)}`}
            </Typography>
            <Typography variant="body1">
              {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)}`}
            </Typography>
            <Typography variant="body1">
              {`Horário: ${dayjs(escala.hrinicial).format('HH:mm')} - ${dayjs(escala.hrfinal).format('HH:mm')}`}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              onClick={() => handleCorretores(escala)}
              disabled={disabled}
            >
              Corretores
            </Button>
          </Stack>

          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button
              variant="outlined"
              size="small"
              color="error"
              disabled={disabled || escala.status !== escalaStatus.Prevista}
              onClick={() => handleExcluir(escala)}
            >
              Excluir
            </Button>
            <Button
              variant="outlined"
              size="small"
              disabled={disabled || escala.status !== escalaStatus.Executando || escala.diamesano < hoje}
              onClick={() => handleSuspender(escala)}
            >
              Suspender
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={disabled || (escala.status !== escalaStatus.Ordenada && escala.status !== escalaStatus.Suspensa)}
              onClick={() => handleExecutar(escala)}
            >
              Executar
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Box
      sx={{
        width: '95%',
        height: '85vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        bgcolor: 'background.default',
        borderRadius: 2,
      }}
    >
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      <Stack
        direction='column'
        spacing={1}
        // alignItems="center"
        // justifyContent='space-between'
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          boxShadow: 1,
          position: 'sticky',
          top: 0,
          zIndex: 1000
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          color="primary"
          textAlign="left"
          pb={2}
        >
          OnLine Controle
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <DatePicker
            label="Selecione o dia"
            format='DD/MM/YYYY'
            value={newDay}
            onChange={handleDayEscala}
            sx={{
              width: isMobile ? '60%' : '200px',
              bgcolor: 'background.paper'
            }}
          />
          <Button
            variant="contained"
            sx={{
              display: isMobile ? 'none' : 'none',
              // width: '250px',
            }}
            // endIcon={<CalendarMonthTwoToneIcon />}
            startIcon={<CalendarMonthTwoToneIcon />}
            onClick={() => setOpenDialogAdd(true)}
            fullWidth={isMobile}
            size='small'
          >
            Adicionar
          </Button>
          <IconButton
            onClick={loadEscalas}
            disabled={loading}
            color="primary"
            sx={{
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ReplayTwoToneIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Box sx={{
        flex: 1,
        bgcolor: 'grey.100',
        overflow: 'auto',
        height: '100%'
      }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            justifyContent: { xs: 'center', sm: 'flex-start' }
          }}
        >
          {filteredEscalas.map((escala) => (
            <Grid
              item
              key={escala.idescala}
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                padding: 2
              }}
            >
              <EscalaCard escala={escala} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {openDialogAdd && (<AdicionarEscala
        setOpenDialogAdd={setOpenDialogAdd}
        openDialogAdd={openDialogAdd}
        idconta={idconta}
        idusuario={idusuario}
        setSnackbar={setSnackbar}
        loadEscalas={loadEscalas}
        setEscalas={setEscalas}
      />)}
      {openDialogExcluir && (<ExcluirEscala
        setOpenDialogExcluir={setOpenDialogExcluir}
        openDialogExcluir={openDialogExcluir}
        escala={escala} 
        escalas={escalas} 
        filteredEscalas={filteredEscalas}
        idusuario={idusuario}
        setSnack={setSnack}
        setSnackbar={setSnackbar}
        listEscalas={loadEscalas}
      />)}
      {openCorretores && (<HabilitarCorretores
        openCorretores={openCorretores}
        setOpenCorretores={setOpenCorretores}
        escala={escala}
        listEscalas={loadEscalas}
        setSnackbar={setSnackbar}
        idusuario={idusuario}
      />)}
      {openDialogEditar && (<EditarEscala
        setOpenDialogEditar={setOpenDialogEditar}
        openDialogEditar={openDialogEditar}
        idconta={idconta}
        idusuario={idusuario}
        setSnackbar={setSnackbar}
        listEscalas={loadEscalas}
        escala={escala}
      />)}
      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          autoHideDuration={4000}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            variant="filled"
            elevation={6}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => { setOpenDialogAdd(true) }}
        sx={{
          position: 'absolute',
          right: isMobile ? '16px' : '32px',
          bottom: isMobile ? '16px' : '32px',
          zIndex: 1000
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  )
}


import { useEffect, useState } from 'react'
import { distribuicaoTipo, escalaStatus, escalaTipo } from '../../../constants/crm'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Typography, Switch } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import { createEscalas } from '../../../controllers/crm/escalaController';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
dayjs.locale('pt-br');

export function AdicionarEscala(
  { setOpenDialogAdd,
    openDialogAdd,
    idconta,
    idusuario,
    setSnackbar,
    loadEscalas,
    setEscalas,
  }) {

  const [periodoOuDiaria, setPeriodoOuDiaria] = useState('d') // d -> Diária, p -> Período
  const [tpDistribuicao, setTpDistribuicao] = useState(distribuicaoTipo.Randomica)
  const [tpEscala, setTpEscala] = useState(escalaTipo.Manual) // a -> Automática, m -> Manual
  const [startDay, setStartDay] = useState(dayjs())
  const [endDay, setEndDay] = useState()
  const [load, setLoad] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [noEdit, setNoEdit] = useState(false)
  const [hrInicial, setHrInicial] = useState(dayjs('09:00', 'HH:mm'))
  const [hrFinal, setHrFinal] = useState(dayjs('20:00', 'HH:mm'))
  const [controleAbandonados, setControleAbandonados] = useState(false)

  const handleStartDayEscala = (day) => {
    setStartDay(day)
  }
  const handleEndDayEscala = (day) => {
    setEndDay(day)
  }

  const handleNo = () => {
    setPeriodoOuDiaria('d');
    setOpenDialogAdd(false);
  };

  const handleGravar = async () => {
    try {
      const dia = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
      const escalas = []
      setDisabled(true)
      setLoad(true)
      let _startDay = startDay
      if (!_startDay.isValid()) {
        setSnackbar({ severity: 'error', message: 'Data de início inválida!' });
        return;
      }
      if (periodoOuDiaria === 'p') {
        if (!endDay.isValid()) {
          setSnackbar({ severity: 'error', message: 'Data de fim inválida!' });
          return;
        }
        if (endDay.diff(_startDay, 'days') > 6) {
          setSnackbar({ severity: 'error', message: 'Período maior que o intervalo permitido!' });
          return;
        }
        let _finalDay = endDay.add(1, 'day')

        while (_startDay.isBefore(_finalDay)) {
          const escala = {
            idconta: idconta,
            diamesano: dayjs(_startDay.format('YYYY-MM-DD')).toISOString(),
            hrinicial: dayjs(_startDay.format('YYYY-MM-DD') + ' ' + hrInicial.format('HH:mm')).toISOString(),
            hrfinal: dayjs(_startDay.format('YYYY-MM-DD') + ' ' + hrFinal.format('HH:mm')).toISOString(),
            tpdistribuicao: tpDistribuicao,
            tpescala: tpEscala,
            status: escalaStatus.Prevista,
            idusuario: idusuario,
            historico: `# ${dia} - Escala Prevista (M)`,
            f_abandono: controleAbandonados
          }
          escalas.push(escala)
          _startDay = _startDay.add(1, 'day')
        }
      }
      else {
        const escala = {
          idconta: idconta,
          diamesano: dayjs(startDay.format('YYYY-MM-DD')).toISOString(),
          hrinicial: dayjs(startDay.format('YYYY-MM-DD') + ' ' + hrInicial.format('HH:mm')).toISOString(),
          hrfinal: dayjs(startDay.format('YYYY-MM-DD') + ' ' + hrFinal.format('HH:mm')).toISOString(),
          tpdistribuicao: tpDistribuicao,
          tpescala: tpEscala,
          status: escalaStatus.Prevista,
          idusuario: idusuario,
          historico: `# ${dia} - Escala Prevista (M)`,
          f_abandono: controleAbandonados
        }
        escalas.push(escala)
      }
      if (escalas) {
        const response = await createEscalas(escalas);
        if (response.status !== 200) {
          setSnackbar({ severity: 'error', message: 'Erro ao gravar escala!' });
          return;
        }
        if (response.status === 200) {
          if (response.data.count > 0) {
            if (response.data.deleted.count > 0) {
              setSnackbar({ severity: 'warning', message: `Escala gravada com sucesso! ${response.data.deleted.count} escala(s) antigas excluídas!`});	
            }
            else setSnackbar({ severity: 'success', message: 'Escala gravada com sucesso!' });

            loadEscalas()
          }
          else {
            setSnackbar({ severity: 'error', message: 'Escala já cadastrada!' });
          }
        }
      }
      else {
        setSnackbar({ severity: 'error', message: 'Não há escalas para gravar!' });
      }

    }
    catch (error) {
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
    finally {
      setDisabled(false)
      setLoad(false)
      setOpenDialogAdd(false)
    }
  };

  useEffect(() => {
    setEndDay(startDay.add(6, 'day'))
  }, [startDay])

  return (
    <Dialog
      maxWidth="xs"
      open={openDialogAdd}
      onClose={handleNo}
    >
      {(load) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <DialogTitle>
        <Typography variant="h4" component="div">
          CONTROLE do ON-LINE - Cadastro
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container
          direction={'row'}
          height={'320px'}
          maxWidth={'320px'}
        >
          <Grid container direction={'row'} marginBottom={0} >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={periodoOuDiaria}
              name="radio-buttons-group"
              onChange={(e) => setPeriodoOuDiaria(e.target.value)}
            >
              <Typography variant="body1" component="div" marginRight={'5px'}>
                <FormControlLabel value={'d'} control={<Radio />} label={'Por Data'} />
                <FormControlLabel value={'p'} control={<Radio />} label={'Por Período'} />
              </Typography>
            </RadioGroup>
          </Grid>
          <Grid container direction={'row'} >
            {periodoOuDiaria === 'd' && (
              <Grid container direction={'column'} gap={1} >
                <Grid item marginBottom={1}>
                  <DatePicker
                    label="Data"
                    minDate={dayjs()}
                    value={startDay}
                    onChange={handleStartDayEscala}
                    format='DD/MM/YYYY'
                    sx={{ width: '150px' }}
                  />
                </Grid>
              </Grid>
            )}
            {periodoOuDiaria === 'p' && (
              <Grid container direction={'row'} gap={1} >
                <Grid item marginBottom={1}>
                  <DatePicker
                    value={startDay}
                    label="Dt Inicial"
                    disabled={noEdit}
                    minDate={dayjs()}
                    onChange={handleStartDayEscala}
                    format='DD/MM/YYYY'
                    sx={{ width: '150px' }}
                  />
                </Grid>
                <Grid item marginBottom={1}>
                  <DatePicker
                    label="Dt Final"
                    value={endDay}
                    disabled={noEdit}
                    onChange={handleEndDayEscala}
                    format='DD/MM/YYYY'
                    sx={{ width: '150px' }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container direction={'row'} gap={1} >
              <Grid item >
                <TimePicker
                  label="Hr Inicial"
                  ampm={false}
                  value={hrInicial}
                  onChange={(date) => setHrInicial(date)}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  sx={{ width: '150px' }}
                />
              </Grid>
              <Grid item >
                <TimePicker
                  label="Hr Final"
                  value={hrFinal}
                  ampm={false}
                  onChange={(date) => setHrFinal(date)}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  sx={{ width: '150px' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={'column'} marginTop={1} >
            <Typography variant="body1" fontSize={14} component="div" marginRight={'5px'}>
              Ordem dos Corretores:
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={distribuicaoTipo.Randomica}
              name="radio-buttons-group"
              onChange={(e) => setTpDistribuicao(e.target.value)}
            >
              <div>
                <FormControlLabel value={distribuicaoTipo.Randomica} control={<Radio />} label={getDistribuicao(distribuicaoTipo.Randomica)} />
                <FormControlLabel value={distribuicaoTipo.Cadastro} control={<Radio />} label={getDistribuicao(distribuicaoTipo.Cadastro)} />
              </div>
            </RadioGroup>
            
            {/* Add Switch for abandoned leads control */}
            <Grid container alignItems="center" marginTop={1}>
              <Typography variant="body1" fontSize={14} component="div" marginRight={'10px'}>
                Controle de Leads Abandonados:
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={controleAbandonados}
                    onChange={(e) => setControleAbandonados(e.target.checked)}
                    color="primary"
                  />
                }
                label={controleAbandonados ? "Ativado" : "Desativado"}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} gap={2}>
          <Button variant="contained" onClick={handleGravar} disabled={disabled} size='small' >
            Cadastrar
          </Button>
          <Button variant="contained" onClick={handleNo} disabled={disabled} size='small'>
            Fechar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid, Tab,
  Box, Stack, IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { motion } from 'framer-motion';
import { Identificacao } from './Identificacao';
import { PolVendas } from './PolVendas';
import { Caracteristicas2 } from './Caracteristicas2';
import { Nuvem } from './Nuvem';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { deletePlace, savePlace } from '../../../controllers/placeController';
import { deleteImgEmpreendimentos, sendImgEmpreendimentos } from '../../../api/storage';


export function InfoPlace({
  addMap,
  setAddMap,
  setOpenModal,
  newPosition,
  setNewPlace,
  newPlace,
  setHasPlaceNew,
  user,
  showMySnackbar
}) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em InfoPlace', newPlace)

  const theme = useTheme();

  const [placeEdit, setPlaceEdit] = useState(newPlace)
  const [wasModified, setWasModified] = useState(false)
  const [fileImg, setFileImg] = useState(null)

  // Funções da MODAL
  const handleCloseCad = () => {
    setNewPlace({})
    setOpenModal(false)
    if (wasModified) {
      setHasPlaceNew(Date.now())
      setAddMap(false)
    }

  }


  // Funções da TAB

  const [activeExcluir, setActiveExcluir] = useState(false)
  const [vtab, setTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [openExcluirDialog, setOpenExcluirDialog] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const handleOpenExcluirDialog = () => {
    setOpenExcluirDialog(true);
  }
  async function handleClickExcluir() {
    setAddMap(false)
    setDisableButton(true)
    let rst = await deletePlace(newPlace, user).then(async (data) => {
      if (data.sucess) {
        await deleteImgEmpreendimentos(newPlace.id)
      }
      return data
    })
    if (rst.sucess) {
      setNewPlace({})
      setPlaceEdit({})
      setOpenModal(false)
      setHasPlaceNew(Date.now())
      showMySnackbar(rst.msg, 4000, 'success')
    }
    else {
      showMySnackbar(rst.msg, 4000, 'error')
    }
  }

  const handleClickGravar = async () => {
    setDisableButton(true)
    const file = placeEdit.file
    delete placeEdit.file
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Gravação placeEdit', placeEdit, newPlace, newPosition)

    if (!placeEdit.position) {
      placeEdit.position = newPosition
    }

    let rst = await savePlace(placeEdit, user)
    if (rst.sucess) {
      if (file && rst.data) {
        await sendImgEmpreendimentos(rst.data, file).then(async (_url) => {
          placeEdit.urlimagem = _url
          await savePlace(placeEdit, user)
        })
      }
      setActiveExcluir(true)
      setDisableButton(false)
      setWasModified(true)
      showMySnackbar(rst.msg, 500, 'success')
    }
    else {
      setDisableButton(false)
      showMySnackbar(rst.msg, 1500, 'error')
    }
  }

  useEffect(() => {
    if (newPlace.id && newPlace.id !== 'gimov') {
      setActiveExcluir(true)
    }
    if (newPlace.id === 'gimov') { setDisableButton(true) }
  }, [])

  const tabProps = {
    setPlaceEdit,
    placeEdit,
    setNewPlace,
    newPlace
  };

  return (
    <Grid item position={'absolute'}>
      <Dialog
        id='DialogInfoPlace'
        open={addMap}
        onClose={handleCloseCad}
        scroll='paper'
        maxWidth="md"
        fullWidth
        PaperProps={{
          component: motion.div,
          initial: { opacity: 0, y: 20 },
          animate: { opacity: 1, y: 0 },
          sx: {
            borderRadius: 2,
            overflow: 'hidden',
             // Changed background color
                  }
                }}
                >
                <DialogTitle
                  sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: theme.palette.mode === 'light'
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
                  borderBottom: 1,
                  borderColor: 'divider'
                  }}
                >
                  <Typography
                  // variant="h3"
                  sx={{
                    color: theme.palette.mode === 'light'
                    ? theme.palette.primary.dark
                    : theme.palette.primary.contrastText,
                    fontWeight: "medium"
                  }}
                  >
                  {placeEdit.empreendimento || 'Novo Empreendimento'}
                  </Typography>
                  <IconButton
                  onClick={handleCloseCad}
                  size="small"
                  sx={{
                    color: theme.palette.mode === 'light'
                    ? theme.palette.primary.dark
                    : theme.palette.primary.contrastText,
                    '&:hover': {
                    bgcolor: theme.palette.mode === 'light'
                      ? 'rgba(0, 0, 0, 0.1)'
                      : 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                  >
                  <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent
                  dividers
                  sx={{
                  p: 0,
                  bgcolor: 'background.paper',
                  }}
                >
                  <TabContext value={vtab}>
                  <Box sx={{
                    bgcolor: theme.palette.mode === 'light'
                    ? theme.palette.grey[50]
                    : theme.palette.grey[900],
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}>
                    <TabList
                    value={vtab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons='auto'
                    sx={{
                      '& .MuiTab-root': {
                      minHeight: 48,
                      px: 3,
                      fontWeight: 'medium',
                      textTransform: 'none',
                      color: theme.palette.text.secondary,
                      '&.Mui-selected': {
                        color: theme.palette.primary.main
                      }
                      },
                      '& .MuiTabs-indicator': {
                      height: 3,
                      borderRadius: '3px 3px 0 0',
                      bgcolor: theme.palette.primary.main
                      }
                    }}
                    >
                    <Tab label="Identificação" value='1' />
                    <Tab label="Política de Vendas" value='2' />
                    <Tab label="Características" value='3' />
                    <Tab label="Nuvem" value='4' />
                    </TabList>
                  </Box>

                  <Box sx={{
                    height: 'calc(100vh - 300px)',
                    overflow: 'auto',
                    bgcolor: theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[800],
                    '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.mode === 'light'
                      ? 'rgba(0,0,0,0.2)'
                      : 'rgba(255,255,255,0.2)',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'light'
                      ? 'rgba(0,0,0,0.3)'
                      : 'rgba(255,255,255,0.3)',
                    }
                    }
                  }}>
                    <TabPanel value='1' sx={{ p: { xs: 2, sm: 3 } }}>
                    <Identificacao {...tabProps} />
                    </TabPanel>
                    <TabPanel value='2' sx={{ p: { xs: 2, sm: 3 } }}>
                    <PolVendas {...tabProps} />
                    </TabPanel>
                    <TabPanel value='3' sx={{ p: { xs: 2, sm: 3 } }}>
                    <Caracteristicas2 {...tabProps} />
                    </TabPanel>
                    <TabPanel value='4' sx={{ p: { xs: 2, sm: 3 } }}>
                    <Nuvem {...tabProps} fileImg={fileImg} setFileImg={setFileImg} />
                    </TabPanel>
                  </Box>
                  </TabContext>
                </DialogContent>

                <DialogActions
                  sx={{
                  p: 2,
                  bgcolor: theme.palette.mode === 'light'
                    ? theme.palette.grey[50]
                    : theme.palette.grey[900],
                  borderTop: 1,
                  borderColor: 'divider'
                  }}
                >
                  <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ width: '100%', justifyContent: 'right' }}
                  >
                  {/* Left side buttons */}


            {/* Right side buttons */}
            <Stack direction="row" spacing={1}>
              {activeExcluir && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={handleOpenExcluirDialog}
                  disabled={!activeExcluir}
                  size="small"
                >
                  Excluir
                </Button>
              )}
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleClickGravar}
                disabled={disableButton}
                size="small"
              >
                Salvar
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseCad}
                size="small"
                startIcon={<CloseIcon />}
                sx={{
                  borderColor: theme.palette.mode === 'light'
                    ? 'rgba(0, 0, 0, 0.23)'
                    : 'rgba(255, 255, 255, 0.23)',
                  color: theme.palette.text.primary,
                  '&:hover': {
                    borderColor: theme.palette.mode === 'light'
                      ? 'rgba(0, 0, 0, 0.5)'
                      : 'rgba(255, 255, 255, 0.5)',
                  }
                }}
              >
                Fechar
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={openExcluirDialog}
        onClose={() => setOpenExcluirDialog(false)}
        PaperProps={{
          component: motion.div,
          initial: { opacity: 0, scale: 0.9 },
          animate: { opacity: 1, scale: 1 },
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          Confirmar Exclusão
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir o empreendimento{' '}
            <strong>{newPlace.empreendimento?.toUpperCase()}</strong>?
            Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setOpenExcluirDialog(false)}
            size="small"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleClickExcluir}
            size="small"
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
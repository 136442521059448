import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Card, 
  Divider,
  Tooltip,
  Snackbar,
  Alert,
  Paper,
  useMediaQuery,
  useTheme,
  Stack
} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { 
  FileDownload as FileDownloadIcon,
  ContentCopy as ContentCopyIcon,
  Share as ShareIcon,
} from '@mui/icons-material';

const QrCodeGenerator = ({ initialUrl = '' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  // Dynamic QR code size based on screen size
  const getQrSize = () => {
    if (isMobile) return 180;
    if (isTablet) return 220;
    return 250;
  };
  
  const [url, setUrl] = useState(initialUrl);
  const [qrSize, setQrSize] = useState(getQrSize());
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const qrRef = useRef();

  // Update QR size when screen size changes
  useEffect(() => {
    setQrSize(getQrSize());
  }, [isMobile, isTablet]);

  // Update URL when initialUrl prop changes
  useEffect(() => {
    if (initialUrl !== url) {
      setUrl(initialUrl);
    }
  }, [initialUrl, url]);

  const downloadQRCode = () => {
    if (!url.trim()) {
      showNotification('Por favor insira uma URL válida', 'error');
      return;
    }

    const canvas = qrRef.current.querySelector('canvas');
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showNotification('QR Code baixado com sucesso!');
  };

  const copyToClipboard = async () => {
    if (!url.trim()) {
      showNotification('Por favor insira uma URL válida', 'error');
      return;
    }

    try {
      const canvas = qrRef.current.querySelector('canvas');
      canvas.toBlob(async (blob) => {
        const clipboardItem = new ClipboardItem({ 'image/png': blob });
        await navigator.clipboard.write([clipboardItem]);
        showNotification('QR Code copiado para a área de transferência!');
      });
    } catch (error) {
      showNotification('Falha ao copiar o QR Code', 'error');
      console.error('Copy failed:', error);
    }
  };

  const shareQRCode = async () => {
    if (!url.trim()) {
      showNotification('Por favor insira uma URL válida', 'error');
      return;
    }

    if (navigator.share) {
      try {
        const canvas = qrRef.current.querySelector('canvas');
        const blob = await new Promise(resolve => canvas.toBlob(resolve));
        const file = new File([blob], 'qrcode.png', { type: 'image/png' });
        
        await navigator.share({
          title: 'QR Code',
          text: 'Confira este QR Code',
          files: [file]
        });
        
        showNotification('QR Code compartilhado com sucesso!');
      } catch (error) {
        console.error('Share failed:', error);
        showNotification('Falha ao compartilhar o QR Code', 'error');
      }
    } else {
      showNotification('API de Compartilhamento não é suportada neste navegador', 'warning');
    }
  };

  const showNotification = (message, severity = 'success') => {
    setNotification({ open: true, message, severity });
  };

  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container 
      maxWidth={isMobile ? "xs" : "sm"} 
      disableGutters={isMobile}
      sx={{ py: isMobile ? 2 : 3 }}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          p: isMobile ? 2 : 3, 
          borderRadius: 2,
          mx: isMobile ? 1 : 0
        }}
      >
        <Typography 
          variant={isMobile ? "h6" : "h5"} 
          component="h2" 
          gutterBottom 
          align="center" 
          sx={{ 
            fontWeight: 'bold', 
            color: 'primary.main',
            mb: isMobile ? 1 : 2
          }}
        >
          QR Code
        </Typography>
        
        <Divider sx={{ my: isMobile ? 1 : 2 }} />
        
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mb: isMobile ? 2 : 3 
          }}
        >
          <Card 
            elevation={4}
            sx={{
              p: isMobile ? 1 : 2,
              backgroundColor: '#fff',
              borderRadius: 2,
              display: 'inline-block',
            }}
          >
            <Box 
              ref={qrRef} 
              sx={{ 
                p: isMobile ? 2 : 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: qrSize,
                minWidth: qrSize
              }}
            >
              {url ? (
                <QRCodeCanvas
                  value={url}
                  size={qrSize}
                  level="H"
                  includeMargin
                />
              ) : (
                <Typography variant="body2" color="text.secondary" align="center">
                  Insira uma URL para gerar o QR Code
                </Typography>
              )}
            </Box>
          </Card>
        </Box>
        
        <Stack 
          direction={isMobile ? "column" : "row"} 
          spacing={isMobile ? 1 : 2}
          justifyContent="center"
          sx={{ mt: 1 }}
        >
          {/* Fix: Wrap the tooltip content in a div to avoid aria-hidden issues */}
          <Tooltip title="Baixar QR Code">
            <span> {/* Using span as a wrapper to fix the aria-hidden issue */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileDownloadIcon />}
                onClick={downloadQRCode}
                disabled={!url}
                fullWidth={isMobile}
                size={isMobile ? 'medium' : 'small'}
              >
                Baixar
              </Button>
            </span>
          </Tooltip>
          
          <Tooltip title="Copiar para Área de Transferência">
            <span> {/* Using span as a wrapper to fix the aria-hidden issue */}
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ContentCopyIcon />}
                onClick={copyToClipboard}
                disabled={!url}
                fullWidth={isMobile}
                size={isMobile ? 'medium' : 'small'}
              >
                Copiar
              </Button>
            </span>
          </Tooltip>
          
          <Tooltip title="Compartilhar QR Code">
            <span> {/* Using span as a wrapper to fix the aria-hidden issue */}
              <Button
                variant="outlined"
                color="info"
                startIcon={<ShareIcon />}
                onClick={shareQRCode}
                disabled={!url}
                fullWidth={isMobile}
                size={isMobile ? 'medium' : 'small'}
              >
                Compartilhar
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Paper>
      
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={closeNotification}
        anchorOrigin={{ 
          vertical: 'bottom', 
          horizontal: 'center' 
        }}
      >
        <Alert 
          onClose={closeNotification} 
          severity={notification.severity} 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default QrCodeGenerator;
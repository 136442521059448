import { Box, Button, CircularProgress, Grid, Typography, Stack, IconButton, Paper, Fade, useMediaQuery, useTheme } from '@mui/material';
import { distributeLeads, getAllLeads } from '../../../controllers/crm/leadController';
import { useEffect, useState } from 'react';
import { green, red } from '@mui/material/colors';
import { escalaTipo, leadStatus } from '../../../constants/crm';
import { listCorretoresDistribuicao } from '../../../controllers/crm/escalaController';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import dayjs from 'dayjs';


export default function LeadsDistribuicao({ account, setUpdate, setSnackbar, listen }) {

  // Variáveis de Recebidas
  const idconta = account.conta;
  const idusuario = account.id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Constante de Estado

  const [leads, setLeads] = useState([]);
  const [corretores, setCorretores] = useState([]);
  const [dia, setDia] = useState(dayjs().format('DD-MM-YYYY'));
  const [disabled, setDisabled] = useState(false);


  async function handleReload() {
    await loadLeadsDisponiveis();
    await loadCorretores();
    // setUpdate(Date.now())
  }

  async function loadLeadsDisponiveis() {
    setDisabled(true);
    const query = {
      where: {
        idconta: idconta,
        status: leadStatus.Disponivel,
        isactive: true,
      },
    };
    try {
      const api = await getAllLeads(query);
      if (api.status === 200) {
        // setUpdate(Date.now());
        if (api.data.length > 0) {
          setLeads(api.data);
          setSnackbar({ severity: 'success', message: 'Leads carregados com sucesso!' });
        } else {
          setLeads([]);
        }
      } else {
        setLeads([]);
      }
    } catch (e) {
      setSnackbar({ severity: 'error', message: 'Erro ao carregar Leads!' });
    } finally {
      setDisabled(false);
    }
  }

  async function loadCorretores() {
    setDisabled(true);
    const dados = {
      diamesano: dayjs().format('YYYY-MM-DD'),
      idconta: idconta,
    };
    try {
      const api = await listCorretoresDistribuicao(dados);
      if (api.status === 200) {
        if (api.data.length > 0) {
          setCorretores(api.data);
          setSnackbar({ severity: 'success', message: 'Corretores carregados com sucesso!' });
        } else {
          setCorretores([]);
          setSnackbar({ severity: 'warning', message: 'Não há Corretores disponíveis para distribuição!' });
        }
      } else {
        setCorretores([]);
      }
    } catch (e) {
      setSnackbar({ severity: 'error', message: 'Erro ao carregar Corretores!' });
    } finally {
      setDisabled(false);
    }
  }

  async function distribuirLeads() {

    const dados = {
      idconta: idconta,
      diamesano: dayjs().format('YYYY-MM-DD'),
      idusuario: idusuario,
      processo: escalaTipo.Manual,
    };
    try {
      setDisabled(true);
      const result = await distributeLeads(dados).then(async (res) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        await loadCorretores();
        return res
      });
      if (result.status === 200) {
        setLeads([]);
        setUpdate(Date.now());
        setSnackbar({ severity: 'success', message: 'Leads Distribuídos com Sucesso!' });
      }
      else if (result.status === 250) {
        console.log(result.data);
        setSnackbar({ severity: 'warning', message: 'Fora de horário para distribuição dos leads.' });
      }
      else {
        setLeads([]);
      }
    } catch (e) {
      setSnackbar({ severity: 'error', message: 'Erro ao distribuir Leads!' });
    }
    finally {
      setDisabled(false);
    }
  }
  useEffect(() => {
    loadLeadsDisponiveis();
    loadCorretores();
  }, []);

  return (
    <Box
      sx={{
        width: '95%',
        height: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        bgcolor: 'background.default',
        borderRadius: 2,
      }}
    >
      {/* Loading Overlay */}
      {disabled && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(4px)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Header Section */}
      <Stack
        spacing={2}
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          boxShadow: 1,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="primary">
          Distribuição de Leads
        </Typography>
        <Typography variant="h3" color="text.secondary">
          Data: {dia}
        </Typography>
      </Stack>

      {/* Corretores Section */}
      <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" fontWeight="medium">
            Corretores disponíveis ({corretores.length})
          </Typography>
          <IconButton
            onClick={handleReload}
            disabled={disabled}
            color="primary"
            sx={{
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'action.hover' },
            }}
          >
            <ReplayTwoToneIcon />
          </IconButton>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            overflowX: 'auto',
            p: 2,
            '::-webkit-scrollbar': {
              height: 8,
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderRadius: 4,
            },
          }}
        >
          {corretores.length > 0 ? (
            corretores.map((corretor, i) => (
              <Paper
                key={i}
                elevation={2}
                sx={{
                  p: 2,
                  minWidth: 200,
                  bgcolor: green[50],
                  border: 1,
                  borderColor: green[200],
                  borderRadius: 2,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 4,
                  },
                }}
              >
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="caption" color="text.secondary">
                      Ordem:
                    </Typography>
                    <Typography variant="subtitle2">{corretor.ordem}</Typography>
                  </Box>
                  <Stack spacing={0.5}>
                    <Typography variant="caption" color="text.secondary">
                      Nome:
                    </Typography>
                    <Typography variant="subtitle2" noWrap>
                      {corretor.nome}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Telefone:
                    </Typography>
                    <Typography variant="subtitle2">{corretor.telefone}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Leads atribuídos:{' '}
                      <Typography component="span" fontWeight="bold">
                        {corretor.qtd}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            ))
          ) : (
            <Paper
              sx={{
                p: 2,
                width: '100%',
                bgcolor: red[50],
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" color="error">
                Não há Corretores disponíveis para distribuição!
              </Typography>
            </Paper>
          )}
        </Box>
      </Box>

      {/* Leads Section */}
      <Box sx={{ flex: 1, p: 2, bgcolor: 'grey.100' }}>
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: 1,
            }}
          >
            <Typography variant="h5">Leads p/ distribuição ({leads.length})</Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={leads.length === 0 || corretores.length === 0}
              onClick={distribuirLeads}
            >
              Distribuir
            </Button>
          </Box>

          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: 0,
            }}
          >
            {leads.map((lead, i) => (
              <Grid item
                minWidth={isMobile ? '100%' : '250px'}
                key={i}>
                <Fade in style={{ transitionDelay: `${i * 50}ms` }}>
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                      height: '100%',
                      transition: 'all 0.3s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: 4,
                      },
                    }}
                  >
                    <Stack spacing={1.5}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle2" color="primary">
                          Lead #{i + 1}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {new Date(lead.createdat).toLocaleString()}
                        </Typography>
                      </Box>
                      <Stack spacing={0.5}>
                        <Typography variant="caption" color="text.secondary">
                          Nome:
                        </Typography>
                        <Typography variant="subtitle2">{lead.nome}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          Origem:
                        </Typography>
                        <Typography variant="subtitle2">{lead.origem}</Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
}
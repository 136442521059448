import { useState, useEffect } from 'react';
import {
  Box, Paper, Typography, Stack, Divider, Button, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, InputAdornment, Chip, Dialog, Avatar, Tooltip, CircularProgress,
  useTheme, TablePagination, Fade, Menu, MenuItem, useMediaQuery
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  SwapHoriz as SwapHorizIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
  Person as PersonIcon,
  Sort as SortIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';

import CarteiraDetalhe from './CarteiraDetalhe';
import { transferirLead } from '../../../controllers/crm/leadController';
import dayjs from 'dayjs';
import { atualizarCarteira, listarClientes } from '../../../controllers/crm/carteiraController';
import { getOperador } from '../../../controllers/crm/operadorController';
import { blue, orange, red } from '@mui/material/colors';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

export default function CarteiraSelecao({ account, setSnackbar }) {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('nome');
  const [sortDirection, setSortDirection] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [showClienteDetail, setShowClienteDetail] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [transferTarget, setTransferTarget] = useState(null);
  const [operadores, setOperadores] = useState([]);
  const [selectedOperador, setSelectedOperador] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [update, setUpdate] = useState(Date.now());

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  async function loadOperadores() {
    setLoading(true);
    try {
      const where = {
        idconta: account.conta
      }
      const data = {}
      data.where = where
      const result = await getOperador(data);
      // console.log('result', result)
      if (result.status === 200) {
        if (result.data.length > 0) {
          setOperadores(result.data)
        }
        else {
          setOperadores([])
        }
      }
      else {
        setOperadores([])
      }
    }
    catch (error) {
      console.error('Erro ao carregar operadores', error);
      setSnackbar({ open: true, message: 'Erro ao carregar operadores', severity: 'error' });
    }
    finally {
      setLoading(false);
    }
  }


  async function loadClientes() {
    setLoading(true);
    try {
      const query = {}

      const where = {
        idconta: account.conta,
        idoperador: account.id
      }
      if (account.conta === account.id) {
        delete where.idoperador
      }
      const select = {
        idcliente: true,
        idconta: true,
        idoperador: true,
        nome: true,
        telefone: true,
        email: true,
        dtnascimento: true,
        recproposta: true,
        observacao: true,
        updatedat: true,
        version: true,
        operador: {
          select: {
            nome: true,
            idoperador: true,
            telefone: true,
          }
        }
      }
      query.select = select
      query.where = where
      const result = await listarClientes(query);

      // console.log('result', result)
      if (result.status === 200) {
        if (result.data.data.length > 0) {
          setClientes(result.data.data)
          setFilteredClientes(result.data.data)
        }
        else {
          setClientes([])
          setFilteredClientes([])
        }
      }
      else {
        setClientes([])
      }
    }
    catch (error) {
      console.error('Erro ao carregar clientes', error);
      setSnackbar({ open: true, message: 'Erro ao carregar clientes', severity: 'error' });
    }
    finally {
      setLoading(false);
    }
  }



  // Demo data - replace with your actual API call
  useEffect(() => {
    loadClientes();
    loadOperadores();
  }, [account.id, update]);

  useEffect(() => {
    filterClientes();
  }, [search, clientes, sortBy, sortDirection]);

  const filterClientes = () => {
    let filtered = [...clientes];

    // Filter by search term
    if (search) {
      const searchLower = search.toLowerCase();
      filtered = filtered.filter(cliente =>
        cliente.nome.toLowerCase().includes(searchLower) ||
        cliente.telefone.includes(search) ||
        cliente.email.toLowerCase().includes(searchLower)
      );
    }

    // Sort
    filtered.sort((a, b) => {
      let comparison = 0;

      if (sortBy === 'nome') {
        comparison = a.nome.localeCompare(b.nome);
      } else if (sortBy === 'updatedat') {
        comparison = new Date(a.updatedat) - new Date(b.updatedat);
      } else if (sortBy === 'recproposta') {
        comparison = (a.recproposta === b.recproposta) ? 0 : a.recproposta ? -1 : 1;
      } else if (sortBy === 'corretor') {
        // Nova lógica de ordenação por corretor
        comparison = a.operador.nome.localeCompare(b.operador.nome);
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    setFilteredClientes(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  const handleMenuOpen = (event, cliente) => {
    setAnchorEl(event.currentTarget);
    setSelectedCliente(cliente);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setShowClienteDetail(true);
    handleMenuClose();
  };

  const handleTransfer = () => {
    setTransferTarget(selectedCliente);
    setShowTransferDialog(true);
    handleMenuClose();
  };

  const handleRowClick = (cliente) => {
    if (isMobile) {
      setSelectedCliente(cliente);
      setShowClienteDetail(true);
    }
    else{
      setSelectedCliente(cliente);
      // setShowClienteDetail(true);
    }
  };

  const handleTransferConfirm = async () => {
    if (!selectedOperador) {
      setSnackbar({ open: true, message: 'Selecione um corretor para transferir', severity: 'warning' });
      return;
    }

    setLoading(true);
    try {
      // Simulate API call
      const transferir = {
        idcliente: transferTarget.idcliente,
        idconta: account.conta,
        idoperador: selectedOperador.idoperador,
        idusuario: account.id
      };
      const result = await atualizarCarteira(transferir);
      console.log('result', result)
      if (result.status === 200) {
        setSnackbar({ open: true, message: `Cliente transferido para ${selectedOperador.nome}`, severity: 'success' });
        setShowTransferDialog(false);
        setSelectedOperador(null);
        setUpdate(Date.now());
        setLoading(false);
      }
      else {
        setSnackbar({ open: true, message: 'Erro ao transferir cliente', severity: 'error' });
        setLoading(false);
      }
    } catch (error) {
      console.error('Erro ao transferir cliente', error);
      setSnackbar({ open: true, message: 'Erro ao transferir cliente', severity: 'error' });
      setLoading(false);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredClientes.length) : 0;
  const cor1 = red[50];
  const cor2 = orange[50];
  const cor3 = blue[50];
  return (
    <>
      {loading && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      <Paper

        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        elevation={3}
        sx={{
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          overflow: 'hidden',
          bgcolor: 'background.paper',
          height: { xs: 'calc(100dvh - 150px)', sm: 'calc(100dvh - 200px)', lg: 'calc(100dvh - 220px)' },
          width: { xs: '95%', sm: '98%', lg: '95%' },
          maxWidth: '1600px',
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          justifyContent="space-between"
          alignItems={{ xs: 'stretch', sm: 'center' }}
          mb={{ xs: 2, sm: 3 }}
        >
          <Typography variant="h5" fontWeight="bold">
            Minha Carteira de Clientes
          </Typography>

          <Box sx={{
            display: 'flex',
            gap: 1,
            width: { xs: '100%', sm: 'auto' },
            flexWrap: { xs: 'nowrap', sm: 'nowrap' }
          }}>
            <TextField
              size="small"
              placeholder="Buscar cliente..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              sx={{
                minWidth: { xs: '100%', sm: 220, md: 250 },
                flex: { xs: 1, sm: 'none' }
              }}
            />

            <Tooltip title="Filtros">
              <IconButton size="small">
                <FilterListIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>

        <TableContainer
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          sx={{
            flex: 1,
            overflow: 'auto',
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`
          }}
        >
          <Table stickyHeader size={isMobile ? "small" : "medium"}>
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': {
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    fontWeight: 'medium'
                  }
                }}>
                <TableCell
                  onClick={() => handleSort('nome')}
                  sx={{
                    bgcolor: 'background.paper',
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'action.hover' },
                    fontWeight: 'bold',
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 }
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    Nome
                    {sortBy === 'nome' && (
                      <SortIcon
                        fontSize="small"
                        sx={{
                          transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
                {!isMobile && (
                  <TableCell sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 1.5 } }}>
                    Contato
                  </TableCell>
                )}
                <TableCell
                  onClick={() => handleSort('recproposta')}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'action.hover' },
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 },
                    display: { xs: 'none', md: 'table-cell' }
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    Proposta
                    {sortBy === 'recproposta' && (
                      <SortIcon
                        fontSize="small"
                        sx={{
                          transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
                
                {!isMobile && (
                  <TableCell 
                    onClick={() => handleSort('corretor')}
                    sx={{ 
                      px: { xs: 1, sm: 2 }, 
                      py: { xs: 1, sm: 1.5 },
                      cursor: 'pointer',
                      '&:hover': { bgcolor: 'action.hover' }
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      Corretor
                      {sortBy === 'corretor' && (
                        <SortIcon
                          fontSize="small"
                          sx={{
                            transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none',
                            transition: 'transform 0.2s'
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                )}
                
                <TableCell
                  onClick={() => handleSort('updatedat')}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'action.hover' },
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 },
                    display: { xs: 'none', sm: 'table-cell' }
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    Atualização
                    {sortBy === 'updatedat' && (
                      <SortIcon
                        fontSize="small"
                        sx={{
                          transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    width: '48px',
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 }
                  }}
                >
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClientes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cliente) => (
                <TableRow
                  key={cliente.idcliente}
                  hover
                  onClick={() => handleRowClick(cliente)}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    // transition: 'background-color 0.2s',
                    borderColor: selectedCliente?.idcliente === cliente.idcliente
                      ? cor1
                      : null,
                    bgcolor: selectedCliente?.idcliente === cliente.idcliente
                      ? cor1
                      : null,
                    '&:hover': {  
                      bgcolor: selectedCliente?.idcliente === cliente.idcliente
                        ? cor1
                        : cor2,
                    }
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 1.5 } }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar
                        sx={{
                          width: { xs: 28, sm: 32 },
                          height: { xs: 28, sm: 32 },
                          bgcolor: theme.palette.primary.light
                        }}
                      >
                        {cliente.nome.charAt(0)}
                      </Avatar>
                      <Stack>
                        <Typography
                          variant={isMobile ? "caption" : "body2"}
                          fontWeight="medium"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {cliente.nome}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                          {cliente.dtnascimento ? dayjs(cliente.dtnascimento).format('DD/MM/YYYY') : 'Não informado'}
                        </Typography>
                        {isMobile && (
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {formatPhoneNumber(cliente.telefone, true)}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>

                  {!isMobile && (
                    <TableCell sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 1.5 } }}>
                      <Stack>
                        <Typography
                          variant="body2"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {cliente.telefone}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {cliente.email}
                        </Typography>
                      </Stack>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      px: { xs: 1, sm: 2 },
                      py: { xs: 1, sm: 1.5 },
                      display: { xs: 'none', md: 'table-cell' }
                    }}
                  >
                    <Chip
                      label={cliente.recproposta ? "Ativo" : "Inativo"}
                      size="small"
                      color={cliente.recproposta ? "success" : "default"}
                      variant="outlined"
                      sx={{ fontSize: '0.75rem' }}
                    />
                  </TableCell>
                  {!isMobile && (
                    <TableCell sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 1.5 } }}>
                      <Stack>
                        <Typography
                          variant="body2"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {cliente.operador.nome}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {formatPhoneNumber(cliente.operador.telefone, true)}
                        </Typography>
                      </Stack>
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      px: { xs: 1, sm: 2 },
                      py: { xs: 1, sm: 1.5 },
                      display: { xs: 'none', sm: 'table-cell' }
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(cliente.updatedat).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="right"
                    sx={{
                      px: { xs: 1, sm: 2 },
                      py: { xs: 1, sm: 1.5 }
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMenuOpen(e, cliente);
                      }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: (isMobile ? 40 : 53) * emptyRows }}>
                  <TableCell colSpan={isMobile ? 3 : 5} />
                </TableRow>
              )}
              {filteredClientes.length === 0 && (
                <TableRow>
                  <TableCell colSpan={isMobile ? 3 : 5} align="center" sx={{ py: 3 }}>
                    <Stack spacing={1} alignItems="center">
                      <PersonIcon sx={{ fontSize: 40, color: 'text.disabled' }} />
                      <Typography variant="body2" color="text.secondary">
                        Nenhum cliente encontrado
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={isMobile ? [5, 10] : [5, 10, 25]}
          component="div"
          count={filteredClientes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={isMobile ? "" : "Linhas por página:"}
          sx={{
            '.MuiTablePagination-selectLabel': {
              display: { xs: 'none', sm: 'block' }
            },
            '.MuiTablePagination-displayedRows': {
              fontSize: { xs: '0.75rem', sm: '0.875rem' }
            },
            '.MuiTablePagination-select': {
              paddingY: { xs: 0 }
            }
          }}
        />
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: {
            minWidth: { xs: 150, sm: 180 },
            borderRadius: 2
          }
        }}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Editar cliente
        </MenuItem>
        {account.conta === account.id && (
          <MenuItem onClick={handleTransfer}>
            <SwapHorizIcon fontSize="small" sx={{ mr: 1 }} />
            Transferir cliente
          </MenuItem>
        )}
      </Menu>

      <Dialog
        fullWidth
        maxWidth="md"
        fullScreen={isMobile}
        open={showClienteDetail}
        onClose={() => setShowClienteDetail(false)}
        PaperProps={{
          sx: { borderRadius: isMobile ? 0 : 2 }
        }}
      >
        {selectedCliente && (
          <CarteiraDetalhe
            cliente={selectedCliente}
            account={account}
            setSnackbar={setSnackbar}
            onClose={() => setShowClienteDetail(false)}
            onUpdate={() => setUpdate(Date.now())}
          />
        )}
      </Dialog>

      <Dialog
        open={showTransferDialog}
        onClose={() => setShowTransferDialog(false)}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '450px' },
            maxWidth: { xs: '100%', sm: '95%' },
            height: isMobile ? '100%' : 'auto',
            borderRadius: isMobile ? 0 : 2,
            p: { xs: 2, sm: 2 }
          }
        }}
      >
        <Typography variant="h6" gutterBottom>
          Transferir Cliente
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {transferTarget && (
          <Stack spacing={2}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Cliente a ser transferido:
              </Typography>
              <Typography variant="body1" fontWeight="medium">
                {transferTarget.nome}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {transferTarget.telefone} • {transferTarget.email}
              </Typography>
            </Box>

            <Typography variant="subtitle2">
              Selecione o novo corretor:
            </Typography>

            <Box sx={{
              maxHeight: { xs: 'calc(100vh - 250px)', sm: '300px' },
              overflow: 'auto',
              pr: 1
            }}>
              <Stack spacing={1}>
                {operadores.map((operador) => (
                  <Paper
                    key={operador.idoperador}
                    elevation={0}
                    onClick={() => setSelectedOperador(operador)}
                    sx={{
                      p: 1.5,
                      borderRadius: 1,
                      cursor: 'pointer',
                      border: '1px solid',
                      borderColor: selectedOperador?.idoperador === operador.idoperador
                        ? cor1
                        : null,
                      bgcolor: selectedOperador?.idoperador === operador.idoperador
                        ? cor1
                        : null,
                      '&:hover': {
                        bgcolor: selectedOperador?.idoperador === operador.idoperador
                          ? cor1
                          : cor3,
                      }
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          bgcolor: theme.palette.primary.light
                        }}
                      >
                        {operador.nome.charAt(0)}
                      </Avatar>
                      <Stack>
                        <Typography variant="body1">
                          {operador.nome}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {operador.telefone}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Box>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="flex-end"
              sx={{
                mt: 2,
                position: isMobile ? 'sticky' : 'relative',
                bottom: isMobile ? 0 : 'auto',
                pt: isMobile ? 2 : 0,
                pb: isMobile ? 2 : 0,
                bgcolor: 'background.paper',
                width: '100%'
              }}
            >
              <Button
                fullWidth={isMobile}
                variant="outlined"
                onClick={() => setShowTransferDialog(false)}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={isMobile}
                variant="contained"
                onClick={handleTransferConfirm}
                disabled={(!selectedOperador || loading) || (selectedOperador.idoperador === transferTarget.idoperador)}
              >
                Transferir
              </Button>
            </Stack>
          </Stack>
        )}
      </Dialog>
    </>
  );
}
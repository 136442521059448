import dayjs from 'dayjs'
import { escalaStatus } from '../../constants/crm'
import { blue, green, grey, orange, red, yellow } from '@mui/material/colors'

  // Função de Cor
  export function getEscalaCor(status, diamesano) {
    const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()
    if (status === escalaStatus.Prevista) return grey['A100']
    else if (status === escalaStatus.Ordenada) return yellow[200]
    else if (status === escalaStatus.Suspensa) return red[100]
    else if (status === escalaStatus.Executando) {
      if (diamesano === hoje) return green[500]
      else if (diamesano < hoje) return grey[400]
      else return orange[100]
    }
    else if (status === escalaStatus.Desativada) return red[200]
    else return blue[200]
  }
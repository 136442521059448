import { Fragment, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import dayjs from 'dayjs';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { campanhaTipo, leadOrigemStatus } from '../../../constants/crm';
import { DatePicker } from '@mui/x-date-pickers';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import LinkIcon from '@mui/icons-material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import { carregarBaseConhecimento, deletarCampanha, salvarCampanha } from '../../../controllers/crm/campanhaController';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QrCodeGenerator from './QrCode';

import 'dayjs/locale/pt-br.js';
import KnowledgeDetail from './Chatbot/KnowledgeDetail';
import { red, blue, green, grey } from '@mui/material/colors';
import { listenToCredito, stopListeningToCredito } from '../../../controllers/userController';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';

dayjs.locale('pt-br');

export default function CampanhaDetails({ user, setShowCampanhaDetails, showCampanhaDetails,
  campanha, campanhas, setCampanhas, setSnackbar }) {

  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [dtinicio, setDtInicio] = useState('');
  const [dtfim, setDtFim] = useState('');
  const [origem, setOrigem] = useState('');  // Initialize as empty string
  const [tipo, setTipo] = useState('');      // Initialize as empty string
  const [link, setLink] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showKnowledge, setShowKnowledge] = useState(false);
  const [openGIMO, setOpenGIMO] = useState(false);
  const [places, setPlaces] = useState([]);
  const [credito, setCredito] = useState(0);
  const [listenCredito, setListenCredito] = useState();
  const [consumo, setConsumo] = useState(0);
  const [knowledge, setKnowledge] = useState('');
  const [changeKnowledge, setChangeKnowledge] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);

  // função para copiar o link para a memória
  function copyToClipboard() {
    if (tipo === "ChatBot")
      navigator.clipboard.writeText(`${process.env.REACT_APP_CHATBOT_URL}/${link}`);
    else {
      navigator.clipboard.writeText(`${process.env.REACT_APP_LEADS_URL}/${link}`);
    }
    setSnackbar({ open: true, message: 'Link copiado para a memória', severity: 'success' });
  }

  function handleOpenGIMO() {
    setOpenGIMO(true);
  }

  function handleChangeTipo(e) {
    e.preventDefault();
    setTipo(e.target.value);
    if (e.target.value === "ChatBot") {
      setChangeKnowledge(true);
    }
  }

  function handleChangeOrigem(e) {
    e.preventDefault();
    setOrigem(e.target.value);

    if (link) {
      const linkArray = link.split('/');
      linkArray[linkArray.length - 1] = e.target.value;
      setLink(linkArray.join('/'));
    }
  }

  // Função para validar os campos
  function validateCampanha() {
    if (nome === '' || nome === null || nome === undefined) {
      setSnackbar({ open: true, message: 'Nome da campanha não pode ser vazio', severity: 'error' });
      return false;
    }
    if (dtinicio === '') {
      setSnackbar({ open: true, message: 'Data de início não pode ser vazio', severity: 'error' });
      return false;
    }
    if (dtfim === '') {
      setSnackbar({ open: true, message: 'Data de fim não pode ser vazio', severity: 'error' });
      return false;
    }
    if (origem === '' || origem === null || origem === undefined) {
      setSnackbar({ open: true, message: 'Canal não pode ser vazio', severity: 'error' });
      return false;
    }
    if (tipo === "ChatBot" && knowledge === '' && changeKnowledge) {
      setSnackbar({ open: true, message: 'Defina a Base de Conhecimento para o GIMO...', severity: 'error' });
      return false;
    }
    if (tipo === '' || tipo === null || tipo === undefined) {
      setSnackbar({ open: true, message: 'Tipo não pode ser vazio', severity: 'error' });
      return false;
    }
    return true;
  }

  // Função para salvar as alterações
  async function saveCampanha() {
    if (!validateCampanha()) return;
    setLoading(true);
    const idcampanha = campanha?.idcampanha ? campanha.idcampanha : null;
    const campanhaData = {
      idcampanha: idcampanha,
      nome: nome,
      idoperador: user.id,
      idconta: user.conta,
      dtinicio: dayjs(dtinicio).toISOString(),
      dtfim: dayjs(dtfim).toISOString(),
      origem: origem,
      tipo: tipo,
      link: link,
      idusuario: user.id,
      knowledge: knowledge,
      version: campanha?.version ? campanha.version : 0,
    };

    const result = await salvarCampanha(user, campanhaData);

    if (result.status === 200) {
      setChangeKnowledge(false);
      campanha.idcampanha = result.data.idcampanha;
      campanha.nome = result.data.nome;
      campanha.dtinicio = result.data.dtinicio;
      campanha.dtfim = result.data.dtfim;
      campanha.origem = result.data.origem;
      campanha.link = result.data.link;
      campanha.version = result.data.version;
      campanha.updatedat = result.data.updatedat;
      campanha.tipo = result.data.tipo;
      if (idcampanha === null) { // Insert
        campanhas.push(campanha);
      }
      setLink(result.data.link);
      setSnackbar({ open: true, message: 'Campanha salva com sucesso', severity: 'success' });
    } else if (result.status === 210) {
      setSnackbar({ open: true, message: 'Acesso concorrente, recarregue os dados.', severity: 'error' });
    }
    else {
      setSnackbar({ open: true, message: 'Erro ao salvar a campanha' + result.message, severity: 'error' });
    }
    setLoading(false);
  }

  // Deletar campanha
  async function deleteCampanha() {
    setLoading(true);
    const result = await deletarCampanha({ idcampanha: campanha.idcampanha, version: campanha.version });
    if (result.status === 200) {
      setSnackbar({ open: true, message: 'Campanha excluída com sucesso', severity: 'success' });
      const newCampanhas = campanhas.filter(camp => camp.idcampanha !== campanha.idcampanha);
      setCampanhas(newCampanhas);
      setShowCampanhaDetails(false);
    }
    else if (result.status === 210) {
      setSnackbar({ open: true, message: 'Acesso concorrente, recarregue os dados.', severity: 'error' });
    }
    else if (result.status === 220) {
      setSnackbar({ open: true, message: 'Campanha não pode ser excluída, pois possui leads associados.', severity: 'error' });
    }
    else {
      setSnackbar({ open: true, message: 'Erro ao salvar a campanha', severity: 'error' });
    }
    setLoading(false);
  }

  // Carregar a base de conhecimento
  async function loadBaseConhecimento(user, idcampanha) {
    const result = await carregarBaseConhecimento(user, idcampanha);
    if (result) {
      setKnowledge(result.know);
    }
  }

  useEffect(() => {
    if (tipo === "ChatBot") {
      setShowKnowledge(true);
    } else {
      setShowKnowledge(false);
    }
  }, [tipo]);

  const handleCreditoUpdate = (result) => {
    if (result.success) {
      setCredito(result.credito);
    } else {
      setSnackbar({ open: true, message: result.message, severity: 'error' });
    }
  };

  useEffect(() => {
    if (campanha) {
      setNome(campanha.nome || '');
      setDtInicio(campanha.dtinicio ? dayjs(campanha.dtinicio) : "");
      setDtFim(campanha.dtfim ? dayjs(campanha.dtfim) : "");
      setOrigem(campanha.origem || '');  // Add fallback value
      setLink(campanha.link || '');
      setConsumo(campanha.consumo || 0);
      setCredito(user.credito || 0);
      setTipo(campanha.tipo || '');      // Add fallback value
      if (campanha.tipo === campanhaTipo.ChatBot) {
        loadBaseConhecimento(user, campanha.idcampanha);
      }
    }
    // Chamando a função listenToCredito e passando a função de callback
    const subscription = listenToCredito(user, handleCreditoUpdate);
    return () => subscription();
  }, [campanha]);

  useEffect(() => {
    // Apply focus handling during loading state
    if (loading) {
      // Create a div to hold focus during loading
      const focusHolder = document.createElement('div');
      focusHolder.tabIndex = -1;
      document.body.appendChild(focusHolder);
      focusHolder.focus();

      return () => {
        // Clean up when loading is done
        document.body.removeChild(focusHolder);
      };
    }
  }, [loading]);

  const handleClose = () => {
    setShowCampanhaDetails(false);
  };

  return (
    <Fragment>
      <Dialog
        open={showCampanhaDetails}
        onClose={!loading ? handleClose : undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        disableEscapeKeyDown={loading}
        keepMounted={loading} // Keep dialog mounted during loading
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          DETALHES DA CAMPANHA
        </DialogTitle>

        <DialogContent
          sx={{ mt: 2, pt: 3, pb: 1, px: 3 }}
          // Add inert attribute conditionally
          {...(loading ? { inert: 'true' } : {})}
        >
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255,255,255,0.7)'
              }}
              role="status"
              aria-label="Loading"
            >
              <CircularProgress size={60} />
            </Box>
          )}

          <Grid container spacing={2} maxWidth={600} py={1}>
            {/* Nome da Campanha */}
            <Grid item xs={12}>
              <TextFieldMask
                fullWidth
                variant='outlined'
                size='small'
                id="nome"
                name='nome'
                label="Nome da Campanha"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                mask={'text'}
              />
            </Grid>

            {/* Data Início e Fim */}
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Data de Início"
                value={dtinicio}
                onChange={(date) => setDtInicio(date)}
                format='DD/MM/YYYY'
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Data de Término"
                value={dtfim}
                onChange={(date) => setDtFim(date)}
                format='DD/MM/YYYY'
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </Grid>

            {/* Canal e Tipo */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="lbOrigem">Canal</InputLabel>
                <Select
                  labelId="lbOrigem"
                  id="origem"
                  value={origem || ''}  // Add fallback value
                  onChange={handleChangeOrigem}
                  label="Canal"
                  sx={{ height: '40px' }}
                >
                  <MenuItem value=""><em>Nenhum</em></MenuItem>
                  {leadOrigemStatus && Object.keys(leadOrigemStatus).map((key) => (
                    key !== 'Description' &&
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="lbTipo">Tipo</InputLabel>
                <Select
                  labelId="lbTipo"
                  id="tipo"
                  value={tipo || ''}  // Add fallback value
                  onChange={handleChangeTipo}
                  label="Tipo"
                  sx={{ height: '40px' }}
                >
                  <MenuItem value=""><em>Nenhum</em></MenuItem>
                  {campanhaTipo && Object.keys(campanhaTipo).map((key) => (
                    key !== 'Description' &&
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Botão GIMO - IA */}
            {showKnowledge && (
              <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Button
                    disabled={loading}
                    color={knowledge ? 'success' : 'primary'}
                    fullWidth
                    variant='contained'
                    onClick={handleOpenGIMO}
                    sx={{
                      py: 1.2,
                      fontWeight: 'bold',
                      boxShadow: 3
                    }}
                  >
                    GIMO - Inteligência Artificial
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={1}
                    sx={{
                      p: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      bgcolor: grey[100]
                    }}
                  >
                    <Typography variant="body2" fontWeight="medium">
                      Consumo de Tokens:
                    </Typography>
                    <Chip
                      label={consumo}
                      color="primary"
                      size="small"
                      sx={{ fontWeight: 'bold' }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {/* Link para copiar */}
            {campanha.link && (
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold"
                    sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <LinkIcon color="primary" /> Link da Campanha
                  </Typography>
                  <Paper
                    elevation={1}
                    sx={{
                      p: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: blue[50],
                      border: `1px solid ${blue[200]}`,
                      borderRadius: 1                      
                    }}
                  >
                    <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: 'monospace',
                          wordBreak: 'break-all'
                        }}
                      >
                        {tipo === "ChatBot"
                          ? `${process.env.REACT_APP_CHATBOT_URL}/${link}`
                          : `${process.env.REACT_APP_LEADS_URL}/${link}`}
                      </Typography>
                    </Box>
                    <Stack direction="row" >
                      <IconButton
                        onClick={copyToClipboard}
                        color="primary"
                        sx={{ ml: 0.5 }}
                        title="Copiar link"
                      >
                        <ContentCopyTwoToneIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setShowQrCode(true)}
                        color="primary"
                        sx={{ ml: 1 }}
                        title="QrCode"
                      >
                        <QrCode2Icon />
                      </IconButton>
                      <IconButton
                        onClick={() => window.open(tipo === "ChatBot"
                          ? `${process.env.REACT_APP_CHATBOT_URL}/${link}`
                          : `${process.env.REACT_APP_LEADS_URL}/${link}`, '_blank')}
                        color="primary"
                        sx={{ ml: 1 }}
                        title="Abrir link"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Stack>
                  </Paper>
                </Box>
              </Grid>
            )}

            {/* Informações da Campanha */}
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Stack spacing={1.5} sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PeopleIcon fontSize="small" sx={{ color: green[700] }} />
                  <Typography variant="body1">
                    <strong>Total de Leads:</strong> {campanha.qtd || 0}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon fontSize="small" sx={{ color: blue[700] }} />
                  <Typography variant="body1">
                    <strong>Cadastrado em:</strong> {dayjs(campanha.createdat).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ px: 3, py: 2, bgcolor: grey[100] }}
          // Add inert attribute conditionally
          {...(loading ? { inert: 'true' } : {})}
        >
          {!showDelete ? (
            <>
              <Button
                variant="outlined"
                size='small'
                color="error"
                disabled={loading}
                onClick={() => setShowDelete(true)}
                startIcon={<DeleteIcon />}
              >
                Excluir
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                sx={{
                  ml: 1,
                  bgcolor: changeKnowledge ? red[700] : undefined,
                  '&:hover': {
                    bgcolor: changeKnowledge ? red[800] : undefined,
                  }
                }}
                size='small'
                disabled={loading}
                onClick={saveCampanha}
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Gravar
              </Button>
              <Button
                disabled={loading}
                onClick={handleClose}
                variant="outlined"
                size='small'
              >
                Fechar
              </Button>
            </>
          ) : (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              bgcolor: red[50],
              p: 2,
              borderRadius: 1
            }}>
              <WarningIcon color="error" fontSize="large" />
              <Typography variant="subtitle1" color="error" fontWeight="bold" sx={{ flexGrow: 1 }}>
                Confirma a exclusão desta campanha?
              </Typography>
              <Button
                variant="contained"
                color="error"
                disabled={loading}
                onClick={deleteCampanha}
                size='small'
              >
                Sim, excluir
              </Button>
              <Button
                variant="outlined"
                disabled={loading}
                onClick={() => setShowDelete(false)}
                size='small'
              >
                Cancelar
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>

      {/* QR Code Dialog */}
      <Dialog
        open={showQrCode && Boolean(link)}  // Convert to boolean to ensure proper prop type
        onClose={() => setShowQrCode(false)}
        aria-labelledby="qrcode-dialog-title"
        aria-describedby="qrcode-dialog-description"
        maxWidth="sm"
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2, p: 2 }
        }}
      >
        <DialogTitle
          id="qrcode-dialog-title"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          QR Code da Campanha
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 3 }}>
          <Box
            sx={{ textAlign: 'center' }}
            id="qrcode-dialog-description"
          >
            {link && (
              <QrCodeGenerator
                initialUrl={tipo === "ChatBot"
                  ? `${process.env.REACT_APP_CHATBOT_URL}/${link}`
                  : `${process.env.REACT_APP_LEADS_URL}/${link}`}
              />
            )}
            <Typography variant="caption" display="block" sx={{ mt: 2, color: grey[700] }}>
              Escaneie o QR Code com a câmera do celular
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowQrCode(false)} variant="contained" fullWidth size='small'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {openGIMO && (
        <KnowledgeDetail
          user={user}
          places={places}
          setPlaces={setPlaces}
          open={openGIMO}
          handleClose={() => setOpenGIMO(false)}
          setSnackbar={setSnackbar}
          Knowledge={knowledge}
          setKnowledge={setKnowledge}
          setChangeKnowledge={setChangeKnowledge}
        />
      )}
    </Fragment>
  );
}

import { 
  Box, Stack, TextField, IconButton, Typography, 
  useMediaQuery, useTheme, Paper, Fade, CircularProgress, Grid, Fab, Snackbar, Alert
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { listarCampanhas } from '../../../controllers/crm/campanhaController';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CampanhaDetails from './CampanhaDetails';
import { motion } from 'framer-motion';

export function CampanhaSelect({ user, setSnackbar, loading, setLoading }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filteredCampanhas, setFilteredCampanhas] = useState([])
  const [campanhas, setCampanhas] = useState([])
  const [select, setSelect] = useState({})
  const [filter, setFilter] = useState('')
  const [showCampanhaDetails, setShowCampanhaDetails] = useState(false)


  async function loadMyCampanhas() {
    setLoading(true)
    const params = { conta: user.conta, id: user.id }
    const result = await listarCampanhas(params)
    if (result) {
      setCampanhas(result.data)
      setFilteredCampanhas(result.data)
      setSnackbar({ open: true, message: 'Campanhas carregadas com sucesso', severity: 'success' })
    }
    else {
      setSnackbar({ open: true, message: 'Erro ao carregar campanhas', severity: 'error' })
    }
    setLoading(false)
  }

  useEffect(() => {
    loadMyCampanhas()
  }, [])

  useEffect(() => {
    setFilteredCampanhas(campanhas)
  }, [campanhas])

  useEffect(() => {
    if (filter === '') {
      setFilteredCampanhas(campanhas)
    } else {
      const result = campanhas.filter(campanha => campanha.nome.toLowerCase().includes(filter.toLowerCase()))
      setFilteredCampanhas(result)
    }
  }, [filter])

  function insertCampanha() {
    const campanha = {
      idconta: user.conta,
      idoperador: user.id,
      idusuario: user.id,
      origem: '',
      nome: '',
      qtd: 0,
    }
    setSelect(campanha)
    setShowCampanhaDetails(!showCampanhaDetails)
  }

  return (
    <Box
      sx={{
        width: '95%',
        height: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        bgcolor: 'background.default',
        borderRadius: 2,
      }}
    >
      {/* Loading Overlay */}
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(4px)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}

      {/* Header and Search Bar */}
      <Stack
        spacing={2}
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          boxShadow: 1,
          position: 'sticky',
          top: 0,
          zIndex: 1000
        }}
      >
        <Typography 
          variant="h3" 
          fontWeight="bold"
          color="primary"
          textAlign="left"
        >
          Campanhas
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            width: '100%',
          }}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Buscar campanha..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
            }}
            sx={{ bgcolor: 'background.paper' }}
          />
          <IconButton
            onClick={loadMyCampanhas}
            disabled={loading}
            color="primary"
            sx={{
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ReplayTwoToneIcon />
          </IconButton>
        </Stack>
      </Stack>

      {/* Campaigns List */}
      <Box sx={{
        flex: 1,
        bgcolor: 'grey.100',
        overflow: 'auto',
        p: 2
      }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            justifyContent: { xs: 'center', sm: 'flex-start' }
          }}
        >
          {filteredCampanhas.map((campanha, index) => (
            <Grid
              item
              maxWidth={isMobile ? '100%' : '280px'}
              key={campanha.idcampanha}
            >
              <Fade in style={{ transitionDelay: `${index * 50}ms` }}>
                <Paper
                  component={motion.div}
                  whileHover={{ scale: 1.02 }}
                  onClick={() => {
                    setSelect(campanha);
                    setShowCampanhaDetails(true);
                  }}
                  elevation={3}
                  sx={{
                    p: 2,
                    height: '100%',
                    cursor: 'pointer',
                    transition: 'all 0.3s',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      boxShadow: 6
                    }
                  }}
                >
                  <Stack spacing={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6" color="primary" fontWeight="bold">
                        {campanha.nome}
                      </Typography>
                    </Box>

                    <Stack spacing={1}>
                      <Typography variant="body2" color="text.secondary">
                        Início: {dayjs(campanha.dtinicio).format('DD/MM/YYYY')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Fim: {dayjs(campanha.dtfim).format('DD/MM/YYYY')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Leads: {campanha.qtd}
                      </Typography>
                      <Typography 
                        variant="caption"
                        sx={{ 
                          color: 'text.secondary',
                          wordBreak: 'break-all',
                          mt: 1
                        }}
                      >
                        {campanha.tipo === 'ChatBot' 
                          ? `${process.env.REACT_APP_CHATBOT_URL}/${campanha.link}`
                          : `${process.env.REACT_APP_LEADS_URL}/${campanha.link}`
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* FAB Button */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={insertCampanha}
        sx={{
          position: 'absolute',
          right: isMobile ? '16px' : '32px',
          bottom: isMobile ? '16px' : '32px',
          zIndex: 1000
        }}
      >
        <AddIcon />
      </Fab>

      {/* Details Dialog */}
      {showCampanhaDetails && (
        <CampanhaDetails
          showCampanhaDetails={showCampanhaDetails}
          setShowCampanhaDetails={setShowCampanhaDetails}
          user={user}
          campanha={select}
          campanhas={campanhas}
          setCampanhas={setCampanhas}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}
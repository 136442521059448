// Add this at the top with other imports
import notification from './notification_01.mp3';

// Create audio instance outside component to avoid recreation
const notificationSound = new Audio(notification);

// Play sound with error handling and user interaction check
export const playSound = async () => {
  try {
    await notificationSound.play();
  } catch (error) {
    if (error.name === 'NotAllowedError') {
      console.log('Audio playback requires user interaction first');
    } else {
      console.error('Error playing notification:', error);
    }
  }
};


import { useEffect, useState } from 'react';
import { 
  Box, Stack, Typography, FormControl, TextField, 
  InputLabel, MenuItem, Select, Paper,
  useTheme, useMediaQuery 
} from '@mui/material';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { Meses } from '../../../utils/dados/DadosMeses';
import { Anos } from '../../../utils/dados/DadosAnos';
import { motion } from 'framer-motion';

export const PolVendas = ({
  setPlaceEdit,
  placeEdit,
  setNewPlace,
  newPlace 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [politicaVendas, setPoliticaVendas] = useState({ ...placeEdit });

  useEffect(() => {
    setPlaceEdit(politicaVendas);
  }, [politicaVendas]);

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{
        width: '100%',
        p: 0
      }}
    >
      <Stack spacing={3}>
        {/* Valores Section */}
        <Paper 
          elevation={2}
          sx={{ p: 2, borderRadius: 2 }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" color="primary" fontWeight="medium">
              Valores e Referências
            </Typography>
            
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2}
              alignItems="flex-start"
            >
              <TextFieldMask
                fullWidth
                label="Renda Mínima"
                value={politicaVendas?.renda || ''}
                onChange={(e) => setPoliticaVendas({ ...politicaVendas, renda: e.target.value })}
                onBlur={(e) => setPoliticaVendas({ ...politicaVendas, renda: e.target.value })}
                mask="currency"
                moeda="R$"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />

              <TextFieldMask
                fullWidth
                label="Imóvel Menor Valor"
                value={politicaVendas?.vlrinicial || ''}
                onChange={(e) => setPoliticaVendas({ ...politicaVendas, vlrinicial: e.target.value })}
                onBlur={(e) => setPoliticaVendas({ ...politicaVendas, vlrinicial: e.target.value })}
                mask="currency"
                moeda="R$"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />

              <TextFieldMask
                fullWidth
                label="Imóvel Maior Valor"
                value={politicaVendas?.vlrfinal || ''}
                onChange={(e) => setPoliticaVendas({ ...politicaVendas, vlrfinal: e.target.value })}
                onBlur={(e) => setPoliticaVendas({ ...politicaVendas, vlrfinal: e.target.value })}
                mask="currency"
                moeda="R$"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </Stack>

            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2}
              alignItems="flex-start"
            >
              <FormControl sx={{ minWidth: { xs: '100%', sm: '200px' } }}>
                <InputLabel>Mês Referência</InputLabel>
                <Select
                  value={politicaVendas?.refvlrmes || ''}
                  onChange={(e) => setPoliticaVendas({ ...politicaVendas, refvlrmes: e.target.value })}
                  label="Mês Referência"
                  sx={{ borderRadius: 2,height: '55px' }}
                >
                  <MenuItem value=""><em>Selecione</em></MenuItem>
                  {Meses.map((m) => (
                    <MenuItem key={m.mes} value={m.mes}>{m.sigla}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextFieldMask
                label="Ano Referência"
                value={politicaVendas?.refvlrano || ''}
                onChange={(e) => setPoliticaVendas({ ...politicaVendas, refvlrano: e.target.value })}
                mask="number"
                sx={{
                  minWidth: { xs: '100%', sm: '150px' },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
                inputProps={{ maxLength: 4 }}
              />
            </Stack>
          </Stack>
        </Paper>

        {/* Promoções Section */}
        <Paper 
          elevation={2}
          sx={{ p: 2, borderRadius: 2 }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" color="primary" fontWeight="medium">
              Bônus e Promoções
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={9}
              label="Detalhes das promoções"
              value={politicaVendas?.promocao || ''}
              onChange={(e) => setPoliticaVendas({ ...politicaVendas, promocao: e.target.value })}
              helperText="Descreva as bonificações e promoções para corretores e clientes"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
              inputProps={{ maxLength: 1000 }}
            />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
};
import { useEffect, useState } from 'react';
import {
  Button, Checkbox, Grid, IconButton,
  Link, MenuItem, Select, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextFieldMask } from '../../utils/TextfieldMask/TextfieldMask';
import { getAllUserContas, getUserByEmail, gravarUser } from '../../controllers/userController';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { leaveTheTeam } from '../../controllers/equipeController';
import { arrayDelete } from '../../utils/arrayDelete';
import cep from 'cep-promise';
import { serverTimestamp } from 'firebase/firestore';
import { result } from '../../utils/result';

import { UF } from '../../utils/dados/DadosUF';
import { validarCPF } from '../../utils/TextfieldMask/validarCPF';
import { validarCNPJ } from '../../utils/TextfieldMask/validarCPNJ';
import { MySnackBar } from '../snackbar/Snackbar';
import { deleteConta } from '../../controllers/contaController';
import { useNavigate } from 'react-router-dom';
import { getOperador, setOperador, updateOperador } from '../../controllers/crm/operadorController';
import { operadorStatus } from '../../constants/crm';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Paper, FormControl, InputLabel, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';


export default function Perfil({ user, openPerfil, setOpenPerfil, setUser, showMySnackbar }) {
  const theme = useTheme();

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Perfil', user)

  const [openExcluirTeam, setOpenExcluirTeam] = useState(false)
  const [msg, setMsg] = useState('teste')
  const [openSnack, setOpenSnack] = useState(false)
  const [severity, setSeverity] = useState('info') // info error warning success
  const [openEncerrarConta, setOpenEncerrarConta] = useState(false)

  // Estrutra da Tabela de Dados
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([])
  const columns = [
    { id: 'equipe', label: 'Equipe', minwidth: 170 },
    { id: 'email', label: 'Email', minwidth: 170 },
  ];
  function createData(id, equipe, email) {
    return { id, equipe, email };
  }
  // Tabela de Dados
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Tratamento da Dialog
  const handleClose = () => {
    setOpenPerfil(false);
  };


  const [usuario, setUsuario] = useState({})
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [isPF, setIsPF] = useState(false)
  const [tpPessoa, setTpPessoa] = useState('PF')
  const [cpfcnpj, setCPFCNPJ] = useState('')
  const [nrCRECI, setNrCRECI] = useState('')
  const [ufCRECI, setUFCRECI] = useState('')
  const [telefone, setTelefone] = useState('')
  const [isWhatsApp, setIsWhatsApp] = useState(false)
  const [isSMS, setIsSMS] = useState(false)
  const [isTelVerified, setisTelVerified] = useState(false)
  const [nrCEP, setNrCEP] = useState('')
  const [nrImovel, setNrImovel] = useState('')
  const [complemento, setComplemento] = useState('')
  const [rua, setRua] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [ufEnd, setUFEnd] = useState('')
  const [erroCEP, setErroCEP] = useState(false)
  const [loading, setLoading] = useState(false)




  async function loadPerfil() {
    setLoading(true)
    const _user = await getUserByEmail(user.email)
    if (_user) {
      if (_user.length > 0) {
        setUsuario(_user[0])
        setNome(_user[0].nome)
        setEmail(_user[0].email)
        setCPFCNPJ(_user[0].cpfcnpj ? _user[0].cpfcnpj : '')
        setTpPessoa(_user[0].tppfpj.toUpperCase())
        if (_user[0].tppfpj.toUpperCase() === 'PF') setIsPF(true)
        else setIsPF(false)
        setNrCRECI(_user[0].nrcreci)
        setUFCRECI(_user[0].ufcreci)
        setTelefone(_user[0].telefone ? _user[0].telefone : '')
        setIsWhatsApp(_user[0].iswhatsapp ? _user[0].iswhatsapp : false)
        setIsSMS(_user[0].issms ? _user[0].issms : false)
        setisTelVerified(_user[0].istelverified ? _user[0].istelverified : false)
        setNrCEP(_user[0].cep ? _user[0].cep : '')
        setRua(_user[0].logradouro ? _user[0].logradouro : '')
        setNrImovel(_user[0].nrimovel ? _user[0].nrimovel : '')
        setComplemento(_user[0].complemento ? _user[0].complemento : '')
        setBairro(_user[0].bairro ? _user[0].bairro : '')
        setUFEnd(_user[0].ufend ? _user[0].ufend : '')
        setCidade(_user[0].municipio ? _user[0].municipio : '')
      }
    }
    setLoading(false)
  }

  async function loadEquipes() {
    let cta = []
    let rst = await getAllUserContas(user.contas)
    if (rst.sucess) {
      if (rst.data.length > 0) {
        rst.data.forEach(element => {
          if (user.id !== element.id)
            cta.push(createData(element.id, element.equipe, element.email))
        });
        setRows(cta)
      }
    }
  }

  const [isloadingCEP, setIsLoadingCEP] = useState(false)
  async function buscaEndereco(event) {
    let _cep = event.target.value
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(_cep)
    if (_cep.length >= 8) {
      setIsLoadingCEP(true)
      cep(_cep).then((_c) => {
        setRua(_c.street)
        setBairro(_c.neighborhood)
        setCidade(_c.city)
        setUFEnd(_c.state)
        setErroCEP(false)
        setIsLoadingCEP(false)
      }).catch((e) => {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(e)
        setRua('')
        setBairro('')
        setCidade('')
        setUFEnd('')
        setErroCEP(true)
        setIsLoadingCEP(false)
      })
    }
    else {
      setRua('')
      setBairro('')
      setCidade('')
      setUFEnd('')
      setErroCEP(true)
    }
  }

  const [selectConta, setSelectConta] = useState([])
  function handleSelectConta(_conta) {
    setSelectConta(_conta)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('click', _conta, rows)
    setOpenExcluirTeam(true)
  }

  async function excluirTeam() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Excluir', selectConta, user)
    if (selectConta.id !== user.conta) {
      const rst = await leaveTheTeam(selectConta.id, user.id)
      if (rst.sucess) {
        let _a = arrayDelete(rows, selectConta)
        if (_a) { setRows(_a) }
        let cts = user.contas
        let _b = arrayDelete(cts, selectConta.id)
        if (_b) {
          let _u = user
          _u.contas = _b
          setUser(_u)
        }
        setOpenExcluirTeam(false)
        setMsg(rst.msg)
        setSeverity('success')
        setOpenSnack(true)
      }
      else {
        setOpenSnack(true)
        setMsg(rst.msg)
        setSeverity('error')
      }
    }
    else {
      setMsg('Não é possivel sair da equipe estando logado nela!')
      setSeverity('error')
      setOpenSnack(true)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Não é possivel excluir a equipe estando logado nela!')
    }
  }

  function handleCloseExc() {
    setOpenExcluirTeam(false)
    setOpenSnack(false)
    setMsg()
  }



  function handleSelectTpPessoa(event) {
    if (event.target.value === 'PF') {
      setIsPF(true)
      setTpPessoa('PF')
    }
    else {
      setIsPF(false)
      setTpPessoa('PJ')
    }
    setCPFCNPJ('')
  }

  const [hasErroNome, setHasErroNome] = useState(false)
  const [hasErroNrCreci, setHasErroNrCreci] = useState(false)

  function handleNome(e) {
    if (e.target.value.length < 5) setHasErroNome(true)
    else setHasErroNome(false)
    setNome(e.target.value)
  }

  const [hasErroUFCRECI, setHasErroUFCRECI] = useState(false)

  function handleNrCRECI(e) {
    if (e.target.value.length <= 5) setHasErroNrCreci(true)
    else setHasErroNrCreci(false)
    setNrCRECI(e.target.value.toUpperCase())
  }

  function handleUFCRECI(e) {
    if (e.target.value.length <= 1) setHasErroUFCRECI(true)
    else setHasErroUFCRECI(false)
    setUFCRECI(e.target.value)
  }

  const [isValidCPFCNPJ, setIsValidCPFCNPJ] = useState(true)
  function validarCPFCNPJ(e) {
    if (isPF) {
      if (validarCPF(e.target.value)) {
        setIsValidCPFCNPJ(true)
        setCPFCNPJ(e.target.value)
      }
      else {
        setIsValidCPFCNPJ(false)
      }
    }
    else {

      if (validarCNPJ(e.target.value)) {
        setIsValidCPFCNPJ(true)
        setCPFCNPJ(e.target.value)
      }
      else {
        setIsValidCPFCNPJ(false)
      }
    }
  }
  const nav = useNavigate()
  async function EncerrarConta() {

    const rst = await deleteConta(user)
    if (rst.sucess) {
      setOpenEncerrarConta(false)
      setOpenPerfil(false)
      nav('/singin', { replace: true })
    }
    else {
      setOpenEncerrarConta(false)
      showMySnackbar('Falha no encerramento da conta', 4000, 'error')
    }
  }

  async function gravarPerfil() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('entrou em gravarPerfil')
    function validar() {
      result.sucess = true
      result.msg = 'Perfil validado...'
      result.data = null
      let erro = 0
      if (hasErroNome) erro++
      if (hasErroNrCreci) erro++
      if (hasErroUFCRECI) erro++
      if (!isValidCPFCNPJ) erro++
      if (erroCEP) erro++
      if (erro > 0) {
        result.sucess = false
        result.msg = 'Dados inválidos...'
      }
      return result
    }

    if (validar().sucess) {
      const _user = {
        // Não atualiza
        ativo: true,
        email: usuario.email,
        conta: usuario.conta,
        convite: usuario.convite,
        createdat: usuario.createdat,
        id: usuario.id,
        permissions: usuario.permissions,
        rules: usuario.rules,
        photourl: usuario.photourl,
        plano: usuario.plano,

        // Atualiza
        nome: nome,
        tppfpj: tpPessoa.toUpperCase(),
        nrcreci: nrCRECI,
        ufcreci: ufCRECI,

        // Novos campos
        cpfcnpj: cpfcnpj,
        telefone: telefone,
        iswhatsapp: isWhatsApp,
        issms: isSMS,
        cep: nrCEP,
        logradouro: rua,
        nrimovel: nrImovel,
        complemento: complemento,
        bairro: bairro,
        ufend: ufEnd,
        municipio: cidade,
        updatedat: serverTimestamp()
      }
      let rst = await gravarUser(_user)
      setMsg(rst.msg)
      setSeverity(rst.sucess ? 'success' : 'error')
      setOpenSnack(true)

      // Atualizar o usuário no CRM
      if (user.crm) {
        if (user.nome !== nome || user.telefone !== telefone) {
          const dados = {
            idconta: user.conta,
            idoperador: user.id,
            nome: nome,
            telefone: telefone,
          }
          const response = await updateOperador(dados)
          if (response.status === 200) {
            setHasOperadorCRM(true)
          }
          else {
            console.log(response)
            setMsg('Falha ao atualizar o Operador CRM')
            setSeverity('error')
            setOpenSnack(true)
          }
        }
      }
    }
    else {
      setMsg(result.msg)
      setSeverity('error')
      setOpenSnack(true)
    }
  }

  function validaOperadorCRM() {
    if (user.hascrm) {
      if (nome === '') {
        setMsg('Informe o Nome e o Telefone para ativar o CRM')
        setSeverity('error')
        setOpenSnack(true)
        return false
      }
      if (telefone === '') {
        setMsg('Informe o Telefone com Whatsapp para ativar o CRM')
        setSeverity('error')
        setOpenSnack(true)
        return false
      }
      if (nrCRECI === '') {
        setMsg("Informe o nr do CRECI para ativar o CRM")
        setSeverity('error')
        setOpenSnack(true)
        return false
      }
      return true
    }
  }

  async function criarOperadorCRM() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Criar Operador CRM', user)
    const dados = {
      idconta: user.conta,
      idoperador: user.id,
      nome: nome,
      telefone: telefone,
      status: operadorStatus.Disponivel,
      email: user.email
    }

    // todo: Verificar se o que foi retirado da equipe e existe no CRM
    // Verificar em Client se atributo crm é true or false

    if (!user.crm && user.hascrm) {
      if (validaOperadorCRM()) {
        // Inserir operador no CRM
        const response = await setOperador(dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Response', response)
        if (response.status === 200) {
          setHasOperadorCRM(true)
          setMsg('Operador CRM ativado com sucesso!')
          setSeverity('success')
          setOpenSnack(true)
          // Atualizar Nome e Telefone no firebase
          if (user.nome !== dados.nome || user.telefone !== dados.telefone) {
            user.nome = dados.nome
            user.telefone = dados.telefone
            gravarPerfil()
          }
          user.crm = true
        }
        else {
          console.log(response)
          setMsg('Falha ao ativar o Operador CRM')
          setSeverity('error')
          setOpenSnack(true)
        }
      }
    }
  }

  async function consultaOperadorCRM() {
    const query = {
      where: {
        idconta: user.conta,
        idoperador: user.id
      }
    }
    try {
      const operador = await getOperador(query)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Operador CRM', operador)
      if (operador.data.length > 0) {
        setHasOperadorCRM(true)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Operador CRM', operador.data[0])
      } else {
        setHasOperadorCRM(false)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Operador CRM não localizado')
      }
    }
    catch (e) {
      setMsg('Falha ao consultar o Operador CRM')
      setSeverity('error')
      setOpenSnack(true)
    }
  }



  const [hasOperadorCRM, setHasOperadorCRM] = useState(false)

  useEffect(() => {
    loadPerfil()
    loadEquipes()
    consultaOperadorCRM()
  }, [])

  return (
    <>
      <Dialog
        open={openPerfil}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          component: motion.div,
          initial: { opacity: 0, y: 20 },
          animate: { opacity: 1, y: 0 },
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        <Box sx={{
          p: 2,
          bgcolor: theme.palette.mode === 'light'
            ? theme.palette.primary.light
            : theme.palette.primary.dark,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h3"
              color={theme.palette.mode === 'light'
                ? theme.palette.primary.dark
                : theme.palette.primary.contrastText
              }
              fontWeight="medium"
            >
              Perfil do Usuário
            </Typography>
            {(user.hascrm && !hasOperadorCRM) && (
              <Button
                variant="contained"
                disabled={loading}
                onClick={criarOperadorCRM}
                startIcon={<AddCircleIcon />}
                color="error"
                size="small"
              >
                Ativar CRM
              </Button>
            )}
          </Stack>
        </Box>

        <DialogContent>
          <Stack spacing={3}>
            {/* Personal Information */}
            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
              <Stack spacing={2}>
                <Typography variant="h6" color="primary" fontWeight="medium">
                  Informações Pessoais
                </Typography>
                <TextField
                  fullWidth
                  required
                  label="Nome"
                  value={nome}
                  error={hasErroNome}
                  onChange={handleNome}
                  inputProps={{ maxLength: 70 }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2
                    }
                  }}
                />

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                >
                  <FormControl sx={{ minWidth: 140 }}>
                    <InputLabel>Tipo de Pessoa</InputLabel>
                    <Select
                      value={tpPessoa}
                      onChange={handleSelectTpPessoa}
                      label="Tipo de Pessoa"
                      sx={{
                        height: '55px',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    >
                      <MenuItem value="PF">Física</MenuItem>
                      <MenuItem value="PJ">Jurídica</MenuItem>
                    </Select>
                  </FormControl>

                  <TextFieldMask
                    fullWidth
                    required
                    label={isPF ? "CPF" : "CNPJ"}
                    mask={isPF ? 'cpf' : 'cnpj'}
                    value={cpfcnpj}
                    onBlur={validarCPFCNPJ}
                    onChange={(e) => setCPFCNPJ(e.target.value)}
                    error={!isValidCPFCNPJ}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Email"
                    value={email}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={user.emailVerified}
                      />
                    }
                    label="Email verificado"
                  />
                </Stack>
              </Stack>
            </Paper>

            {/* Professional Information */}
            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
              <Stack spacing={2}>
                <Typography variant="h6" color="primary" fontWeight="medium">
                  Informações Profissionais
                </Typography>

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                >
                  <TextFieldMask
                    required
                    label="CRECI"
                    value={nrCRECI}
                    error={hasErroNrCreci}
                    onChange={handleNrCRECI}
                    mask="nrcreci"
                    sx={{
                      minWidth: { xs: '100%', sm: '200px' },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }}
                  />

                  <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>UF CRECI</InputLabel>
                    <Select
                      value={ufCRECI}
                      onChange={handleUFCRECI}
                      error={hasErroUFCRECI}
                      label="UF CRECI"
                      sx={{
                        height: '55px',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    >
                      <MenuItem value=""><em>Selecione</em></MenuItem>
                      {UF.map((_uf) => (
                        <MenuItem key={_uf.sigla} value={_uf.sigla}>
                          {_uf.sigla}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                {/* Contact Information */}
                <Stack spacing={2}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                    spacing={2}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                  >
                    <TextFieldMask
                      label="Telefone"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                      mask="phone"
                      sx={{
                        minWidth: { xs: '100%', sm: '200px' },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isWhatsApp}
                          onChange={(e) => setIsWhatsApp(e.target.checked)}
                        />
                      }
                      label="WhatsApp"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSMS}
                          onChange={(e) => setIsSMS(e.target.checked)}
                        />
                      }
                      label="SMS"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={isTelVerified}
                        />
                      }
                      label="Telefone verificado"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Paper>

            {/* Address Information */}
            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
              <Stack spacing={2}>
                <Typography variant="h6" color="primary" fontWeight="medium">
                  Endereço
                </Typography>

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  alignItems="flex-start"
                >
                  <TextFieldMask
                    required
                    label="CEP"
                    value={nrCEP}
                    onChange={(e) => setNrCEP(e.target.value)}
                    onInput={() => setErroCEP(false)}
                    onBlur={buscaEndereco}
                    mask="cep"
                    error={erroCEP}
                    sx={{
                      width: { xs: '100%', sm: '200px' },
                      bgcolor: isloadingCEP ? 'warning.light' : 'transparent',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }}
                  />

                  <Link
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    variant="body2"
                    sx={{ mt: 1 }}
                  >
                    Consultar CEP
                  </Link>
                </Stack>

                {/* Address fields */}
                <Stack spacing={2}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                  >
                    <TextField
                      fullWidth
                      label="Logradouro"
                      value={rua}
                      onChange={(e) => setRua(e.target.value)}
                      disabled={!erroCEP}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    />

                    <TextField
                      label="Número"
                      value={nrImovel}
                      onChange={(e) => setNrImovel(e.target.value)}
                      sx={{
                        width: { xs: '100%', sm: '150px' },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    label="Complemento"
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }}
                  />

                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                  >
                    <TextField
                      fullWidth
                      label="Bairro"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      disabled={!erroCEP}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    />

                    <TextField
                      label="UF"
                      value={ufEnd}
                      onChange={(e) => setUFEnd(e.target.value)}
                      disabled={!erroCEP}
                      sx={{
                        width: { xs: '100%', sm: '100px' },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    />

                    <TextField
                      fullWidth
                      label="Cidade"
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                      disabled={!erroCEP}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Paper>

            {/* Teams Table Section */}
            {rows.length > 0 && (
              <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                <Stack spacing={2}>
                  <Typography variant="h6" color={theme.palette.text.primary} fontWeight="medium">
                    Equipes
                  </Typography>

                  <TableContainer sx={{ maxHeight: 300 }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow >
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ bgcolor:theme.palette.primary.light }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          <TableCell sx={{ bgcolor:theme.palette.primary.light }} align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <TableRow
                              hover
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 }
                              }}
                            >
                              {columns.map((column) => (
                                <TableCell key={column.id}>
                                  {row[column.id]}
                                </TableCell>
                              ))}
                              <TableCell align="right">
                                <IconButton
                                  size="small"
                                  onClick={() => handleSelectConta(row)}
                                  color="error"
                                >
                                  <DeleteTwoToneIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                  />
                </Stack>
              </Paper>
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
          bgcolor: theme.palette.mode === 'light'
            ? theme.palette.grey[50]
            : theme.palette.grey[900]
        }}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpenEncerrarConta(true)}
            startIcon={<DeleteIcon />}
            size='small'
          >
            Encerrar Conta
          </Button>
          <Box sx={{ flex: 1 }} />
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={gravarPerfil}
              loading={isloadingCEP}
              startIcon={<SaveIcon />}
              size='small'
            >
              Salvar
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={handleClose}
              startIcon={<CloseIcon />}
              size='small'
            >
              Fechar
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Mensagem - Snackbar */}
      <MySnackBar
        msg={msg}
        open={openSnack}
        tmp={6000}
        severity={severity}
        setOpenSnack={setOpenSnack} />

      {/* Dialog Retirar Usuário da Equipe */}
      <Dialog
        open={openExcluirTeam}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sair da equipe"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              {selectConta.equipe} ({selectConta?.email}) ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={excluirTeam} autoFocus>Sim</Button>
          <Button onClick={handleCloseExc}>Não</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog Encerrar Conta */}
      <Dialog
        open={openEncerrarConta}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Encerrar Conta"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              Atenção!<br /><br /> Todos os dados vinculados a esta conta serão excluidos do aplicativo GIMOV.<br />
              Para a sua segurança NÃO será possível recuperar os dados apagados. <br /><br />
              Você confirma a exclusão de todos os dados do usuário do GIMOV?<br /><br /> Nome: {user.nome}<br /><br />Email: {user.email}<br />
            </Typography><br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={EncerrarConta} autoFocus>Sim</Button>
          <Button onClick={() => { setOpenEncerrarConta(false) }}>Não</Button>
        </DialogActions>
      </Dialog>

    </>

  )
}
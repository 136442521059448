import { leadStatus } from '../../constants/crm'
import { gFases } from '../../constants/fases'
import { apiGate, apiGate2 } from '../../services/api_gate'
import { nome } from '../../utils/TextfieldMask/Mask'

export async function createLead(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do createLead') }
  const api = await apiGate2.post('/crm/lead/create', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api)
  return api
}

export async function getAllLeads(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getAllLeads') }
  const api = await apiGate.post('/crm/lead/list', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}


export async function getMyLeads(account) {
  const query = {
    select: {
      idlead: true,
      nome: true,
      email: true,
      telefone: true,
      faixaderenda: true,
      createdat: true,
      updatedat: true,
      idfase: true,
      idetapa: true,
      idoperador: true,
      isactive: true,
      status: true,
      interesse: true,
      dtdesignacao: true,
      version: true,
      origem: true,
      idcliente: true,
      motivo: true,
      fase: {
        select: {
          idfase: true,
          nome: true,
          ordem: true
        },
        where: {
          isactive: true
        }
      },
      etapa: {
        select: {
          idetapa: true,
          nome: true,
          ordem: true
        }
      },
    },
    where: {
      idconta: account.conta,
      idoperador: account.id,
      isactive: true,
      status: { not: leadStatus.Disponivel },
      idfase: { not: process.env.REACT_APP_KEY_ARQUIVO }
    },
    orderBy: [{
      fase: {
        ordem: 'asc'
      },
    },
    {
      etapa: {
        ordem: 'asc'
      }
    },
    {
      createdat: 'asc'
    }
    ],
  }
  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getLeads', query) }
    const api = await apiGate.post('/crm/lead/list', query)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
    return api
  } catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em getLeads', e) }
    return []
  }
}

export async function distributeLeads(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou do distributeLeads', dados)
  const api = await apiGate.post('/crm/lead/distribuir', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function listLeadsDistribuidos(account) {
  const dados = {
    idconta: account.conta,
    isactive: true,
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listLeadsDistribuido') }
  const api = await apiGate.post('/crm/lead/listleadsdistribuidos', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function listFasesEtapas(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listFasesEtapas') }
  const api = await apiGate.post('/crm/lead/listFasesEtapas', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function liberarLead(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do liberarLead') }
  const api = await apiGate.post('/crm/lead/liberar', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function encerrarLead(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do encerrarLead', dados) }
  const api = await apiGate.post('/crm/lead/encerrar', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function transferirLead(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do tranferirLead') }
  const api = await apiGate.post('/crm/lead/transferir', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export function estatistica(_leads) {
  // console.log('Leads ', _leads)

  let cont = []
  let dados = []
  let total = 0
  const leadsQtd = _leads.length
  for (let i = 0; i < gFases.length; i++) {
    let _fase = gFases[i]
    cont[i] = 0
    for (let j = 0; j < leadsQtd; j++) {
      if (_leads[j].idfase === _fase.idfase) {
        cont[i]++
      }
    }
    _fase.total = cont[i]
    total += cont[i]
    dados.push(_fase)
  }

  // Separar os leads por motivo de encerramento e contar as quantidades
  let _leadsEncerramento = []
  let _leadsEncerramentoQtd = []
  for (let i = 0; i < leadsQtd; i++) {
    let _lead = _leads[i]
    let _encerramento = _lead.motivo
    let _index = _leadsEncerramento.indexOf(_encerramento)
    if (_index === -1) {
      _leadsEncerramento.push(_encerramento)
      _leadsEncerramentoQtd.push(1)
    } else {
      _leadsEncerramentoQtd[_index]++
    }
  }
  // Colocar os motivos em ordem crescente
  _leadsEncerramento = _leadsEncerramento.sort(function (a, b) {
    return a - b;
  })
  // Colocar as quantidades no motivo
  const _encerramento = []
  for (let i = 0; i < _leadsEncerramento.length; i++) {
    _encerramento.push({ motivo: _leadsEncerramento[i] ? _leadsEncerramento[i] : 'Sem Motivo', qtd: _leadsEncerramentoQtd[i] ? _leadsEncerramentoQtd[i] : 0 })
  }

  // Separar os leads por origem do lead e contar as quantidades
  let _leadsOrigem = []
  let _leadsOrigemQtd = []
  let _canal = []
  for (let i = 0; i < leadsQtd; i++) {
    let _lead = _leads[i]
    let _origem = _lead.origem
    let _index = _leadsOrigem.indexOf(_origem)
    if (_index === -1) {
      _leadsOrigem.push(_origem)
      _leadsOrigemQtd.push(1)
    } else {
      _leadsOrigemQtd[_index]++
    }
  }
  // colocar os origens em ordem crescente
  _leadsOrigem = _leadsOrigem.sort(function (a, b) {
    return a - b;
  })
  // Colocar as quantidades no origem
  for (let i = 0; i < _leadsOrigem.length; i++) {
    _canal.push({ canal: _leadsOrigem[i] ? _leadsOrigem[i] : 'Sem Origem', qtd: _leadsOrigemQtd[i] ? _leadsOrigemQtd[i] : 0 })
  }


  // Separar os leads por nome do lead e contar as quantidades
  let _leadsNome = []
  let _leadsNomeQtd = []
  for (let i = 0; i < leadsQtd; i++) {
    let _lead = _leads[i]
    let _nome = _lead.nome
    let _index = _leadsNome.indexOf(_nome)
    if (_index === -1) {
      _leadsNome.push(_nome)
      _leadsNomeQtd.push(1)
    } else {
      _leadsNomeQtd[_index]++
    }
  }

  // Colocar os nomes ordem crescente
  _leadsNome = _leadsNome.sort(function (a, b) {
    return a - b;
  })
  // Colocar as quantidades no nomes
  const _operador = []
  for (let i = 0; i < _leadsNome.length; i++) {
    _operador.push({ nome: _leadsNome[i], qtd: _leadsNomeQtd[i] })
  }

  dados.total = total
  dados.operador = _operador
  dados.canal = _canal
  dados.encerramento = _encerramento

  // console.log('Dados  ', dados)
  return dados
}
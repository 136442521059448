import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Slide } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';


import { green } from '@mui/material/colors';
import { canEdit, canFilter, canTeam } from '../../constants/perfil';

import './styles.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { delAlerta } from '../../controllers/messageriaController';
import ComputerIcon from '@mui/icons-material/Computer';
import { playSound } from '../Sounds/playSound';
import { SingOut } from '../../public/Login/SingOut';

export default function MenuAppBar(
  { setAddMap, addMap, setActiveMarker,
    user, setCoordinates, coordinates, setIsPageAjuda,
    isPageAjuda, setOpenPerfil, openPerfil,
    setOpenEquipe, openEquipe, hasConvite,
    setOpenConvite, setPosAtual, hasMessage, setOpenMessage,
    setOpenFilter, isFiltering, setIsFiltering, isExample,
    hasNewLead, setHasNewLead
  }) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Menu:', addMap, user)

  let navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [teamAnchoerEl, setTeamAnchorEl] = useState(null)
  const [hasToken, setHasToken] = useState(user.tokenmsg ? true : false)
  // const [loadToken, setLoadToken] = useState(false)


  // console.log('coordenadas menu:', coordinates)


  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isTeamMenuOpen = Boolean(teamAnchoerEl)



  function handleOpenFiltro() {
    setOpenFilter(!isFiltering)
    setIsFiltering(!isFiltering)
    setActiveMarker(null)
    setAddMap(false)
  }
  const handleClickAjuda = () => {
    setIsPageAjuda(!isPageAjuda)
  }


  const handleClickGPS = (event) => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Carregando Coordinates:', latitude, longitude)
      setCoordinates({ lat: latitude, lng: longitude });
      setPosAtual({ lat: latitude, lng: longitude })
    });
  }

  const handleAddMap = () => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em HandleAddMap:', addMap)
    setActiveMarker(null)
    setAddMap(!addMap)

  }

  const handleOpenPerfil = () => {
    setOpenPerfil(true)
    setAnchorEl(null);
  }
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTeamMenuOpen = (event) => {
    setTeamAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  async function handleMenuLogOut() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em HandleMenuLogOut')
    navigate('/singin', { replace: true })
  }
  const handleMenuSingOut = () => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em HandleMenuSingOut')
    // listenLeads();
    SingOut()
    navigate('/singin', { replace: true })
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  // Menu Suspenso Desktop
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{'Usuário: ' + user.email}</MenuItem>
      {user.conta !== user.id && (<MenuItem onClick={handleMenuClose}>{'Equipe: ' + user.equipe}</MenuItem>)}
      {/* <MenuItem onClick={handleMenuClose}>{'Plano: ' + user.plano}</MenuItem> */}
      <MenuItem onClick={handleOpenPerfil} >Minha Conta</MenuItem>
      {/* <MenuItem onClick={handleMenuSingOut}>SingOut</MenuItem> */}

    </Menu>
  );

  // Menu Suspenso Mobile
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <IconButton
          size="large"
          aria-label="Perfil"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleMenuSingOut}>
        <IconButton
          size="large"
          aria-label="Sair"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppTwoToneIcon />
        </IconButton>
        <p>LogOut</p>
      </MenuItem>
    </Menu>
  );

  // Menu Suspenso Team

  const handleCRMMenu = () => {

    if (user.hascrm) navigate('/crm', { replace: true, state: { account: user, coordinates } })
    else {
      alert('Você não tem permissão para acessar o CRM')
    }
  }

  const handleTeamMenuClose = () => {
    setTeamAnchorEl(null);
  };


  const handlerOpenEquipe = () => {
    setOpenEquipe(!openEquipe)
    handleTeamMenuClose()
  }

  const teamMenuId = 'team-menu';
  const renderTeamMenu = (
    <Menu
      anchorEl={teamAnchoerEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={teamMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isTeamMenuOpen}
      onClose={handleTeamMenuClose}
    >
      <MenuItem onClick={handlerOpenEquipe} >Equipe</MenuItem>
    </Menu>
  );

  const disableAlertLead = () => {
    setHasNewLead(false)
    delAlerta(user.id)
  }


  const [listen, setListen] = useState(null)
  useEffect(() => {
    const listenLeads = onSnapshot(doc(db, "alerts", user.id), (snapshot) => {
      const alert = snapshot.data();
      setListen(alert)
      if (alert) playSound();
    })
    return () => listenLeads();
  }, [])


  return (

    <Grid container
      bgcolor={'red'}
      height={'6vh'}
    >
      <AppBar
      // position="static" 
      >
        <Toolbar  >
          <Grid container
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {/* Logotipo */}
            <Box
              height={'45px'}
              width={'139px'}
              sx={{
                display: 'flex',
                backgroundImage: 'url(./logo1n_bc.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            />
            {/* --------> Menu Desktop <----------*/}
            <Grid item sx={{
              display: { xs: 'none', md: 'flex' },
            }}>
              <Grid container gap={2}>
                {/* Icone do menu para alerta de lead } */}
                {listen && (
                  <IconButton
                    size="large"
                    // edge="end"
                    aria-label="Lead"
                    color='error'
                    onClick={disableAlertLead}
                    className={'btn-pisca'}
                  // sx={{ position:'absolute', top: '10px', left: '50%' }}
                  // sx={{ mx: '10px' }}
                  >
                    <CampaignOutlinedIcon fontSize='large' />
                    Lead
                  </IconButton>
                )}
                {/* Icone do Menu para AJUDA} */}
                <IconButton
                  size="large"
                  // edge="end"
                  aria-label="Ajuda"
                  color={isPageAjuda || isExample ? 'error' : 'inherit'}
                  onClick={handleClickAjuda}
                  className={isPageAjuda ? '' : isExample ? 'btn-pisca' : ''}
                >
                  <QuestionMarkTwoToneIcon fontSize='large' />
                </IconButton>
                {/* Icone do menu para alerta de tokenmsg ausente } */}
                {/* {(user.crm && !hasToken) &&
                  <Box sx={{ position: 'relative' }}>
                    <IconButton
                      size="large"
                      // edge="end"
                      aria-label="Lead"
                      color='error'
                      // onClick={resetToken}
                      className={'btn-pisca'}
                      disabled={loadToken}
                    >
                      <GroupsRoundedIcon fontSize='large' />
                    </IconButton>
                    {loadToken && alertaToken()}
                  </Box>
                } */}
                {/* Icone para o CRM */}
                {(user.crm) &&
                  <IconButton
                    size="large"
                    // edge="end"
                    aria-label="CRM"
                    color="inherit"
                    onClick={handleCRMMenu}
                  >
                    <ComputerIcon fontSize='large' />
                  </IconButton>
                }
                {/* Icone do Menu para administrar Convites} */}
                {user.hasinvite && (
                  <IconButton
                    size="large"
                    // edge="end"
                    aria-label="Equipe"
                    aria-controls={teamMenuId}
                    aria-haspopup="true"
                    onClick={() => { setOpenConvite(true) }}
                    color="error"
                    className='btn-pisca'
                  >
                    <EmailTwoToneIcon fontSize='large' />
                  </IconButton>
                )}
                {/* Icone do Menu para MENSAGENS} */}
                {hasMessage && (
                  <IconButton
                    size="large"
                    // edge="end"
                    aria-label="Mensagem"
                    color='secondary'
                    onClick={() => { setOpenMessage(true) }}
                  >
                    <MessageTwoToneIcon fontSize='large' />
                  </IconButton>
                )}
                {/* Icone do Menu para Filtro} */}
                {canFilter(user) && (
                  <IconButton
                    size="large"
                    // edge="start"
                    aria-label="Filtro"
                    color={isFiltering ? 'error' : 'inherit'}
                    onClick={handleOpenFiltro}
                    sx={{ backgroundColor: isFiltering ? green[500] : '' }}
                  >
                    <FilterAltTwoToneIcon fontSize='large' />
                  </IconButton>
                )}
                {/* Icone do Menu para GPS} */}
                <IconButton
                  size="large"
                  // edge="end"
                  aria-label="GPS"
                  color="inherit"
                  onClick={handleClickGPS}
                >
                  <GpsFixedTwoToneIcon fontSize='large' />
                </IconButton>
                {/* Icone do Menu para Administrar Equipes} */}
                {canTeam(user) && (
                  <IconButton
                    size="large"
                    // edge="end"
                    aria-label="Equipe"
                    aria-controls={teamMenuId}
                    aria-haspopup="true"
                    onClick={() => { setOpenEquipe(!openEquipe) }}
                    color="inherit"
                  >
                    <GroupAddTwoToneIcon fontSize='large' />
                  </IconButton>
                )}
                {/* Icone do Menu para Editar Imóveis} */}
                {canEdit(user) && (
                  <IconButton
                    size="large"
                    aria-label="cadastrar"
                    color={addMap ? 'error' : 'inherit'}
                    onClick={handleAddMap}
                    sx={{ backgroundColor: addMap ? green[500] : '' }}
                    disabled={isFiltering}
                  >
                    <EditTwoToneIcon fontSize='large' />
                  </IconButton>
                )}
                {/* Icone do Menu para Perfil ddo Usuário} */}
                <IconButton
                  size="large"
                  // edge="end"
                  aria-label="conta"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >

                  <AccountCircle fontSize='large' />
                </IconButton>
                {/* <Avatar sx={{ width: 32, height: 32 }} alt={user.nome} src="/assets/avatars/avatar2_32x32.png" /> */}
                {/* Icone do Menu para LogOut} */}
                <IconButton size="large"
                  aria-label="sair"
                  color="inherit"
                  onClick={handleMenuLogOut}
                >
                  <ExitToAppTwoToneIcon fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
            {/* Menu Mobile */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              {/* Icone do menu para alerta de lead } */}
              {listen && (
                <IconButton
                  size="large"
                  aria-label="Lead"
                  color='error'
                  onClick={disableAlertLead}
                  className={'btn-pisca'}
                >
                  <CampaignOutlinedIcon fontSize='large' />
                </IconButton>
              )}
              {/* Icone do Menu para AJUDA} */}
              {(!hasNewLead && hasToken) && (
                <IconButton
                  size="large"
                  aria-label="Ajuda"
                  color={isPageAjuda || isExample ? 'error' : 'inherit'}
                  onClick={handleClickAjuda}
                  className={isPageAjuda ? '' : isExample ? 'btn-pisca' : ''}
                >
                  <QuestionMarkTwoToneIcon />
                </IconButton>
              )}
              {/* Icone do menu para alerta de tokenmsg ausente } */}
              {/* {(user.crm && !hasToken) &&
                <Grid item
                  justifyContent={'center'}
                >
                  <Box sx={{ position: 'relative' }}>
                    <IconButton
                      size="large"
                      aria-label="Lead"
                      color='error'
                      onClick={resetToken}
                      className={'btn-pisca'}
                      disabled={loadToken}
                    >
                      <GroupsRoundedIcon fontSize='large' />
                    </IconButton>
                    {loadToken && alertaToken()}
                  </Box>
                </Grid>
              } */}
              {/* Icone para o CRM */}
              {(user.crm) &&
                <IconButton
                  size="large"
                  aria-label="CRM"
                  color="inherit"
                  onClick={handleCRMMenu}
                >
                  <ComputerIcon fontSize='inherit' />
                </IconButton>
              }
              {/* Icone do Menu para MENSAGENS} */}
              {hasMessage && (
                <IconButton
                  size="large"
                  aria-label="Mensagem"
                  color='secondary'
                  onClick={() => { setOpenMessage(true) }}
                >
                  <MessageTwoToneIcon />
                </IconButton>
              )}
              {/* Icone do Menu para Filtro} */}
              <IconButton
                size="large"
                aria-label="Filtrar"
                color={isFiltering ? 'secondary' : 'inherit'}
                onClick={handleOpenFiltro}
              >
                <FilterAltTwoToneIcon />
              </IconButton>
              {/* Icone do Menu para administrar Convites} */}
              {user.hasinvite && (
                <IconButton
                  size="large"
                  aria-label="Equipe"
                  aria-controls={teamMenuId}
                  aria-haspopup="true"
                  onClick={() => { setOpenConvite(true) }}
                  color="inherit"
                >
                  <EmailTwoToneIcon fontSize='large' />
                </IconButton>
              )}
              {/* Icone do Menu para GPS} */}
              {!listen && (
                <IconButton
                  size="large"
                  aria-label="Perfil"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleClickGPS}
                >
                  <GpsFixedTwoToneIcon />
                </IconButton>
              )}

              {/* Icone do Menu para Perfil ddo Usuário} */}
              <IconButton
                size="large"
                aria-label="conta"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle fontSize='large' />
              </IconButton>
              {/* Icone do Menu para LogOut} */}
              <IconButton size="large"
                aria-label="sair"
                color="inherit"
                onClick={handleMenuLogOut}
              >
                <ExitToAppTwoToneIcon />
              </IconButton>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderTeamMenu}
      <Toolbar />
    </Grid>

  );
}
import {
  Box, Stack, Typography, FormControl, TextField,
  InputLabel, MenuItem, Select, Paper,
  useTheme, useMediaQuery
} from '@mui/material';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export const Identificacao = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace,
  newPosition,
  openExcluirDialog,
  setOpenExcluirDialog,
  handleClickExcluir
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [vEmpreendimento, setvEmpreendimento] = useState(false)
  const [vConstrutora, setvConstrutora] = useState(false)
  const [vEndereco, setvEndereco] = useState(false)

  const [identificacao, setIdentificacao] = useState({ ...placeEdit, position: newPosition })

  useEffect(() => {
    setPlaceEdit(identificacao)
  }, [identificacao])

  const hasError = (event) => {
    let res = false
    if (event.target.value.length <= 3) res = true
    if (event.target.id === 'construtora') setvConstrutora(res)
    if (event.target.id === 'empreendimento') setvEmpreendimento(res)
    if (event.target.id === 'endereco') setvEndereco(res)
    return res
  }

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{
        width: '100%',
        p: 0
      }}
    >
      <Paper
        elevation={2}
        sx={{ p: 2, borderRadius: 2 }}
      >
        <Stack spacing={3}>
          {/* Empreendimento e Telefone */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <FormControl fullWidth>
              <TextField
                label="Empreendimento"
                required
                id="empreendimento"
                value={identificacao?.empreendimento || ''}
                onBlur={hasError}
                onChange={(e) => setIdentificacao({
                  ...identificacao,
                  empreendimento: e.target.value
                })}
                error={vEmpreendimento}
                inputProps={{ maxLength: 150 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </FormControl>

            <FormControl sx={{ minWidth: { xs: '100%', sm: '200px' } }}>
              <TextFieldMask
                id="telefone"
                label="Telefone"
                value={identificacao?.telefone || ''}
                onChange={(e) => setIdentificacao({
                  ...identificacao,
                  telefone: e.target.value
                })}
                mask="phone"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </FormControl>
          </Stack>

          {/* Construtora e Site */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <FormControl fullWidth>
              <TextField
                required
                id="construtora"
                label="Construtora"
                value={identificacao?.construtora || ''}
                onChange={(e) => setIdentificacao({
                  ...identificacao,
                  construtora: e.target.value
                })}
                onBlur={hasError}
                error={vConstrutora}
                inputProps={{ maxLength: 150 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </FormControl>

            <FormControl sx={{ minWidth: { xs: '100%', sm: '200px' } }}>
              <TextField
                id="site"
                label="Site"
                value={identificacao?.site || ''}
                onChange={(e) => setIdentificacao({
                  ...identificacao,
                  site: e.target.value
                })}
                inputProps={{ maxLength: 150 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </FormControl>
          </Stack>

          {/* Endereço */}
          <FormControl fullWidth>
            <TextField
              required
              id="endereco"
              label="Endereço"
              value={identificacao?.endereco || ''}
              onChange={(e) => setIdentificacao({
                ...identificacao,
                endereco: e.target.value
              })}
              onBlur={hasError}
              error={vEndereco}
              inputProps={{ maxLength: 100 }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
            />
          </FormControl>

          {/* Entrega e Situação */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <FormControl sx={{ minWidth: { xs: '100%', sm: '200px' } }}>
              <TextField
                id="entrega"
                label="Entrega"
                value={identificacao?.entrega || ''}
                onChange={(e) => setIdentificacao({
                  ...identificacao,
                  entrega: e.target.value
                })}
                inputProps={{ maxLength: 150 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                  }
                }}
              />
            </FormControl>

            <FormControl sx={{ minWidth: { xs: '100%', sm: '200px' } }}>
              <InputLabel id="lbSituacao">Situação</InputLabel>
              <Select              
                labelId="lbSituacao"
                id="situacao"
                value={identificacao?.situacao || 'LA'}
                label="Situação"
                onChange={(e) => setIdentificacao({
                  ...identificacao,
                  situacao: e.target.value
                })}
                sx={{
                  borderRadius: 2,
                  height: '55px',
                }}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                <MenuItem value="LA">Lançamento</MenuItem>
                <MenuItem value="EO">Em Obra</MenuItem>
                <MenuItem value="OE">Em Obra/Entregue</MenuItem>
                <MenuItem value="E">Entregue</MenuItem>
                <MenuItem value="V">100% Vendido</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {/* Descrição */}
          <FormControl fullWidth>
            <TextField
              id="desc"
              name="desc"
              label="Descrição"
              multiline
              rows={7}
              value={identificacao?.descricao || ''}
              onChange={(e) => setIdentificacao({
                ...identificacao,
                descricao: e.target.value
              })}
              inputProps={{ maxLength: 1000 }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
            />
          </FormControl>
        </Stack>
      </Paper>
    </Box>
  );
};
import { apiGate } from '../../services/api_gate'

export async function carregarPorWhere(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do carregarPorWhere') }
  const api = await apiGate.post('/crm/carteira/loadbywhere', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function atualizarCarteira(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do atualizarCarteira') }
  const api = await apiGate.post('/crm/carteira/update', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function carregarCliente(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do carregarCliente') }
  const api = await apiGate.post('/crm/carteira/load', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function salvarCarteira(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do salvarCarteira') }
  const api = await apiGate.post('/crm/carteira/saveComLead', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function listarClientes(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listarClientes') }
  const api = await apiGate.post('/crm/carteira/list', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function excluirCliente(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do excluirCliente') }
  const api = await apiGate.post('/crm/carteira/delete', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function consultarCPFCNPJ(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do consultarCPFCNPJ') }
  const api = await apiGate.post('/crm/carteira/consultarCPFCNPJ', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}
import { useEffect, useState } from 'react';
import {
  Grid, Button, Card, CardContent,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  FormControl, TextField, Typography,
  Box, useTheme
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';

import gerarCodigo from '../../../utils/gerarCodigo';
import { getContaController, setCodigoConvite } from '../../../controllers/contaController';
import { enviarConviteEmail } from '../../../controllers/inviteController';
import { green, red } from '@mui/material/colors';
import { canUserSendInvite } from '../../../constants/perfil';
import { getQtdOperadores } from '../../../controllers/equipeController';


export function CadConvite({ user, listUsuarios }) {
  const theme = useTheme();

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Integrantes', listUsuarios, listUsuarios.length)

  const conta = user.conta
  const [equipe, setEquipe] = useState([])
  const [hasConviteValido, setHasConviteValido] = useState(false)
  const [openDesativarConvite, setOpenDesativarConvite] = useState(false)
  const [isLoadingDesativar, setIsLoadingDesativar] = useState(false)
  const [emailConvidado, setEmailConvidado] = useState('')
  const [msg, setMsg] = useState(null)
  const [openConfirmarConvite, setOpenConfirmarConvite] = useState(false)
  const [canSendInvite, setCanSendInvite] = useState(false)

  async function getEquipe(conta) {
    const equipe = await getContaController(conta)
    setEquipe(equipe)
    if (equipe.conviteval !== null) {
      if (new Date(equipe.conviteval.toDate()) > new Date()) { setHasConviteValido(true) }
      else setHasConviteValido(false)
    }
  }

  const [isLoadingCon, setIsLoadingCon] = useState(false)
  async function handleGerarConvite() {
    setIsLoadingCon(true)
    const codigo = gerarCodigo()
    await setCodigoConvite(codigo.codigo, codigo.validade, conta).then(() => {
      getEquipe(conta)
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu handleGerarConvite', codigo, equipe)
    setIsLoadingCon(false)
  }

  async function desativarConvite() {
    setIsLoadingDesativar(true)
    await setCodigoConvite(null, null, conta).then(() => {
      getEquipe(conta)
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu desativarConvite', conta, equipe)
    setOpenDesativarConvite(false)
    setIsLoadingDesativar(false)
  }

  function handleEmail(event) {
    setEmailConvidado(event.target.value)
    setMsg(null)
  }

  function handleEnviarConvite() {
    if (emailConvidado !== undefined && emailConvidado.length > 0)
      setOpenConfirmarConvite(true)
  }

  async function sendInviteByEmail() {
    setIsLoadingCon(true)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em sendInviteByEmail:', emailConvidado)
    let i = 0;
    listUsuarios.forEach((operador) => {
      if (operador.email === emailConvidado) {
        i++
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Integrante:', i, operador)
      }
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Tem integrante:', i)
    if (i === 0 && emailConvidado !== '' && emailConvidado !== user.email && emailConvidado !== equipe.email) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Email operador:', user.email)
      const result = await enviarConviteEmail(emailConvidado, equipe, conta)
      setMsg(result.msg)
    }
    else {
      setMsg('Já é integrante da equipe ou email é invalido...')
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Email do operador inválido', emailConvidado)
    }
    setOpenConfirmarConvite(false)
    setIsLoadingCon(false)
  }


  async function getCanSendInvite(_conta) {
    const rst = await getQtdOperadores(_conta)
    if (rst.sucess) {
      if (!canUserSendInvite(user, rst.data)) {
        setMsg('Limite de operadores do plano alcançado (' + rst.data + ').')
        setCanSendInvite(false)
        await desativarConvite()
      }
      else {
        setMsg('')
        setCanSendInvite(true)
      }
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(canUserSendInvite(user, rst.data))
    }

  }

  useEffect(() => {
    getEquipe(conta)
    getCanSendInvite(conta)
  }, [])

  return (
    <Box sx={{ height: '480px', p: 2, maxWidth: 800, margin: '0 auto' }}>
      <Card elevation={0} sx={{ mb: 3, backgroundColor: theme.palette.background.neutral }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                size="medium"
                onClick={handleGerarConvite}
                loading={isLoadingCon}
                loadingPosition="start"
                variant="contained"
                disabled={!canSendInvite || hasConviteValido}
                startIcon={<EmailTwoToneIcon />}
                sx={{
                  backgroundColor: canSendInvite && !hasConviteValido ? theme.palette.primary.main : theme.palette.grey[300],
                  '&:hover': {
                    backgroundColor: canSendInvite && !hasConviteValido ? theme.palette.primary.dark : theme.palette.grey[300]
                  }
                }}
              >
                Gerar Novo Código de Convite
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        {/* Código de Convite */}
        <Grid item xs={12} md={6}>
          <Card elevation={1} sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h4" gutterBottom sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.main,
                mb: 3
              }}>
                <LinkTwoToneIcon sx={{ mr: 1 }} />
                Código do Convite
              </Typography>

              <Box sx={{
                p: 2,
                borderRadius: 1,
                backgroundColor: theme.palette.background.neutral,
                mb: 2
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      Código
                    </Typography>
                    <Typography variant="h3" sx={{ fontFamily: 'monospace' }}>
                      {equipe?.convite || '---'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      <AccessTimeTwoToneIcon sx={{ mr: 1, fontSize: 18 }} />
                      Válido até
                    </Typography>
                    <Typography variant="body2">
                      {equipe.convite ? new Date(equipe.conviteval.toDate()).toLocaleString() : '---'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <LoadingButton
                fullWidth
                size="medium"
                onClick={() => setOpenDesativarConvite(true)}
                loading={isLoadingDesativar}
                loadingPosition="start"
                variant="outlined"
                color="error"
                disabled={!hasConviteValido}
                sx={{ mt: 2 }}
              >
                Desativar Código
              </LoadingButton>
            </CardContent>
          </Card>
        </Grid>

        {/* Envio por Email */}
        <Grid item xs={12} md={6}>
          <Card elevation={1} sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h4" gutterBottom sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.main,
                mb: 3
              }}>
                <EmailTwoToneIcon sx={{ mr: 1 }} />
                Enviar Convite por Email
              </Typography>

              <TextField
                fullWidth
                size="medium"
                label="Email do convidado"
                variant="outlined"
                onChange={handleEmail}
                disabled={!canSendInvite || !hasConviteValido}
                sx={{ mb: 2 }}
              />

              <LoadingButton
                fullWidth
                size="medium"
                onClick={handleEnviarConvite}
                loading={isLoadingCon}
                loadingPosition="start"
                variant="contained"
                disabled={!canSendInvite || !hasConviteValido || !emailConvidado}
                startIcon={<EmailTwoToneIcon />}
                sx={{ mb: 1 }}
              >
                Enviar Convite
              </LoadingButton>

              {msg && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{
                    mt: 1,
                    textAlign: 'center'
                  }}
                >
                  {msg}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Dialog Desativar Código do Convite */}
      <Dialog
        open={openDesativarConvite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='h3' component={'span'} >
            {"Confirmar desativação:"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='h3' component={'span'} color={'error'}>
              Desativar código de convite {equipe.convite}?
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={desativarConvite}>Sim</Button>
          <Button onClick={() => setOpenDesativarConvite(false)}>Não</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog Confirmar envio de convite */}
      <Dialog
        open={openConfirmarConvite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='h3' component={'span'} >
            {"Confirmar envio de convite para:"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              {emailConvidado}
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={sendInviteByEmail}>Sim</Button>
          <Button onClick={() => setOpenConfirmarConvite(false)}>Não</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
import { useRef, useState } from 'react';
import { InfoWindowF, GoogleMap, useJsApiLoader, Autocomplete, MarkerF } from '@react-google-maps/api';
import { InputBase, useTheme, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Divider, FormControlLabel, Checkbox, Paper } from '@mui/material';
import { green } from '@mui/material/colors';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { situacaoTipo } from '../../utils/tipos';
import { descDormitorios } from '../../utils/description';
import { poolFacilidades } from '../../constants/places';
import SearchIcon from '@mui/icons-material/Search';




const Map = ({
  setCoordinates,
  setBounds,
  coordinates,
  places,
  setChildClicked, setActiveMarker, setOpenModal, setNewPosition,
  activeMarker, isAddMap, setNewPlace, totImoveis, isFiltering, showMySnackbar }) => {

  const theme = useTheme()
  const mapRef = useRef(null)
  const { google } = window
  
  const hasGeoCode = true
  const limitGeoCode = 20
  const [qtdAddressCode, setQtdAddressCode] = useState(0)

  async function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenterChanged() {
    if (!mapRef.current) {
      return;
    }
    const bounds = mapRef.current.getBounds().toJSON();
    const center = mapRef.current.getCenter().toJSON();
    setCoordinates(center);
    setBounds(bounds);
    setChildClicked(-1);
    setPutArrow(false)

  }

  const [googleMapsLibraries] = useState(['places']);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_MAP,
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_GEOCODE,
    libraries: googleMapsLibraries
  })


  const geocodeLatLng = async (lat, lng) => {
    if (!isLoaded) {
      console.error("Google Maps API ainda não carregada.");
      return null;
    }
    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
    try {
      const response = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK' && results[0]) {
            setQtdAddressCode(qtdAddressCode + 1)
            resolve(results[0]);
          } else {
            reject(new Error(`Geocode falhou devido a: ${status}`));
          }
        });
      });
      // const addressComponents = response.address_components;
      // const cidade = addressComponents.find(component => component.types.includes('administrative_area_level_2'))?.long_name || '';
      // const bairro = addressComponents.find(component => component.types.includes('sublocality'))?.long_name || '';
      // const rua = addressComponents.find(component => component.types.includes('route'))?.long_name || '';
      // const numero = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';
      // const endereco = response.formatted_address
      return response.formatted_address || '';
    } catch (error) {
      console.error("Erro ao buscar dados de geocodificação:", error);
      return null;
    }
  };


  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC)
  const [putArrow, setPutArrow] = useState(false)
  const onPlaceChanged = (event, value) => {
    try {
      mapRef.current.setZoom(19)
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      setCoordinates({ lat, lng });
      setPutArrow(true)
    } catch {
      setPutArrow(false)
    }
  }


  // Marker setup

  let urlCursor = 'url(assets/images/lapis40px.png),auto'

  const optionsMap = {
    //disableDefaultUI: true,
    draggableCursor: isAddMap ? urlCursor : 'default',
    draggingCursor: isAddMap ? urlCursor : 'default',
    minZoom: 9,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: 8
    },
    rotateControl: true,
    zoomControl: false,
    region: 'BR',
    language: 'pt-BR'
    // zoomControlOptions: { position: 8 },

    // Bug no GoogleMaps -> Desabilita a fullscreen depois de movimentar o mapa
    // fullscreenControl: true,
    // fullscreenControlOptions: {
    //   position: 8
    // },    
  }

  function pinSeta(color) {
    return {
      //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
      path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
      //url: '../assets/images/casa_map.png',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 1,
      scale: 3
    };
  }

  function pinSymbol(color) {
    return {
      //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
      //path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
      //url: '../assets/images/casa_map.png',
      url: '../assets/images/predio_map.png',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 1,
      scale: 2
    };
  }

  // const onLoadMarker = marker => {
  //   if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('marker: ', marker.position)
  // }

  const handleActiveMarker = (marker) => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em handlerActiveMarker', marker)
    // if (marker === activeMarker) {

    if (!isAddMap) {
      setChildClicked(marker) // Atualiza Lista
      setActiveMarker(marker)
    }
    else {
      setNewPosition(places[marker].position)
      setNewPlace(places[marker])
      setOpenModal(true)
    }
  };
  const handleDeactiveMarker = (marker) => {
    setActiveMarker(null)
  }

  async function handleOnClickMap(marker) {

    setActiveMarker(null)
    setPutArrow(false)
    if (isAddMap) {
      setNewPosition(marker.latLng.toJSON())
      if (hasGeoCode && qtdAddressCode < limitGeoCode) {
        const geocode = await geocodeLatLng(marker.latLng.toJSON().lat, marker.latLng.toJSON().lng)
        setNewPlace({
          position: marker.latLng.toJSON(),
          endereco: geocode
        })
      }
      setOpenModal(true)
    } else {
      // console.log('Entrou em handleOnClickMap', marker.latLng.toJSON())
      setOpenModal(false)
      setNewPosition(null)
    }
  }
  const [openExtrato, setOpenExtrato] = useState(false)
  const [putDivulgacao, setPutDivulgacao] = useState(true)

  const [dormitorios, setDormitorios] = useState('')

  function mostrarExtrato() {
    setDormitorios(descDormitorios(places[activeMarker]))
    setOpenExtrato(true)
  }


  async function copyDivToClipboard() {
    var range = document.createRange();
    range.selectNode(document.getElementById("dialog"))
    await navigator.clipboard.writeText(range.toString().split('/ln').join('\n')).then(() => {
      showMySnackbar('Copiado para área de transferência...', 2000, 'success')
    }).catch((e) => {
      showMySnackbar('Erro na operação', 2000, 'error')
    })
  }

  return (
    isLoaded ? (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {/* MAPA */}
        <Box sx={{ flex: 1, position: 'relative' }}>
          <GoogleMap
            onLoad={handleLoad}
            options={optionsMap}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={coordinates ? coordinates : { lat: -23.5505199, lng: -46.63330939999999 }}
            zoom={15}
            mapTypeId='roadmap'
            onDragEnd={handleCenterChanged}
            onClick={handleOnClickMap}
            onZoomChanged={handleCenterChanged}
          >
            {/* Search Bar with Material Design */}
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                top: theme.spacing(2),
                left: theme.spacing(2),
                zIndex: 1,
                borderRadius: theme.shape.borderRadius,
                [theme.breakpoints.down('md')]: { display: 'none' }
              }}
            >
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                <InputBase
                  id='pesquisar_map'
                  name='pesquisar_map'
                  placeholder='Pesquisar localização...'
                  sx={{
                    px: 2,
                    py: 1,
                    width: '400px',
                    [theme.breakpoints.down('md')]: { width: '300px' },
                    '& input': {
                      transition: theme.transitions.create('width'),
                    }
                  }}
                  startAdornment={
                    <SearchIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />
                  }
                />
              </Autocomplete>
            </Paper>

            {/* Markers */}
            {putArrow && (
              <MarkerF
                position={coordinates}
                title={'Ponto'}
                animation={google.maps.Animation.BOUNCE}
                icon={pinSeta('red')}
              />
            )}

            {places?.map((place, i) => (
              <MarkerF
                key={i}
                position={place.position}
                onClick={() => handleActiveMarker(i)}
                title={place.name}
                animation={activeMarker === i ? google.maps.Animation.DROP : google.maps.Animation.DROP}
                icon={pinSymbol('blue')}
              >
                {activeMarker === i && (
                  <InfoWindowF
                    onCloseClick={handleDeactiveMarker}
                    onDomReady={() => {
                      const closeButton = document.querySelector('button[title="Close"]');
                      if (closeButton) closeButton.parentNode.style.display = 'none';
                    }}
                  >
                    <Box
                      onClick={mostrarExtrato}
                      sx={{
                        width: 150,
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.9,
                          transform: 'scale(1.01)',
                          transition: 'all 0.2s'
                        }
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        sx={{
                          mt: 2,
                          py: 1,
                          px: 1,
                          color: 'white',
                          bgcolor: theme.palette.primary.main,
                          borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {place.empreendimento}
                      </Typography>
                      <img
                        alt={place.empreendimento}
                        style={{
                          width: '100%',
                          height: '100px',
                          objectFit: 'fill',
                          borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
                        }}
                        src={place.urlimagem || 'assets/images/gimov_map_symbol.png'}
                      />
                    </Box>
                  </InfoWindowF>
                )}
              </MarkerF>
            ))}
          </GoogleMap>
        </Box>

        {/* Status Bottom Bar */}
        <Paper
          elevation={2}
          sx={{
            py: 1,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: isFiltering ? green[500] : 'background.paper',
            borderRadius: 0
          }}
        >
          {isFiltering && (
            <FilterAltTwoToneIcon
              sx={{ mr: 1, color: isFiltering ? 'white' : 'inherit' }}
            />
          )}
          <Typography
            variant='body2'
            color={isFiltering ? 'white' : 'text.primary'}
          >
            Empreendimentos: ({places.length}/{totImoveis})
          </Typography>
        </Paper>

        {/* Rest of the dialogs remain the same */}
        <Dialog
          open={openExtrato}
          scroll={'paper'}
          aria-labelledby="title"
          aria-describedby="dialog"
          fullWidth
        >
          <DialogTitle id="title">
            <Typography component={'span'} variant='h6' fontSize={'16px'}>
              Extrato - {places[activeMarker]?.empreendimento}
            </Typography>
          </DialogTitle>
          <DialogContent dividers >
            <DialogContentText
              id="dialog"
              tabIndex={-1}
              height={'400px'}
              component={'div'}
            >
              <Grid
                container
                flex
                direction={'column'}
                sx={{ pb: 2 }}
              >
                {places[activeMarker]?.empreendimento && (<Box>Empreendimento: {places[activeMarker]?.empreendimento}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.construtora && (<Box>Construtora: {places[activeMarker]?.construtora}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.blocos && (<Box>Torres: {places[activeMarker]?.blocos} </Box>)}
                {places[activeMarker]?.andares && (<Box>Andares: {places[activeMarker].andares}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.endereco && (<Box>Endereço: {places[activeMarker]?.endereco}<p hidden>/ln</p></Box>)}
                {(places[activeMarker]?.maxarea && places[activeMarker]?.minarea) && (<Box>Metragens: {places[activeMarker]?.minarea}m2 até {places[activeMarker]?.maxarea}m2<p hidden>/ln</p></Box>)}
                {dormitorios && (<Box>Dormitórios: {dormitorios}</Box>)}
                {(places[activeMarker]?.vlrinicial && places[activeMarker]?.vlrfinal) && (<Box maxWidth={'80vw'} sx={{ whiteSpace: 'normal' }}>Investimento: {places[activeMarker]?.vlrinicial} até {places[activeMarker]?.vlrfinal} aproximadamente<p hidden>/ln</p></Box>)}
                {(places[activeMarker]?.refvlrano && places[activeMarker]?.refvlrmes) && (<Box>Referência mês/ano dos valores: {places[activeMarker]?.refvlrmes}/{places[activeMarker]?.refvlrano}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.renda && (<Box>Renda: {places[activeMarker]?.renda}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.entrega && (<Box>Entrega prevista: {places[activeMarker]?.entrega}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.situacao && (<Box>Situação: {situacaoTipo(places[activeMarker].situacao)}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.site && (<Box>Site: {places[activeMarker].site}<p hidden>/ln</p></Box>)}
                <Divider sx={{ my: 1 }} />
                <Box my={0}>Facilidades:<p hidden>/ln</p></Box>
                <Divider sx={{ my: 1 }} />
                {poolFacilidades.map((caracteristica, i) => (
                  places[activeMarker] && places[activeMarker][caracteristica.campo] && (<Box key={i}>{caracteristica.nome}<p hidden>/ln</p></Box>)
                ))}
                {(putDivulgacao) && (places[activeMarker]?.divulgacao && (<Divider sx={{ my: 1 }} />))}
                {(putDivulgacao) && (places[activeMarker]?.divulgacao && (<Box my={0}>Divulgação:<p hidden>/ln</p></Box>))}
                {(putDivulgacao) && (places[activeMarker]?.divulgacao && (<Box >{places[activeMarker].divulgacao}<p hidden>/ln</p></Box>))}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'flex-end'} px={2}>
              {places[activeMarker]?.divulgacao && (<FormControlLabel control={<Checkbox
                onClick={() => { setPutDivulgacao(!putDivulgacao) }}
                checked={putDivulgacao} />}
                label="Divulgação" />
              )}
              <Button onClick={copyDivToClipboard}>Copiar</Button>
              <Button onClick={() => { setOpenExtrato(false) }} sx={{ mr: 1 }}>Fechar</Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>
    ) : null
  )
}


export default Map;
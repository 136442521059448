import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getLeadTasks } from '../../../controllers/crm/leadTaskController';
import { yellow } from '@mui/material/colors';
import LeadTaskDetails from './LeadTaskDetails';
import { taskType } from '../../../constants/crm';
import { getColorDay, getColorStatus } from '../../../utils/crm/getColors';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localeData from 'dayjs/plugin/localeData'
dayjs.locale('pt-br');
dayjs.extend(localeData)
dayjs().localeData();

export function MyTasks({ account, setSnackbar, setShowTasks, tasks, setTasks,
  setUpdate, update, dayInicio, setDayInicio, dayFim, setDayFim, setShowMenu }) {

  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState(null);
  const [showTask, setShowTask] = useState(false)
  const [semana, setSemana] = useState([])
  const [chkOutros, setChkOutros] = useState(true)
  const [tipo, setTipo] = useState(taskType.Description[taskType.Tarefa])

  const weekDays = dayjs.weekdays()


  function nextWeek() {
    var _dayStart = dayjs(dayInicio).add(7, 'day').toISOString()
    var _dayEnd = dayjs(dayFim).add(7, 'day').toISOString()
    setDayInicio(_dayStart)
    setDayFim(_dayEnd)
    loadTasks(_dayStart, _dayEnd)
  }

  function previousWeek() {
    var _dayStart = dayjs(dayInicio).subtract(7, 'day').toISOString()
    var _dayEnd = dayjs(dayFim).subtract(7, 'day').toISOString()
    setDayInicio(_dayStart)
    setDayFim(_dayEnd)
    loadTasks(_dayStart, _dayEnd)
  }

  async function handleConcluidos(e) {
    setChkOutros(!e.target.checked)
  }

  function handleTipo(e) {
    if (e.target.checked) setTipo(taskType.Description[taskType.Reuniao])
    else setTipo(taskType.Description[taskType.Tarefa])
  }


  async function loadTasks(_dayInicio, _dayFim) {
    // console.log('loadTasks', _dayInicio, _dayFim)
    try {
      const where = {
        idconta: account.conta,
        idoperador: account.id,
        dtacao: {
          gte: _dayInicio,
          lte: _dayFim
        },
        // tipo: { in: [taskType.Description[taskType.Tarefa], taskType.Description[taskType.Reuniao]] },
        isactive: true
      }

      const orderBy = { dtacao: 'asc' }
      const include = { lead: true }
      const query = { where, orderBy, include }
      setLoading(true)
      // console.log('query', query)
      const api = await getLeadTasks(query)

      setTasks(api.data)
      // setUpdate(Date.now())
      // setSnackbar({ open: true, message: 'Tarefas carregadas com sucesso', severity: 'success' })
    }
    catch (e) {
      setTasks([])
      setSnackbar({ open: true, message: 'Erro ao carregar tarefas...', severity: 'error' })
    }
    finally {
      setLoading(false)
    }
  }

  function handleTaskDetail(_task) {
    setTask(_task)
    setShowTask(true)
  }

  function organizeTasks() {
    const segunda = { dia: weekDays[1], data: dayjs(dayInicio).add(1, 'day').toISOString(), tasks: [] }
    const terca = { dia: weekDays[2], data: dayjs(dayInicio).add(2, 'day').toISOString(), tasks: [] }
    const quarta = { dia: weekDays[3], data: dayjs(dayInicio).add(3, 'day').toISOString(), tasks: [] }
    const quinta = { dia: weekDays[4], data: dayjs(dayInicio).add(4, 'day').toISOString(), tasks: [] }
    const sexta = { dia: weekDays[5], data: dayjs(dayInicio).add(5, 'day').toISOString(), tasks: [] }
    const sabado = { dia: weekDays[6], data: dayjs(dayInicio).add(6, 'day').toISOString(), tasks: [] }
    const domingo = { dia: weekDays[0], data: dayjs(dayInicio).add(0, 'day').toISOString(), tasks: [] }

    const semana = [domingo, segunda, terca, quarta, quinta, sexta, sabado]
    tasks.forEach(task => {
      // if (chkOutros) { // Retira os Diferente de Pendente
      //   if (task.situacao !== taskStatus.Description[taskStatus.Pendente]) return
      // }
      // if (task.tipo !== tipo) {
      //   return
      // }
      task.dia = dayjs(task.dtacao).format('dddd')
      const dia = dayjs(task.dtacao).day()
      switch (dia) {
        case 0:
          domingo.tasks.push(task);
          break;
        case 1:
          segunda.tasks.push(task);
          break;
        case 2:
          terca.tasks.push(task);
          break;
        case 3:
          quarta.tasks.push(task);
          break;
        case 4:
          quinta.tasks.push(task);
          break;
        case 5:
          sexta.tasks.push(task);
          break;
        case 6:
          sabado.tasks.push(task);
          break;
        default:
          break;
      }
    })
    // console.log('semana', semana)
    return semana
  }

  useEffect(() => {
    setSemana(organizeTasks())
  }, [chkOutros, tipo])

  useEffect(() => {
    setSemana(organizeTasks())
    // console.log('atualizou task')
  }, [tasks])

  useEffect(() => {
    if (update) loadTasks(dayInicio, dayFim)
  }, [update])

  useEffect(() => {
    if (tasks.length === 0) loadTasks(dayInicio, dayFim)
  }, [])

  return (
    // <Container>
    <>
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid container
        direction={'row'}
        justifyContent={'center'}
        // alignContent={{ md: 'center' }}
        gap={1}
        height={'85vh'}
        p={1}
      >

        <Grid container
          bgcolor={'#f5f5f5'}
          direction={'row'}
          mt={{ xs: 1, sm: 0 }}
          maxHeight={'70px'}
          alignItems="center"
          sx={{ borderRadius: 2, boxShadow: 2 }}

        >
          <Grid container justifyContent={'center'} alignItems={'center'} >
            <IconButton key={'previous'} color="primary" onClick={previousWeek} >
              &#9194;
            </IconButton>
            <Typography variant='h4' component={'span'} alignContent='center' >Semana:</Typography>
            <Typography variant='h4' component={'span'} alignContent='center'  >{' ' + dayjs(dayInicio).format('DD/MM')} - {dayjs(dayFim).format('DD/MM')}</Typography>
            <IconButton key={'next'} color="primary" onClick={nextWeek} >
              &#9193;
            </IconButton>
          </Grid>
          {/* <Grid container px={1}
            justifyContent={{ xs: 'left', sm: 'center' }} >
            <FormControlLabel key={'chkTipo'} control={<Checkbox size='medium' onClick={(e) => handleTipo(e)} />} label="Reuniões" />
            <FormControlLabel key={'chkOutros'} control={<Checkbox size='medium' onClick={(e) => handleConcluidos(e)} />} label="Mostrar concluídas e suspensas" />
          </Grid> */}
        </Grid>
        {weekDays.map((day, index) => (
          <Card key={index + 't'}
            elevation={3}
            sx={{
              bgcolor: getColorDay(day),
              height: '230px',
              borderRadius: 2,
            }}>
            <CardContent >
              <Typography variant='h4' align='left' mb={1}
                bgcolor={yellow[200]}
                border={1}
                p={.5}
              // width={{ xs: '200px', sm: '250px' }}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
                {semana[index]?.data ? ' (' + dayjs(semana[index]?.data).format('DD/MM') + ')' : ''}
              </Typography>
              <Grid container

                height={'160px'}
                overflow={'auto'}
                border={1}
              >
                <Grid item
                  textAlign={'start'}
                  width={{ xs: '350px', sm: '300px' }}
                  my={.5}
                >
                  {semana[index] && semana[index].tasks.map((_task, i) => (
                   <Button
                      key={i + 'a'}
                      variant={'text'}
                      size='small'
                      sx={{
                        cursor: 'pointer', textAlign: 'start', justifyContent: 'start',
                        width: '100%',
                        borderRadius: 0,
                        bgcolor: getColorStatus(_task.situacao, i),
                        mb: .5
                      }}
                      onClick={() => handleTaskDetail(_task)} >
                      <Typography variant={'body1'} fontSize={'12px'} px={.5} >
                        {dayjs(_task.dtacao).format('HH:mm') + '  - '}
                        {_task.titulo}
                      </Typography>
                    </Button>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Grid>
      {showTask && (
        <LeadTaskDetails
          account={account}
          lead={task.lead}
          task={task}
          taskList={tasks}
          taskListFiltered={null}
          filtro={null}
          setShowTask={setShowTask}
          showTask={showTask}
          setSnackbar={setSnackbar}
          setSemana={setSemana}
          organizeTasks={organizeTasks}
          setUpdate={setUpdate}
          update={update}
        />
      )}
    </>
    // </Container>
  );
} 
import { createElement, useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  useTheme
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CadEquipe } from './cadequipe/CadEquipe';
import { CadConvite } from './cadconvite/CadConvite';
import { CadMensagem } from './cadmensagem/CadMensagem';
import { getAllOperadoresEquipe } from '../../controllers/equipeController';

export default function Equipe({ user, openEquipe, setOpenEquipe, setHasMessage, setMsgAdm, msgAdm }) {
  const theme = useTheme();
  const [value, setValue] = useState('1');
  const [listUsuarios, setListUsuarios] = useState([]);

  const handleClose = () => setOpenEquipe(false);
  const handleChange = (event, newValue) => setValue(newValue);

  async function getAllOperadores() {
    const operadores = await getAllOperadoresEquipe(user.conta);
    setListUsuarios(operadores);
  }

  useEffect(() => {
    if (openEquipe) {
      getAllOperadores();
    }
  }, [openEquipe]);

  const tabsConfig = [
    { label: 'Equipe', value: '1', component: CadEquipe, props: { user, listUsuarios, setListUsuarios, getAllOperadores } },
    { label: 'Convite', value: '2', component: CadConvite, props: { user, listUsuarios, setListUsuarios } },
    { label: 'Quadro de Avisos', value: '3', component: CadMensagem, props: { user, setHasMessage, setMsgAdm, msgAdm } }
  ];
  

  return (
    <Dialog
      open={openEquipe}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: theme.palette.background.paper,
        }
      }}
    >
      <Box sx={{ height: '78vh' }}>
        <DialogTitle 
          sx={{
            fontSize: '1.15rem',
            fontWeight: 500,
            color: theme.palette.primary.main,
            borderBottom: `1px solid ${theme.palette.divider}`,
            pb: 2
          }}
          bgcolor={theme.palette.secondary.main}
        >
          Cadastro da Equipe
        </DialogTitle>
        
        <DialogContent sx={{ p: 0 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList 
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: { backgroundColor: theme.palette.primary.main }
                }}
                sx={{
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    minWidth: 120,
                    fontWeight: 500,
                    '&.Mui-selected': {
                      color: theme.palette.primary.main
                    }
                  }
                }}
              >
                {tabsConfig.map(tab => (
                  <Tab 
                    key={tab.value}
                    label={tab.label} 
                    value={tab.value}
                  />
                ))}
              </TabList>
            </Box>

            {tabsConfig.map(tab => (
              <TabPanel key={tab.value} value={tab.value} sx={{ p: 3 }}>
                {createElement(tab.component, {
                  ...tab.props,
                  key: tab.value
                })}
              </TabPanel>
            ))}
          </TabContext>
        </DialogContent>

        <DialogActions sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              textTransform: 'none',
              px: 4,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              }
            }}
            size='large'
          >
            Fechar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}